import React, { Fragment } from 'react';
import 'antd/dist/antd.css';
import { Table } from 'antd';
import './Dashboard.css';
import './Reports.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';

export default class ReportLogTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: []
    };
  }

  componentDidMount() {
    const userVar = JSON.parse(localStorage.getItem('user'));
    console.log(userVar);
    let sourceList = [];
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/bpsadmin/reportlog.php`, { userVar })
      .then(response => {
        console.log(response.data.payload.data);
        response.data.payload.data.map((item, key) => {
          const temp = {
            pulledBy: item['username'],
            pulledDate: item['pulledDate'],
            reportType: item['reportType'],
            action: item['fileUrl']
          };
          sourceList.push(temp);
        });
        this.setState({ dataSource: sourceList });
      });

  }

  render() {

    const columns = [
      {
        title: 'Name',
        dataIndex: 'pulledBy',
        key: 'pulledBy',
      },
      {
        title: 'Pulled Date',
        dataIndex: 'pulledDate',
        key: 'pulledDate',
      },
      {
        title: 'Report Type',
        dataIndex: 'reportType',
        key: 'reportType',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <a className='blue-link' href={record.action}>Download Report</a>
          </span>
        ),
      },
    ];
    return (
      <Fragment>
        <Container className="py-5 vh-fill">
          <Row className="pt-md-5">
            <Col className="pt-5">
              <h3>Report Log Menu</h3>
            </Col>
          </Row>
          <Row className="py-4">
            <Col>
              <Table dataSource={this.state.dataSource} columns={columns} scroll={{ x: 600, y: 500 }} />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}