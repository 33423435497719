import React from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { HomePage } from '../HomePage';
import { LoginPage } from '../LoginPage';
import { RegisterPage } from '../RegisterPage';
import { Reports } from '../HomePage/account/Reports';
import { Logout } from '../HomePage/account/Logout';
import { ForgotPage } from '../forgot/ForgotPage';
import { PasswordResetPage } from '../HomePage/PasswordReset';
import { FileUploader } from '../HomePage/account/FileUploader';
import { DealerRegReports } from '../HomePage/account/DealerRegReports'
import { DealerExpReports } from '../HomePage/account/DealerExpReports'
import { SurveyReports } from '../HomePage/account/SurveyReports'
import { PhysicalCardReport } from '../HomePage/account/PhysicalCardReport'
import { VirtualCardReport } from '../HomePage/account/VirtualCardReport'
import { ReportLogMenu } from '../HomePage/account/ReportLogMenu'
import { AllStatusRebate } from '../HomePage/account/AllStatusRebate';
import { ReferralCheckReport } from '../HomePage/account/ReferralCheckReport'
import { PromotionCheckReport } from '../HomePage/account/PromotionCheckReport'
import { ResendEmail } from '../HomePage/account/ResendEmail';
import { ReconcileReport } from '../HomePage/account/ReconcileReport';
import {RebateEditNavItem} from '../HomePage/account/RebateEditNavItem';
import {RebateTableNav} from '../HomePage/account/RebateTableNav';
import { SeasonalTableNav } from '../HomePage/account/SeasonalTableNav';
import { NoteNav } from '../HomePage/account/NoteNav';
import { RebateReservationReport } from '../HomePage/account/RebateReservationReport';
import { SpecialPromo } from '../HomePage/account/SpecialPromo';
import { CorpDocTableNav } from '../HomePage/account/CorpDocTableNav';
import { MaxReferralReport } from '../HomePage/account/MaxReferralReport';
import { MonthlyReport } from '../HomePage/account/MonthlyReport';
import { DealerFullyFundReport } from '../HomePage/account/DealerFullyFundReport';
import { TCUUReport } from '../HomePage/account/TCUUReport';
import { SNHPReport } from '../HomePage/account/SNHPReport';
import Dashboard from '../HomePage/account/Dashboard';
import AddUsers from '../HomePage/account/AddUsers';
import ListUsers from '../HomePage/account/ListUsers';

class App extends React.Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }

    render() {
        const { alert } = this.props;
        return (

            <Router history={history}>
                <div>
                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute exact path="/submittedrebates" component={RebateTableNav} />
                    <PrivateRoute exact path="/reports" component={Reports} />
                    <PrivateRoute exact path="/allrebatereports" component={AllStatusRebate} />
                    <PrivateRoute exact path="/dealerregreports" component={DealerRegReports} />
                    <PrivateRoute exact path="/dealerexpreports" component={DealerExpReports} />
                    <PrivateRoute exact path="/surveyreports" component={SurveyReports} />
                    <PrivateRoute exact path="/physicalcardreports" component={PhysicalCardReport} />
                    <PrivateRoute exact path="/virtualcardreports" component={VirtualCardReport} />
                    <PrivateRoute exact path="/promotioncheckreport" component={PromotionCheckReport} />
                    <PrivateRoute exact path="/referralcheckreport" component={ReferralCheckReport} />
                    <PrivateRoute exact path="/tcuureport" component={TCUUReport} />
                    <PrivateRoute exact path="/snhpreport" component={SNHPReport} />
                    <PrivateRoute exact path="/reportlogmenu" component={ReportLogMenu} />
                    <PrivateRoute exact path="/reportreconcile" component={ReconcileReport} />
                    <PrivateRoute exact path="/upload" component={FileUploader} />
                    <PrivateRoute exact path="/resendemail" component={ResendEmail} />
                    <PrivateRoute exact path="/editrebate" component={RebateEditNavItem} />
                    <PrivateRoute exact path="/logout" component={Logout} />
                    <PrivateRoute exact path="/passwordreset" component={PasswordResetPage} />
                    <PrivateRoute exact path="/rebates" component={RebateTableNav} />
                    <PrivateRoute exact path="/seasonal" component={SeasonalTableNav} />
                    <PrivateRoute exact path="/addnote" component={NoteNav} />
                    <PrivateRoute exact path="/rebatereservation" component={RebateReservationReport} />
                    <PrivateRoute exact path="/specialpromoreport" component={SpecialPromo} />
                    <PrivateRoute exact path="/corpdocs" component={CorpDocTableNav} />
                    <PrivateRoute exact path="/maxreferralreport" component={MaxReferralReport} />
                    <PrivateRoute exact path="/monthlyreport" component={MonthlyReport} />
                    <PrivateRoute exact path="/dealerfullyfund" component={DealerFullyFundReport} />
                    <PrivateRoute exact path="/listusers" component={ListUsers} />
                    <PrivateRoute exact path="/addusers" component={AddUsers} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/forgot" component={ForgotPage} />
                    <Route path="/register" component={RegisterPage} />
                </div>
            </Router>

        );
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };