import React, {Fragment} from 'react';
import 'antd/dist/antd.css';
import {
    Table, Form, Input, Button, Icon, Select, Radio, Popover, AutoComplete, Alert, Spin, Tag } from 'antd';
import axios from 'axios';
import './RebateTableNew.css';
import LoadingOverlay from 'react-loading-overlay';
import { Container, Row, Col } from 'react-bootstrap';
import AIModal from './AIModal';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }


const { Option } = Select;
const { Option2 } = AutoComplete;

class SeasonalTable extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
          dataSource: [],
          pagination: {},
          pageSize: 15,
          searchString: '',
          declineReason: [
            {name: 'Approved', label: 'Approved'},
            {name: 'Approved1', label: 'Dealer Fully Paid - 2020'},
            {name: 'Approved2', label: 'Dealer Fully Paid - 2021'},
            {name: 'Approved3', label: 'Contract not signed by promotion timeline'},
            {name: 'Approved4', label: 'Not installed within the promotion timeframe'},
            {name: 'Pending Document Upload', label: 'Signed Contract Required/Dealer Invoice is Not Accepted'},
            {name: 'Declined1', label: 'Wrong Rebate Code'},
            {name: 'Declined2', label: 'Wrong Panels Installed for this Rebate Code'},
            {name: 'Declined3', label: 'Seasonal Promo cannot be use as a second/add on rebate'},
            {name: 'Declined4', label: 'Duplicate record with same timing of submission and/or rebate code and system information'},
            { name: 'Declined5', label: 'Contract not signed by promotion timeline' },
            { name: 'Declined6', label: 'Invalid panel series' },
            { name: 'Declined7', label: 'Did not meet required minimum system size' },
            { name: 'Declined8', label: 'Non-SunPower Loan product' },
            { name: 'Declined9', label: 'Incorrect Dealer' },
            { name: 'Declined10', label: "Offer not available in customer's State" },
            { name: 'Declined11', label: 'Did not meet Loan Term requirement' },
            { name: 'Declined12', label: 'Did not meet APR requirement' },
          ],
          selectedRowKeys: [],
          declineReasonSelected: [],
          errorKey: {},
          showButtons: true,
          showComment: true,
          rebateState: 'all',
          xAxis: 3800,
          autoOptions: [],
          selectData: [],
          value: undefined,
          inputType: 'fname',
          showReset: false,
          autoCompleteValue: [],
          rebateField: {},
          rebateErrorKey: {},
          invalidRebateErrorKey: {},
          rebateCodes: [],
          showNotSelected: false,
          dealerReason: {},
          dealerFullPaidReason: [],
          dealerReasonErrorKey: {},
          modalVisible: false,
          modalSystemId: [],

      };
      this.state.columns = this.getColumns();
      /*this.state.columns.unshift(
        {
            title: 'Approval Date/Decline Date',
            dataIndex: 'processDate',
            key: 'processDate',
            width: 220
        }
      );*/
      this.state.columns.unshift(
        {
            title: 'Rebate Reason',
            dataIndex: 'reason',
            key: 'reason',
            width: 800,
            render: (select, record) => {
                //console.log(record);
                return (
                    <div>
                        <Select name={'reason_'+record['key']} onSelect={(value, event) => this.reasonOnChange(value, event, record['key'])} placeholder='Select Rebate action' style={{ width: 700 }}>
                            {this.state.declineReason.map(dReason => (
                                <Option value={dReason.name} label={dReason.name} key={dReason.name}>
                                    {dReason.label}
                                </Option>
                            ))}
                        </Select>
                        <span className={(this.state.errorKey[record['key']]) ? 'showMsg' : 'hide'}>This field is required</span>
                        {this.state.rebateField[record['key']] ?
                            <Input placeholder="Correct Rebate Code" onChange={(e) => this.captureRebate(record['key'], e)} style={{ width: "50%" }} /> 
                            : null
                        }
                        <span className={(this.state.rebateErrorKey[record['key']]) ? 'showMsg' : 'hide'}>Rebate code is required</span>
                        <span className={(this.state.invalidRebateErrorKey[record['key']]) ? 'showMsg' : 'hide'}>Rebate code is invalid</span>
                        {this.state.dealerReason[record['key']] ?
                            <Input placeholder="Dealer fully paid reason" onChange={(e) => this.captureDealerReason(record['key'], e)} style={{ width: "50%" }} /> 
                            : null
                        }
                        <span className={(this.state.dealerReasonErrorKey[record['key']]) ? 'showMsg' : 'hide'}>Dealer fully paid reason is required</span>
                    </div>
                );
            },
                
        },
      );
      this.onAction = this.onAction.bind(this);
      this.reasonOnChange = this.reasonOnChange.bind(this);
      this.onSelectChange = this.onSelectChange.bind(this);
      this.approveRebates = this.approveRebates.bind(this);
      this.declineRebates = this.declineRebates.bind(this);
      this.statusChange = this.statusChange.bind(this);
      this.handleReset = this.handleReset.bind(this);
      this.handleSearch = this.handleSearch.bind(this);
      this.onSelect = this.onSelect.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleonSelect = this.handleonSelect.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.onInputChange = this.onInputChange.bind(this);
      this.handleOnBlur = this.handleOnBlur.bind(this);
      this.captureRebate = this.captureRebate.bind(this);
      this.captureDealerReason = this.captureDealerReason.bind(this);
      this.showAIModal=this.showAIModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
    }
  
    captureRebate(key, e) {
        let rebateCodes = this.state.rebateCodes;
        let existsFlag = false;
        for (let i = 0; i < rebateCodes.length; i++) {
            if (rebateCodes[i]['id'] === key) {
                rebateCodes[i]['rebateCode'] = e.target.value;
                existsFlag = true;
            }
        } 
        if (!existsFlag) {
            rebateCodes.push({'id': key, 'rebateCode': e.target.value});
        }
        
        this.setState({rebateCodes});
    }

    captureDealerReason(key, e) {
        let dealerFullPaidReason = this.state.dealerFullPaidReason;
        let existsFlag = false;
        for (let i = 0; i < dealerFullPaidReason.length; i++) {
            if (dealerFullPaidReason[i]['id'] === key) {
                dealerFullPaidReason[i]['dealerReason'] = e.target.value;
                existsFlag = true;
            }
        } 
        if (!existsFlag) {
            dealerFullPaidReason.push({'id': key, 'dealerReason': e.target.value});
        }
        
        this.setState({dealerFullPaidReason});
    }

    showAIModal(systemId) {
        //console.log(this.state.selectedRowKeys);
        this.setState({  modalVisible: true, modalSystemId: systemId });
    }
    
    handleCloseModal() {
        this.setState({  modalVisible: false, modalSystemId: [] });
    }

    getColumns() {
        return [
            {
                title: 'AI Status',
                dataIndex: 'aiStatus',
                key: 'aiStatus',
                width: 180,
                render: (select, record) => {
                    //console.log(record);
                    return (
                        <div>
                            {record['aiStatus'] ? <>
                                <Tag color={record['aiStatus'] == 'AI_PENDING' ? "red" : "green"} >{record['aiStatus']}</Tag>
                                <Button type='link' className='p-0' onClick={() => this.showAIModal([record.key])}><Icon type="info-circle" /></Button>
                                </> : null}
                        </div>

                    );
                },
            },
            {
                title: 'Rebate Date',
                dataIndex: 'rebatedate',
                key: 'rebatedate',
                width: (this.state.rebateState == 'Approved' || this.state.rebateState == 'Declined') ? 200 : 150,
            },
            {
                title: 'Rebate Code',
                dataIndex: 'promocode',
                key: 'promocode',
                width: (this.state.rebateState == 'Approved' || this.state.rebateState == 'Declined') ? 150 : 250,
                render: (select, record) => {
                    return(
                        <div>
                            <Popover
                            placement="topLeft"
                            title=''
                            content={record['popUpInfo']}
                            arrowPointAtCenter
                            >
                                <Icon type="info-circle" />
                            </Popover>
                            <span> {record['promocode']}</span>
                        </div>
                    );
                }
            },
            {
                title: 'Rebate Status Tracking No',
                dataIndex: 'trackingNo',
                key: 'trackingNo',
                width: (this.state.rebateState != 'Approved') ? 230 : (this.state.rebateState != 'Pending SPWR Approval') ? 240 : 225,
            },
            {
                title: 'Reward Type',
                dataIndex: 'reward',
                key: 'reward',
                width: 150,
            },
            {
                title: 'Reward Amount',
                dataIndex: 'amount',
                key: 'amount',
                width: 150,
            },
            /*{
                title: 'Ship Date',
                dataIndex: 'shipDate',
                key: 'shipDate',
                width: 150,
            },*/
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                width: 150,
            },
            /*{
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                width: (this.state.rebateState == 'Approved') ? 200 : 150,
            },*/
            {
                title: 'Address',
                dataIndex: 'address1',
                key: 'address1',
                width: (this.state.rebateState == 'Approved') ? 200 : 170,
            },
            {
                title: 'Address2',
                dataIndex: 'address2',
                key: 'address2',
                width: 120,
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                width: 150,
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                width: (this.state.rebateState == 'Approved') ? 120 :100,
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
                width: (this.state.rebateState == 'Approved') ? 100 : 80,
            },
            {
                title: 'Mailing Address',
                dataIndex: 'mailingaddress1',
                key: 'mailingaddress1',
                width: 200,
            },
            {
                title: 'Mailing Address2',
                dataIndex: 'mailingaddress2',
                key: 'mailingaddress2',
                width: 170,
            },
            {
                title: 'Mailing City',
                dataIndex: 'mailingcity',
                key: 'mailingcity',
                width: 160,
            },
            {
                title: 'Mailing State',
                dataIndex: 'mailingstate',
                key: 'mailingstate',
                width: 170,
            },
            {
                title: 'Mailing Zip',
                dataIndex: 'mailingzip',
                key: 'mailingzip',
                width: 120,
            },
            {
                title: 'Uploaded Documents',
                dataIndex: 'fileNames',
                key: 'fileNames',
                width: (this.state.rebateState == 'Approved') ? 600 : 200,
                render: (select, record) => {
                    return (
                        <div>
                            {record.fileNames.map(document => (
                                <p><a href={process.env.REACT_APP_S3_BUCKET+'/'+document} target='_blank'>{document}</a></p>
                            ))}
                        </div>
                    );
                }
            },
            {
                title: 'First Name',
                dataIndex: 'fname',
                key: 'fname',
                fixed: 'right',
                width: 140,
                render: (select, record) => {
                    return(
                        <div>
                            <Popover
                            placement="topLeft"
                            title=''
                            content={record['popUpInfo']}
                            arrowPointAtCenter
                            >
                                <Icon type="info-circle" />
                            </Popover>
                            <span> {record['fname']}</span>
                        </div>
                    );
                }
                
            },
            {
                title: 'Last Name',
                dataIndex: 'lname',
                key: 'lname',
                fixed: 'right',
                width: 120,
                
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                fixed: 'right',
                width: 250,
                
            }
        ];
    }

    formatDate(date) {
        let month = ''; let day = ''; let year = '';
        if (date && date.length > 0) {
            const datetimeParts = date.split(' ');
            //console.log(datetimeParts);
            const dateParts = datetimeParts[0].split('-');
            
            let d = new Date(dateParts[0], dateParts[1], dateParts[2]);
            const monthVal = (d.getMonth() == 0) ? 12 : d.getMonth();
            month = '' + (monthVal);
            day = '' + d.getDate();
            year = d.getFullYear();
            //console.log(d);
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        }
    
        return [year, month, day].join('-');
    }
    
    getPopUpInfo(entry) {
        let table = (
            <table id="customers">
                <thead>
                    <tr>
                    <th>Referral Amount</th>
                    <th>Referral Code</th>
                    <th>Install Date</th>
                    <th>Contract Date</th>
                    <th>Module Type</th>
                    <th>System Size</th>
                    <th>Purchased/Leased</th>
                    <th>Dealer Name</th>
                    <th>Dealer City</th>
                    <th>Dealer State</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>{entry['referralAmount']}</td>
                    <td>{entry['reftrackingNo']}</td>
                    <td>{entry['installdate']}</td>
                    <td>{entry['contractDate']}</td>
                    <td>{entry['module']}</td>
                    <td>{entry['watts']}</td>
                    <td>{entry['saletype']}</td>
                    <td>{entry['dname']}</td>
                    <td>{entry['dcity']}</td>
                    <td>{entry['dstate']}</td>
                    </tr>
                </tbody>
            
            </table>
        );
        
        return table;
    }

    fillUpTableData(response) {
        const pagination = {
            pageSize: this.state.pageSize, 
            total: response.data.total,
            showSizeChanger: false,
        };
        const tableData = [];
        
        response.data.data.map(entry => {
            let temp = {
                key: entry['id'],
                fname: entry['fname'],
                lname: entry['lname'],
                email: entry['email'],
                trackingNo: entry['trackingNo'],
                status: (entry['status'] == 'In Process') ? 'Pending' : entry['status'],
                promocode: entry['promocode'],
                installdate: (entry['systemdate']) ? entry['systemdate'] : '',
                contractDate: (entry['contractDate']) ? entry['contractDate']: '',
                module: (entry['moduleName']) ? entry['moduleName'] : '',
                processDate: (entry['processDate']) ? this.formatDate(entry['processDate']): '',
                comment: (entry['comment']) ? entry['comment'] : '',
                rebatedate: this.formatDate(entry['created']),
                fundingStatus: entry['fundingStatus'],
                shipDate: (entry['shipDate']) ? entry['shipDate'] : '',
                address1: entry['address1'],
                address2: (entry['address2']) ? entry['address2']: '',
                city: entry['city'],
                state: entry['state'],
                zip: entry['zip'],
                phone: (entry['phone']) ? entry['phone'] : '',
                referralAmount: (entry['referralAmount']) ? entry['referralAmount'] : 0,
                reftrackingNo: (entry['reftrackingNo']) ? entry['reftrackingNo'] : '',
                mailingaddress1: entry['mailingaddress1'],
                mailingaddress2: (entry['mailingaddress2']) ? entry['mailingaddress2']: '',
                mailingcity: entry['mailingcity'],
                mailingstate: entry['mailingstate'],
                mailingzip: entry['mailingzip'],
                reward: entry['cardType'],
                amount: entry['amount'],
                watts: (entry['watts']) ? entry['watts']: '',
                saletype: (entry['saletype']) ? entry['saletype'] : '',
                dname: (entry['dname']) ? entry['dname'] : '',
                dcity: (entry['dcity']) ? entry['dcity'] : '',
                dstate: (entry['dstate']) ? entry['dstate'] : '',
                fileNames: [],
                aiStatus: entry['aiStatus'],
                systemId:entry['systemId'],
                popUpInfo: ''
            };
            temp['popUpInfo'] = this.getPopUpInfo(temp);
            entry['docs'].map(doc => {
                temp['fileNames'].push(doc);
            });
            tableData.push(temp);
        });
        
        this.setState({ dataSource: tableData, spinnerDisplay: false, spinnerText: '', pagination });
    }

    getRebates(pageNo, searchString, status, inputType) {
        this.setState({ spinnerDisplay: true, spinnerText: 'Retrieving the rebates...' });
        const userVar = JSON.parse(localStorage.getItem('user'));
        //console.log(userVar);
        const rebateState = (status.length > 0) ? status : this.state.rebateState;
        let url = '';
        const urlSearchString = (searchString.length > 0 && searchString != 'reset') ? searchString : (searchString == 'reset') ? '' : this.state.searchString;
        if (pageNo) {
            url = `${process.env.REACT_APP_BACKEND_URL}/api/getrebates?page=${pageNo}&searchString=${urlSearchString}&status=${rebateState}&inputType=${inputType}`;
        } else if (searchString.length > 0) {
            url = `${process.env.REACT_APP_BACKEND_URL}/api/getrebates?page=${pageNo}&searchString=${urlSearchString}&status=${rebateState}&inputType=${inputType}`;
        } else {
            url = `${process.env.REACT_APP_BACKEND_URL}/api/getrebates?searchString=${urlSearchString}&status=${rebateState}&inputType=${inputType}`;
        }
        
        axios.get(url, {
            headers: {
                "Authorization" : `Bearer ${userVar.token2}`,
                'Accept' : 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
            }})
        .then(response => {
            this.fillUpTableData(response);
        }).catch(error => {
            if (error.response) {
                if (error.response.data.error == "token_expired") {
                    localStorage.clear();
                    window.location.href = '/login';
                }
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
              }
        });
    }

    componentDidMount() {
        
        this.getRebates(null, '', 'Pending SPWR Approval');
        
    }

    onAction(pagination, filters, sorter, extra) {
        //console.log(pagination);
        const userVar = JSON.parse(localStorage.getItem('user'));
        //console.log(userVar);
        this.setState({ dataSource: [] });
        this.getRebates(pagination.current, '', 'Pending SPWR Approval', this.state.inputType);
        
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            //console.log('Received values of form: ', values);
            //this.setState({ dataSource: [], searchString: values.searchString });
            this.setState({ dataSource: []});
            this.getRebates(null, this.state.searchString, 'Pending SPWR Approval', this.state.inputType);
            
          }
        });
    };

    /*rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let selectedIds = selectedRowKeys.split(',');
            this.setState({selectedIds});
            console.log(this.state.selectedIds);
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };*/
    onSelectChange = selectedRowKeys => {
        //console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    reasonOnChange(value, ev, key) {
        //console.log(value);
        //console.log(key);
        let rebateField = this.state.rebateField;
        //const position = value.search(/(declined1|declined2)/i);
        const position = value.search(/\b(?:declined[12](?![01]))\b/gi);
        if (position != -1) {
            rebateField[key] = true;
        } else {
            rebateField[key] = false;
        }
        let dealerReason = this.state.dealerReason;
        dealerReason[key] = (value == 'Approved1' || value == 'Approved2') ? true : false;
        let declineReasonSelected = this.state.declineReasonSelected;
        let found = false;
        for (let i = 0; i < declineReasonSelected.length; i++) {
            //console.log(declineReasonSelected[i]);
            if (key === declineReasonSelected[i]['id']) {
                found = true;
                declineReasonSelected[i]['reason'] = value;
            }
        }
        if (!found) {
            declineReasonSelected.push({'id': key, 'reason': value});
        }
        
        this.setState({declineReasonSelected, rebateField, dealerReason});
    }

    processRebates() {
        //const spinnerText = ($action == 'Approved') ? 'Approving the selected Rebates...' : 'Declining the selected Rebates...';
        const spinnerText = 'Processing the rebates...'
        this.setState({ spinnerDisplay: true, spinnerText });
        const userVar = JSON.parse(localStorage.getItem('user'));
        let row = {};
        row['selectedIds'] = this.state.selectedRowKeys;
        row['declineIds'] = this.state.declineReasonSelected;
        row['action'] = 'seasonal';
        row['approvedDomain'] = 'corporate';
        row['rebateCodes'] = this.state.rebateCodes;
        row['dealerFullPaidReason'] = this.state.dealerFullPaidReason;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/rebate`,{row}, {
            headers: {
                "Authorization" : `Bearer ${userVar.token2}`
            }})
        .then(response => {
            //console.log(response['data']);
            if (response['data']['errorList'] && response['data']['errorList'].length > 0) {

                let invalidRebateErrorKey = {};
                response['data']['errorList'].map(errorKey => {
                    invalidRebateErrorKey[errorKey] = true;
                });
                this.setState({invalidRebateErrorKey, spinnerDisplay: false, showNotSelected: false});
            } else {
                this.setState({errorKey: {}, selectedRowKeys:[], declineReasonSelected: [], rebateErrorKey: {}, showNotSelected: false, dealerReasonErrorKey: {}});
                this.fillUpTableData(response);
            }
        })
        .catch(error => {
            alert(JSON.stringify(error));
        })
    }

    approveRebates() {
        //console.log(this.state.selectedRowKeys);
        this.processRebates('Approved');
        this.setState({selectedRowKeys: []});
    }
    
    declineRebates() {
        //console.log(this.state.declineReasonSelected);
        let error = false;
        let errorKey = []; let rebateErrorKey = {}; let codeExists = []; let showNotSelected = true;
        let dealerReasonErrorKey = {}; let reasonExists = [];
        this.state.selectedRowKeys.map(rowKey => {
            showNotSelected = false;
            //console.log(rowKey);
            const existsFlag = this.state.declineReasonSelected.some(function(arrVal) {
                return rowKey === arrVal.id;
            });
            //console.log('existsFlag: ',existsFlag);
            if (!existsFlag) {
                errorKey[rowKey] = true;
                error = true;
            }
            
            const rebateCodes = this.state.rebateCodes;
            const dealerFullPaidReason = this.state.dealerFullPaidReason;
            //console.log(this.state.declineReasonSelected);
            
            this.state.declineReasonSelected.map(function(arrVal) {
                if (arrVal['reason'] == 'Declined1' || arrVal['reason'] == 'Declined2') {
                    const codeExistsFlag = rebateCodes.some(codeVal => {
                        //console.log('arrVal.id: '+arrVal.id+'|codeVal.id:'+codeVal.id);
                        return arrVal.id === codeVal.id;
                    });
                    //codeExists[arrVal.id] = codeExists;
                    codeExists.push({'id':arrVal.id, 'error': codeExistsFlag});
                }
                if (arrVal['reason'] == 'Approved1' || arrVal['reason'] == 'Approved2') {
                    const reasonExistsFlag = dealerFullPaidReason.some(codeVal => {
                        //console.log('arrVal.id: '+arrVal.id+'|codeVal.id:'+codeVal.id);
                        return arrVal.id === codeVal.id;
                    });
                    //codeExists[arrVal.id] = codeExists;
                    reasonExists.push({'id':arrVal.id, 'error': reasonExistsFlag});
                }
                
            });
            //console.log(codeExists);
            
        })
        for (let j = 0; j < codeExists.length; j++) {
            if (!codeExists[j]['error']) {
                error = true;
                rebateErrorKey[codeExists[j]['id']] = true;
            }
        }
        for (let j = 0; j < reasonExists.length; j++) {
            if (!reasonExists[j]['error']) {
                error = true;
                dealerReasonErrorKey[reasonExists[j]['id']] = true;
            }
        }
        if (showNotSelected) {
            error = true;
        }
        //console.log(rebateErrorKey);
        //console.log('errorKey:',errorKey);
        if (!error) {
            this.processRebates();
            //this.setState({errorKey: {}, selectedRowKeys:[], declineReasonSelected: [], rebateErrorKey: {}});
        } else {
            this.setState({errorKey, rebateErrorKey, showNotSelected, dealerReasonErrorKey});
        }
    }

    statusChange(e) {
        //console.log(e.target.value);
        this.getRebates(null, '', e.target.value, this.state.inputType);
        let columns = this.getColumns();
        if (e.target.value == 'In Process') {
            columns.unshift(
                {
                    title: 'Decline Reason',
                    dataIndex: 'reason',
                    key: 'reason',
                    width: 770,
                    render: (select, record) => {
                        //console.log(record);
                        return (
                            <div>
                                <Select name={'reason_'+record['key']} onSelect={(value, event) => this.reasonOnChange(value, event, record['key'])}placeholder='Select Decline Reason' style={{ width: 610 }}>
                                    {this.state.declineReason.map(dReason => (
                                        <Option value={dReason.name} label={dReason.name} key={dReason.name}>
                                            {dReason.label}
                                        </Option>
                                    ))}
                                </Select>
                                <span className={(this.state.errorKey[record['key']]) ? 'showMsg' : 'hide'}>This field is required</span>
                                {this.state.rebateField[record['key']] ?
                                    <Input placeholder="Correct Rebate Code" onChange={(e) => this.captureRebate(record['key'], e)} maxLength="15" /> 
                                    : null
                                }
                                <span className={(this.state.rebateErrorKey[record['key']]) ? 'showMsg' : 'hide'}>Rebate code is invalid</span>
                            </div>
                            
                        );
                    },
                        
                },
            );
            this.setState({showButtons: true, showComment: true, rebateState: e.target.value, columns, xAxis: 4300});
            
            
        } else if (e.target.value == 'Declined') {
            columns.unshift(
                {
                    title: 'Declined Comment',
                    dataIndex: 'comment',
                    key: 'comment',
                    width:180,
                },
            );
        } 
        if (e.target.value != 'In Process' && e.target.value != 'Pending SPWR Approval') {
            columns.unshift(
                {
                    title: 'Approval Date/Decline Date',
                    dataIndex: 'processDate',
                    key: 'processDate',
                    width: (e.target.value == 'Declined') ? 190 : 190,
                },
            );
            const xAxis = (e.target.value == 'Approved') ? 3600 : (e.target.value == 'Declined') ? 3900 : (e.target.value == 'Pending SPWR Approval') ? 3700 : 3800;
            this.setState({showButtons: false, showComment: false, rebateState: e.target.value, columns, xAxis});
           

        }
        if (e.target.value == 'Pending SPWR Approval') {
            this.setState({showButtons: false, showComment: false, rebateState: e.target.value, columns, xAxis: 3400});
        }   
        if (e.target.value == 'all') {
            columns.unshift(
                {
                    title: 'Rebate Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 130,
                },
            );
            this.setState({showButtons: false, showComment: false, rebateState: e.target.value, columns, xAxis: 3800});
            
        }
    }

    expandedRowRender(record, index, indent, expanded) {
        const columns = [
          { title: 'Reward', dataIndex: 'reward', key: 'reward', width: 40},
          { title: 'Rebate Amount', dataIndex: 'amount', key: 'amount' , width: 40},
          { title: 'Phone', dataIndex: 'phone', key: 'phone', width: 40 },
          { title: 'Install Date', dataIndex: 'installdate', key: 'installdate', width: 40 },
          { title: 'Contract Date', dataIndex: 'contractDate', key: 'contractDate' , width: 40},
          { title: 'Module Type', dataIndex: 'module', key: 'module', width: 40 },
          { title: 'System Size', dataIndex: 'watts', key: 'watts', width: 40 },
          { title: 'Purchased/Leased', dataIndex: 'saletype', key: 'saletype', width: 50 },
          { title: 'Dealer Name', dataIndex: 'dname', key: 'dname', width: 50 },
          { title: 'Dealer City', dataIndex: 'dcity', key: 'dcity' , width: 50},
          { title: 'Dealer State', dataIndex: 'dstate', key: 'dstate', width: 200 },
        ];
        const data = [];
        data.push({
            key: record['key'],
            installdate: record['installdate'],
            contractDate: record['contractDate'],
            module: record['module'],
            watts: record['watts'],
            saletype: record['saletype'],
            dname: record['dname'],
            dcity: record['dcity'],
            dstate: record['dstate'],
            reward: record['reward'],
            amount: record['amount'],
        });
        
        return <Table 
                columns={columns} dataSource={data} pagination={false} scroll={{ x: 700 }}
                />;
    };

    handleReset() {
        //console.log('reset');
        this.props.form.setFieldsValue({
            'searchString': ''
          });
          //this.formRef.current.resetFields();
        this.setState({searchString: '', showReset: false, value: '', autoCompleteValue: []});
        this.getRebates(null, 'reset', 'Pending SPWR Approval');
    }

    renderOption(item) {
        console.log(item);
    
        return (
          <Option key={item.value} value={item.value.toString()}>
            <div className="global-search-item">
              
                  {item.title}
                
            </div>
          </Option>
        );
      }

    handleSearch(value) {
        //console.log(value);
        if (value && value.length > 1) {
            const userVar = JSON.parse(localStorage.getItem('user'));
            let url = '';
            url = `${process.env.REACT_APP_BACKEND_URL}/api/seasonalautocomplete/${value}/${this.state.inputType}`;
            
            axios.get(url, {
                headers: {
                    "Authorization" : `Bearer ${userVar.token2}`,
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8'
                }})
            .then(response => {
                console.log(response.data);
                /*let options = response.data.data.map(entry => {
                    return (
                        <Option2 key={entry.value} value={entry.value}>
                            {entry.title}
                        </Option2>
                    );
                });
                console.log(options);*/
                let autoOptions = [];
                response.data.map(item => {
                    autoOptions.push(String(item));
                })
                this.setState({autoOptions});
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        localStorage.clear();
                        window.location.href = '/login';
                    }
                    /*console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);*/
                }
            });
        } else if (value.length == 0) {
            this.handleReset();
        }
        
    }

    onSelect(value, option) {
        //console.log(value);
        //console.log(option);
    }

    handleonSelect(value, option) {
        //console.log(value);
        //console.log(option);
        this.setState({searchString: value, showReset: true, dataSource: []});
        this.getRebates(null, value, 'Pending SPWR Approval', this.state.inputType);
    }

    handleChange(value) {
        this.setState({ value });
    };

    handleInputChange(value) {
        this.setState({inputType: value});
    }

    onInputChange(value) {
        console.log(value);
        if (value && value.length == 0) {
            this.handleReset();
        }
    }

    handleOnBlur(value) {
        //console.log('value in onblur:',value);
        this.setState({searchString: value, showReset: true});
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const searchStringError = isFieldTouched('searchString') && getFieldError('searchString');
        //const options = this.state.autoOptions.map(d => <Option key={d.value}>{d.title}</Option>);
      return (
          <Fragment>
              <Container fluid className="py-5">
                    <Row className="pt-md-5">
                        <Col className="pt-5 pb-md-4">
                            <h3>Document Approval</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className={this.state.showNotSelected ? 'show' : 'hide'}>
                                <p>
                                    <Alert message="You need to select at least one rebate checkbox" type="error" />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-between pt-4 pb-5'>
                        <Col xl={6} sm={12}>
                            <Form layout="inline" onSubmit={this.handleSubmit}>
                                <Select defaultValue="fname" style={{ width: 220 }} onChange={this.handleInputChange} className="pe-2">
                                    <Option value="fname">First Name</Option>
                                    <Option value="lname">Last Name</Option>
                                    <Option value="email">Email</Option>
                                    <Option value="phone">Phone Number</Option>
                                    <Option value="name">Dealer Name</Option>
                                    <Option value="trackingNo">Rebate Status Tracking No</Option>
                                </Select>

                                <AutoComplete
                                    dataSource={this.state.autoOptions}
                                    style={{ width: 200 }}
                                    onSearch={this.handleSearch}
                                    onSelect={this.handleonSelect}
                                    value={this.state.autoCompleteValue}
                                    onChange={e => this.setState({ autoCompleteValue: [e] })}
                                    onBlur={this.handleOnBlur}
                                >
                                    <Input.Search autoComplete='off' />
                                </AutoComplete>
                            
                                <Form.Item>
                                    <Icon type="close-circle" style={{ display: (isFieldTouched('searchString') !== undefined && isFieldTouched('searchString')) ? '' : 'none' }} onClick={this.handleReset} />
                                    <Button className="btn-orange-secondary me-1" type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} style={{ margin: 0 }}>
                                        Search
                                    </Button>
                                    <Button className="btn-gray-secondary ms-1" type="primary" onClick={this.handleReset} style={{ display: this.state.showReset ? '' : 'none' }}>
                                        Reset
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col xl={6} sm={12} className="text-end">
                            <div className={this.state.showButtons ? 'show' : 'hide'}>
                                <Button className="btn-orange me-1" onClick={this.declineRebates}>Process Rebate</Button>
                            </div>
                        </Col>
                    </Row >
                    <Row>
                        <Col>
                            <LoadingOverlay
                                active={this.state.spinnerDisplay}
                                spinner
                                text={this.state.spinnerText}
                            >
                                <Table
                                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                                    rowSelection={(this.state.showComment) ? rowSelection : null} dataSource={this.state.dataSource} columns={this.state.columns} onChange={this.onAction} scroll={{ x: this.state.xAxis, y: 440 }} pagination={this.state.pagination}
                                />
                            </LoadingOverlay>
                        </Col>
                    </Row>
                    <AIModal visible={this.state.modalVisible} onClose={this.handleCloseModal} systemId={this.state.modalSystemId} />
                </Container>
          </Fragment>
      );
    }
  }

const SeasonalTableForm = Form.create({ name: 'rebate_form' })(SeasonalTable);
export default SeasonalTableForm;
