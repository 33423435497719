import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Container, Col, Row, Form, Card, Button } from 'react-bootstrap';
import { userActions } from '../_actions';
import queryString from 'query-string'
import './RegisterPage.css';
import axios from 'axios';
import { Footer } from '../footer/Footer';
import { CardBody } from 'reactstrap';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                dealerFname: '',
                dealerLname: '',
                username: '',
                password: '',
                cpassword: '',
                email: '',
                dealerAddr: '',
                dealerAddr2: '',
                city: '',
                state: '',
                zip: '',
                autoapp: '',
                emapp: '',
                dealercode: ''
            },
            submitted: false,
            usernameError: false,
            autoapprove: false,
            emailapprove: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        this._mounted = true
        if (values.dealer === undefined) {
            values.dealer = '1111111';
            window.location.href='/login';
        }
        axios.get(process.env.REACT_APP_BACKEND_URL + '/dealerlookup.php?dealercode=' + values.dealer)
            .then(response => {
                //console.log(response.data);
                //console.log(response.data.length);
                if (response.data.length !== undefined && response.data.length === 0) {
                    window.location.href = '/login';
                }
                this.setState({
                    user:
                    {
                        dealerAddr: response.data.address1,
                        dealerAddr2: response.data.address2,
                        city: response.data.city,
                        state: response.data.state,
                        zip: response.data.zip
                    }
                });

            });
        this.setState({ user: { dealercode: values.dealer } });
    }


    handleChange(event) {
        let { name, value } = event.target;
        if (name == 'autoapp' || name == 'emapp') {
            value = true;
        }
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
        if (name == 'autoapp') {
            this.setState({ autoapprove: true, emailapprove: false });
        } else if (name == 'emapp') {
            this.setState({ autoapprove: false, emailapprove: true });
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({ submitted: true });
        const values = queryString.parse(this.props.location.search);
        this._mounted = true
        if (values.dealer === undefined) {
            values.dealer = '1111111';
            window.location.href = '/login';
        }
        const { user } = this.state;
        const postBody = { dealercode: values.dealer, user: user };
        if (user.dealerFname && user.dealerLname && user.username && user.password && user.email && user.dealerAddr && user.city && user.zip && (user.autoapp || user.emapp)) {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/registerdealer.php`, { postBody })
                .then(res => {
                    console.log(res);
                    if (!res.data.username) {
                        this.setState({ usernameError: true });
                    } else {
                        console.log('in here redirect');
                        this.setState({ usernameError: false });
                        this.props.register(user);
                    }

                    console.log(user);
                });
        }
    }

    render() {
        const { registering } = this.props;
        const { user, submitted } = this.state;
        return (
            <Fragment>
                <Container fluid className="bg-light py-4">
                    <Row className="vh-fill align-items-center justify-content-center">
                        <Col lg={6} md={8}>
                            <Card className="rounded shadow-lg border-0 p-4">
                                {this.state.usernameError ? <div className="alert alert-danger">Username is already in use.</div> : null}
                                <Card.Header className='bg-white'>
                                    <h1 className="font-weight-bold">CREATE AN ACCOUNT</h1>
                                    <p>Let's set up your Dealer Admin Rebate Account!</p>

                                </Card.Header>


                                <Card.Body>
                                    <Form name="form" onSubmit={this.handleSubmit}>
                                        <Form.Group className={'py-2' + (submitted && !user.username ? ' has-error' : '')}>
                                            <Form.Label htmlFor="username" className="sr-only">Create Username</Form.Label>
                                            <input type="text" className="form-control" name="username" value={user.username} onChange={this.handleChange} placeholder="Create Username" />
                                            {submitted && !user.username &&
                                                <div className="help-block">Username is required</div>
                                            }
                                        </Form.Group>
                                        <Form.Group className={'py-2' + (submitted && !user.password ? ' has-error' : '')}>
                                            <Form.Label htmlFor="password" className="sr-only">Create Password</Form.Label>
                                            <input type="password" className="form-control" name="password" value={user.password} onChange={this.handleChange} placeholder="Create Password" />
                                            {submitted && !user.password &&
                                                <div className="help-block">Password is required</div>
                                            }
                                        </Form.Group>

                                        <Form.Group className={'py-2' + (submitted && !user.cpassword ? ' has-error' : '')}>
                                            <Form.Label htmlFor="cpassword" className="sr-only">Confirm Password</Form.Label>
                                            <input type="password" className="form-control" name="cpassword" value={user.cpassword} onChange={this.handleChange} placeholder="Confirm Password" />
                                            {submitted && !user.cpassword &&
                                                <div className="help-block">Confirm Password is required</div>
                                            }
                                            {submitted && user.cpassword != user.password &&
                                                <div className="help-block">Confirm Password should match the password</div>
                                            }
                                        </Form.Group>

                                        <Form.Group className={'py-2' + (submitted && !user.email ? ' has-error' : '')}>
                                            <Form.Label htmlFor="email" className="sr-only">Email</Form.Label>
                                            <input type="email" className="form-control" name="email" value={user.email} onChange={this.handleChange} placeholder="Create Email" />
                                            {submitted && !user.email &&
                                                <div className="help-block">Email is required</div>
                                            }
                                        </Form.Group>
                                        <hr />
                                        <Form.Group className={'py-2' + (submitted && !user.dealerFname ? ' has-error' : '')}>
                                            <Form.Label htmlFor="dealerFname" className="sr-only">Dealer Contact First Name</Form.Label>
                                            <input type="text" className="form-control" name="dealerFname" value={user.dealerFname} onChange={this.handleChange} placeholder="Create Dealer Contact First Name" />
                                            {submitted && !user.dealerFname &&
                                                <div className="help-block">Dealer Contact First Name is required</div>
                                            }
                                        </Form.Group>

                                        <Form.Group className={'py-2' + (submitted && !user.dealerLname ? ' has-error' : '')}>
                                            <Form.Label htmlFor="dealerLname" className="sr-only">Dealer Contact Last Name</Form.Label>
                                            <input type="text" className="form-control" name="dealerLname" value={user.dealerLname} onChange={this.handleChange} placeholder="Create Dealer Contact Last Name" />
                                            {submitted && !user.dealerLname &&
                                                <div className="help-block">Dealer Contact Last Name is required</div>
                                            }
                                        </Form.Group>

                                        <Form.Group className={'py-2' + (submitted && !user.dealerAddr ? ' has-error' : '')}>
                                            <Form.Label htmlFor="dealerAddr" className="sr-only">Dealer Address 1</Form.Label>
                                            <input type="text" className="form-control" name="dealerAddr" value={user.dealerAddr} onChange={this.handleChange} placeholder="Create Dealer Address 1" />
                                            {submitted && !user.dealerAddr &&
                                                <div className="help-block">Dealer Address 1 is required</div>
                                            }
                                        </Form.Group>

                                        <Form.Group className={'py-2' + (submitted && !user.dealerAddr2 ? ' has-error' : '')}>
                                            <Form.Label htmlFor="dealerAddr2" className="sr-only">Dealer Address 2</Form.Label>
                                            <input type="text" className="form-control" name="dealerAddr2" value={user.dealerAddr2} onChange={this.handleChange} placeholder="Create Dealer Address 2" />

                                        </Form.Group>

                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className={'py-2' + (submitted && !user.city ? ' has-error' : '')}>
                                                    <Form.Label htmlFor="city" className="sr-only">City</Form.Label>
                                                    <input type="text" className="form-control" name="city" value={user.city} onChange={this.handleChange} placeholder="Create City" />
                                                    {submitted && !user.city &&
                                                        <div className="help-block">City is required</div>
                                                    }
                                                </Form.Group>
                                            </Col>

                                            <Col lg={3}>
                                                <Form.Group className={'py-2' + (submitted && !user.state ? ' has-error' : '')}>
                                                    <Form.Label htmlFor="state" className="sr-only">State</Form.Label>
                                                    <input type="text" className="form-control" name="state" value={user.state} onChange={this.handleChange} placeholder="Create State" />
                                                    {submitted && !user.state &&
                                                        <div className="help-block">State is required</div>
                                                    }
                                                </Form.Group>
                                            </Col>

                                            <Col lg={3}>
                                                <Form.Group className={'py-2' + (submitted && !user.zip ? ' has-error' : '')}>
                                                    <Form.Label htmlFor="zip" className="sr-only">Zip</Form.Label>
                                                    <input type="text" className="form-control" name="zip" value={user.zip} onChange={this.handleChange} placeholder="Create Zip" />
                                                    {submitted && !user.zip &&
                                                        <div className="help-block">Zip is required</div>
                                                    }
                                                </Form.Group>

                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <p>How would you like your customer's Rebates
                                                    submitted?</p>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col>
                                                {submitted && !user.emapp && !user.autoapp &&
                                                    <div className="help-block">You must select one of the approval options</div>
                                                }
                                                <Form.Check
                                                    type="radio"
                                                    label="Auto Approve"
                                                    name="autoapp"
                                                    id="autoapp"
                                                    value={user.autoapp} checked={this.state.autoapprove} onChange={this.handleChange}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Approve From Email"
                                                    name="emapp"
                                                    id="emapp"
                                                    value={user.emapp} checked={this.state.emailapprove} onChange={this.handleChange}
                                                />
                                                <Form.Text>
                                                    Dealers must approve or disapprove rebates
                                                    submitted within 5 business days. If Dealer does not process Rebate
                                                    within 5 business days the system will auto approve.
                                                </Form.Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="text-center pt-4">
                                                    <Button className="btn-sunpower-orange">Create Account</Button>
                                                    {registering &&
                                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                                <Card.Footer className='pt-3'>
                                    <p>Already have an account? <Link to="/login" className="text-dark">Login here</Link></p>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Footer></Footer>
            </Fragment>
        );
    }
}

function mapState(state) {
    const { registering } = state.registration;
    return { registering };
}

const actionCreators = {
    register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };