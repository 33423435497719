import React, { Component, Fragment } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import './Reports.css'
import { Container, Row, Col, Form } from 'react-bootstrap';
import { CalendarOutlined } from '@ant-design/icons';

class ExceptionReport extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false,
          reportType: 'exception',
          columns: [],
          startDate: '',
          endDate: '',
          checkedStatus: false,
          column: {
            fname: false,
            lname: false,
            uaddress1: false,
            uaddress2: false,
            ucity: false,
            ustate: false,
            uzip: false,
            email: false,
            name: false,
            dealercode: false,
            promocode: false,
            created: false,
            dphone: false,
            email: false,
          }
        };
        this.handleChange = this.handleChange.bind(this);
        this.captureStartDate = this.captureStartDate.bind(this);
        this.captureEndDate = this.captureEndDate.bind(this);
        this.captureColumn = this.captureColumn.bind(this);
        this.reportExport = this.reportExport.bind(this);
        this.selectClickCheck = this.selectClickCheck.bind(this);
        this.selectClickUnCheck = this.selectClickUnCheck.bind(this);
      }
    
      toggle(e) {
          console.log(e.target.value);
        /*this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
          }));*/
        this.setState({reportType: e.target.value});
      }

      handleChange(date) {
        this.setState({
          startDate: date
        });
      }

    captureStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    captureEndDate = date => {
        //let enddate = moment(date).format("YYYY-MM-DD");
        this.setState({
            endDate: date
        });
    };

    captureColumn(e) {
        let column = this.state.column;
        if (e.target.checked) {
            let columnSelected = [];
            if (this.state !== undefined) {
                columnSelected= this.state.columns;
            }
            column[e.target.value] = true;
            console.log(columnSelected)
            columnSelected.push(e.target.value);
            this.setState({columns: columnSelected, column: column});
        } else {
            let lastCols= this.state.columns;
            let index = lastCols.indexOf(e.target.value);
            if (index > -1) {
                lastCols.splice(index, 1);
            }
            column[e.target.value] = false;
            this.setState({columns: lastCols, column: column});
        }
    }

    selectClickCheck()  {
        let columnSelected = [];
        columnSelected.push('fname');
        columnSelected.push('lname');
        columnSelected.push('uaddress1');
        columnSelected.push('uaddress2');
        columnSelected.push('ucity');
        columnSelected.push('ustate');
        columnSelected.push('uzip');
        columnSelected.push('email');
        columnSelected.push('name');
        columnSelected.push('dealercode');
        columnSelected.push('promocode');
        columnSelected.push('created');
        columnSelected.push('dphone');
        columnSelected.push('demail');
        let column = {
            fname: true,
            lname: true,
            uaddress1: true,
            uaddress2: true,
            ucity: true,
            ustate: true,
            uzip: true,
            email: true,
            name: true,
            dealercode: true,
            promocode: true,
            created: true,
            dphone: true,
            demail: true,
          };
        this.setState({checkedStatus : true, columns: columnSelected, column: column});
    }

    selectClickUnCheck() {
        const columnSelected = [];
        let column = {
            fname: false,
            lname: false,
            uaddress1: false,
            uaddress2: false,
            ucity: false,
            ustate: false,
            uzip: false,
            email: false,
            name: false,
            dealercode: false,
            promocode: false,
            created: false,
            dphone: false,
            demail: false,
          }
        this.setState({checkedStatus : false, columns: columnSelected, column: column});
    }

    reportExport() {
        console.log(this.state.columns.toString());
        console.log(this.state.endDate.toLocaleDateString());
        console.log(this.state.startDate.toLocaleDateString());
        console.log(this.state.reportType);
        const userVar = JSON.parse(localStorage.getItem('user'));
        let reportUrl = process.env.REACT_APP_BACKEND_URL+"/bpsadmin/dealerexceptionreport.php?reportType="+this.state.reportType+
            "&startDate="+this.state.startDate.toLocaleDateString()+"&endDate="+this.state.endDate.toLocaleDateString()+"&columns="+this.state.columns.toString()+"&userVar="+userVar.token;
        console.log(reportUrl);
        window.open(reportUrl, "_blank");
    }

      render() {
        return (
            <Fragment>
                <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                        <h3>Dealer Exception Report</h3>
                        <p>Reports will be produced and downloaded in Excel.</p>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col md={4}>
                                <Form.Label className="d-block pt-4">Start Date:</Form.Label>
                                <DatePicker
                                    id='date-picker'
                                    placeholderText='mm/dd/yyyy'
                                    selected={this.state.startDate}
                                    onChange={this.captureStartDate}
                                    dateFormat="MM-dd-yyyy"
                                    className="form-control form-control-sm"
                                />
                                <CalendarOutlined className="date-icon" />
                        </Col>
                        <Col md={4}>
                                <Form.Label className="d-block pt-4">End Date:</Form.Label>
                                    <DatePicker
                                    id='date-picker'
                                    placeholderText='mm/dd/yyyy'
                                    selected={this.state.endDate}
                                    onChange={this.captureEndDate}
                                    dateFormat="MM-dd-yyyy"
                                    className="form-control form-control-sm"
                                />
                                <CalendarOutlined className="date-icon" />

                        </Col>
                    </Row>
                    <Row className="py-4 border-top">
                        <Col>
                            <Button onClick={this.selectClickCheck} className="btn-sm btn-gray-secondary me-1">Select All</Button>
                            <Button onClick={this.selectClickUnCheck} className="btn-sm btn-gray-secondary ms-1">Deselect All</Button>
                        </Col>
                    </Row>
                    <Row className="pb-4">
                        <Col md={3}>
                            <Form.Check type="checkbox" name="fname" value="fname" label="First Name" onChange={this.captureColumn} checked={this.state.column.fname}/>
                            <Form.Check type="checkbox" name="lname" value="lname" label="Last Name" onChange={this.captureColumn} checked={this.state.column.lname} />
                            <Form.Check type="checkbox" name="uaddress1" value="uaddress1" label="Address 1" onChange={this.captureColumn} checked={this.state.column.uaddress1} />
                            <Form.Check type="checkbox" name="uaddress2" value="uaddress2" label="Address 2" onChange={this.captureColumn} checked={this.state.column.uaddress2} />
                            <Form.Check type="checkbox" name="ucity" value="ucity" label="City" onChange={this.captureColumn} checked={this.state.column.ucity} />
                            <Form.Check type="checkbox" name="ustate" value="ustate" label="State" onChange={this.captureColumn} checked={this.state.column.ustate} />
                            <Form.Check type="checkbox" name="uzip" value="uzip" label="Zip" onChange={this.captureColumn} checked={this.state.column.uzip} />
                            <Form.Check type="checkbox" name="email" value="email" label="User Email" onChange={this.captureColumn} checked={this.state.column.email} />
                        </Col>
                        <Col md={3} xs={6}>
                            <Form.Check type="checkbox" name="promocode" value="promocode" label="Rebate Code" onChange={this.captureColumn} checked={this.state.column.promocode} />
                            <Form.Check type="checkbox" name="created" value="created" label="Rebate Date" onChange={this.captureColumn} checked={this.state.column.created} />
                            <Form.Check type="checkbox" name="name" value="dname" label="Dealer Name" onChange={this.captureColumn} checked={this.state.column.name} />
                            <Form.Check type="checkbox" name="dealercode" value="dealercode" label="Dealer Code" onChange={this.captureColumn} checked={this.state.column.dealercode} />
                            <Form.Check type="checkbox" name="demail" value="demail" label="Dealer Email" onChange={this.captureColumn} checked={this.state.column.demail} />
                            <Form.Check type="checkbox" name="dphone" value="dphone" label="Dealer Phone" onChange={this.captureColumn} checked={this.state.column.dphone} />
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col>
                            <Button type="button" className="btn-orange" onClick={this.reportExport}>Export Report</Button>
                        </Col>
                    </Row>
                </Container>
            </Fragment>

        );
      }
}

export default ExceptionReport;