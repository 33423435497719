import React, { Component, Fragment } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import './Reports.css'
import { Container, Row, Col, Form } from 'react-bootstrap';
import { CalendarOutlined } from '@ant-design/icons';

class RegistrationReport extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            reportType: 'registration',
            columns: [],
            startDate: '',
            endDate: '',
            checkedStatus: false,
            column: {
                fname: false,
                lname: false,
                address1: false,
                address2: false,
                city: false,
                state: false,
                zip: false,
                email: false,
                name: false,
                dealercode: false,
                approvalmode: false,
                registerdate: false,
                phone: false,
                rsm: false
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.captureStartDate = this.captureStartDate.bind(this);
        this.captureEndDate = this.captureEndDate.bind(this);
        this.captureColumn = this.captureColumn.bind(this);
        this.reportExport = this.reportExport.bind(this);
        this.selectClickCheck = this.selectClickCheck.bind(this);
        this.selectClickUnCheck = this.selectClickUnCheck.bind(this);
    }

    toggle(e) {
        console.log(e.target.value);
        /*this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
          }));*/
        this.setState({ reportType: e.target.value });
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    captureStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    captureEndDate = date => {
        //let enddate = moment(date).format("YYYY-MM-DD");
        this.setState({
            endDate: date
        });
    };

    captureColumn(e) {
        let column = this.state.column;
        if (e.target.checked) {
            let columnSelected = [];
            if (this.state !== undefined) {
                columnSelected = this.state.columns;
            }
            column[e.target.value] = true;
            console.log(columnSelected)
            columnSelected.push(e.target.value);
            this.setState({ columns: columnSelected, column: column });
        } else {
            let lastCols = this.state.columns;
            let index = lastCols.indexOf(e.target.value);
            if (index > -1) {
                lastCols.splice(index, 1);
            }
            column[e.target.value] = false;
            this.setState({ columns: lastCols, column: column });
        }
    }

    selectClickCheck() {
        let columnSelected = [];
        columnSelected.push('fname');
        columnSelected.push('lname');
        columnSelected.push('address1');
        columnSelected.push('address2');
        columnSelected.push('city');
        columnSelected.push('state');
        columnSelected.push('zip');
        columnSelected.push('email');
        columnSelected.push('name');
        columnSelected.push('dealercode');
        columnSelected.push('approvalmode');
        columnSelected.push('registerdate');
        columnSelected.push('phone');
        columnSelected.push('rsm');
        let column = {
            fname: true,
            lname: true,
            address1: true,
            address2: true,
            city: true,
            state: true,
            zip: true,
            email: true,
            name: true,
            dealercode: true,
            approvalmode: true,
            registerdate: true,
            phone: true,
            rsm: true
        };
        this.setState({ checkedStatus: true, columns: columnSelected, column: column });
    }

    selectClickUnCheck() {
        const columnSelected = [];
        let column = {
            fname: false,
            lname: false,
            address1: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            email: false,
            name: false,
            dealercode: false,
            approvalmode: false,
            registerdate: false,
            phone: false,
            rsm: false
        }
        this.setState({ checkedStatus: false, columns: columnSelected, column: column });
    }

    reportExport() {
        console.log(this.state.columns.toString());
        console.log(this.state.endDate.toLocaleDateString());
        console.log(this.state.startDate.toLocaleDateString());
        console.log(this.state.reportType);
        const userVar = JSON.parse(localStorage.getItem('user'));
        let reportUrl = process.env.REACT_APP_BACKEND_URL + "/bpsadmin/dealerregexport.php?reportType=" + this.state.reportType +
            "&startDate=" + this.state.startDate.toLocaleDateString() + "&endDate=" + this.state.endDate.toLocaleDateString() + "&columns=" + this.state.columns.toString() + "&userVar=" + userVar.token;
        console.log(reportUrl);
        window.open(reportUrl, "_blank");
    }

    render() {
        return (
            <Fragment>
                <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                            <h3>Dealer Registration Report</h3>
                            <p>Reports will be produced and downloaded in Excel.</p>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col md={4}>
                            <Form.Label className="d-block pt-4">Start Date:</Form.Label>
                            <DatePicker
                                id='date-picker'
                                placeholderText='mm/dd/yyyy'
                                selected={this.state.startDate}
                                onChange={this.captureStartDate}
                                dateFormat="MM-dd-yyyy"
                                className="form-control form-control-sm"
                            />
                            <CalendarOutlined className="date-icon" />
                        </Col>
                        <Col md={4}>
                            <Form.Label className="d-block pt-4">End Date:</Form.Label>
                            <DatePicker
                                id='date-picker'
                                placeholderText='mm/dd/yyyy'
                                selected={this.state.endDate}
                                onChange={this.captureEndDate}
                                dateFormat="MM-dd-yyyy"
                                className="form-control form-control-sm"
                            />
                            <CalendarOutlined className="date-icon" />

                        </Col>
                    </Row>
                    <Row className="py-4 border-top">
                        <Col>
                            <Button onClick={this.selectClickCheck} className="btn-sm btn-gray-secondary me-1">Select All</Button>
                            <Button onClick={this.selectClickUnCheck} className="btn-sm btn-gray-secondary ms-1">Deselect All</Button>
                        </Col>
                    </Row>
                    <Row className="pb-4">
                        <Col md={3}>
                            <Form.Check type="checkbox" name="fname" value="fname" label="First Name" onChange={this.captureColumn} checked={this.state.column.fname} />
                            <Form.Check type="checkbox" name="lname" value="lname" label="Last Name" onChange={this.captureColumn} checked={this.state.column.lname} />
                            <Form.Check type="checkbox" name="address1" value="address1" label="Address 1" onChange={this.captureColumn} checked={this.state.column.address1} />
                            <Form.Check type="checkbox" name="address2" value="address2" label="Address 2" onChange={this.captureColumn} checked={this.state.column.address2} />
                            <Form.Check type="checkbox" name="city" value="city" label="City" onChange={this.captureColumn} checked={this.state.column.city} />
                            <Form.Check type="checkbox" name="state" value="state" label="State" onChange={this.captureColumn} checked={this.state.column.state} />
                            <Form.Check type="checkbox" name="zip" value="zip" label="Zip" onChange={this.captureColumn} checked={this.state.column.zip} />
                        </Col>
                        <Col md={3} xs={6}>
                            <Form.Check type="checkbox" name="email" value="email" label="Email" onChange={this.captureColumn} checked={this.state.column.email} />
                            <Form.Check type="checkbox" name="phone" value="phone" label="Phone" onChange={this.captureColumn} checked={this.state.column.phone} />
                            <Form.Check type="checkbox" name="name" value="name" label="Dealer Name" onChange={this.captureColumn} checked={this.state.column.name} />
                            <Form.Check type="checkbox" name="dealercode" value="dealercode" label="Dealer ID" onChange={this.captureColumn} checked={this.state.column.dealercode} />
                            <Form.Check type="checkbox" name="rsm" value="rsm" label="RSM" onChange={this.captureColumn} checked={this.state.column.rsm} />
                            <Form.Check type="checkbox" name="approvalmode" value="approvalmode" label="Approval Mode" onChange={this.captureColumn} checked={this.state.column.approvalmode} />
                            <Form.Check type="checkbox" name="registerdate" value="registerdate" label="Register Date" onChange={this.captureColumn} checked={this.state.column.registerdate} />
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col>
                            <Button type="button" className="btn-orange" onClick={this.reportExport}>Export Report</Button>
                        </Col>
                    </Row>
                </Container>
            </Fragment>

        );
    }
}

export default RegistrationReport;