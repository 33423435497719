import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import './HomePage.css';
import { Footer } from '../footer/Footer';
import { createBrowserHistory } from 'history';
import './account/Reports.css'
import { Container, Col, Row, Form, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import { withRouter } from "react-router-dom";

class PasswordResetPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            temppass: '',
            password: '',
            confirmpass: '',
            submitted: false,
            tempError: false,
            mismatchError: false,
            loginError: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getUsers();
    }

    handleDeleteUser(id) {
        return (e) => this.props.deleteUser(id);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const history = createBrowserHistory();

        this.setState({ submitted: true, loginError: false });
        const { temppass, password, confirmpass  } = this.state;
        const userObj = JSON.parse(localStorage.getItem('user'));
        if (temppass && password && confirmpass) {
            const postBody = {temppass: this.state.temppass, password: this.state.password, confirmpass: this.state.confirmpass, token: userObj.token}
            if (password != confirmpass) {
                this.setState({ mismatchError: true, tempError: false, submitted: false});
            } else {
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/bpsadmin/passwordchange.php`, { postBody })
                .then(res => {
                    console.log(res.data);
                    if(res.data.notFound) {
                        this.setState({ submitted: false, tempError: true, mismatchError: false });
                    } else {
                        this.props.history.push('/');
                    }
                });
            }
        }
    }

    render() {
        //const { user, users } = this.props;
        const { loggingIn } = this.props;
        const { temppass, password, confirmpass, submitted } = this.state;
        return (
                <Fragment>
                    <Container fluid className="bg-light py-4">
                    <Row className="vh-fill align-items-center justify-content-center">
                        <Col lg={4} md={6}>
                            <Card className="rounded shadow-lg border-0 p-4">
                                { this.state.tempError ? <div className="alert alert-danger">Temporary Password is invalid</div> : null}
                                { this.state.mismatchError ? <div className="alert alert-danger">Password's don't match</div> : null}
                                <Card.Header className="bg-white border-0">
                                        <h1 className="text-center font-weight-bold">Change Password</h1>
                                    </Card.Header>
                                    <Card.Body>
                                    <Form name="form" onSubmit={this.handleSubmit}>
                                    
                                            <Form.Group className={'py-2' + (submitted && !temppass ? ' has-error' : '')}>
                                                <Form.Label htmlFor="email">Temporary Password:</Form.Label>
                                                <Form.Control input type="text" className="bg-white" name="temppass" value={temppass} onChange={this.handleChange} placeholder="Temporary Password" />
                                                {submitted && !temppass &&
                                                <div className="help-block">Temporary Password is required</div>
                                                }
                                            </Form.Group>
                                            <Form.Group className={'py-2' + (submitted && !password ? ' has-error' : '')}>
                                                <Form.Label htmlFor="pwd" className="bg-white">Password:</Form.Label>
                                                <Form.Control input type="password" className="bg-white" name="password" value={password} onChange={this.handleChange} placeholder="Password" />
                                                {submitted && !password &&
                                                <div className="help-block">Password is required</div>
                                                }
                                            </Form.Group>
                                            <Form.Group className={'py-2' + (submitted && !confirmpass ? ' has-error' : '')}>
                                                <Form.Label htmlFor="pwd" className="bg-white">Confirm Password:</Form.Label>
                                                <input type="password" className="form-control" name="confirmpass" value={confirmpass} onChange={this.handleChange} placeholder="Confirm Password" />
                                                {submitted && !confirmpass &&
                                                <div className="help-block">Confirm Password is required</div>
                                                }
                                        </Form.Group>
                                        <div className="text-center pt-4">
                                            <Button onClick={this.handleSubmit} className="btn-sunpower-orange">Change Password</Button>
                                                {loggingIn &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                }
                                            </div>
                                        </Form>
                                    </Card.Body>
                                    
                                </Card>
                            </Col>
                        </Row>

                </Container>
                <Footer />
                </Fragment>
                

        );
    }
}

function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    getUsers: userActions.getAll,
    deleteUser: userActions.delete
}

const connectedHomePage = connect(mapState, actionCreators)(PasswordResetPage);
export { connectedHomePage as PasswordResetPage };