import React, { Component, Fragment } from 'react';
import './Reports.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class TCUUReportExport extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          dropdownOpen: false,
          reportType: 'tcuu',
          columns: [],
          startDate: '',
          endDate: '',
          checkedStatus: false,
          
        };
        this.reportExport = this.reportExport.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
      }

    reportExport() {
        const userVar = JSON.parse(localStorage.getItem('user'));
        let reportUrl = process.env.REACT_APP_BACKEND_URL+"/bpsadmin/reconcile/Report.php?token="+userVar.token;
        console.log(reportUrl);
        window.open(reportUrl, "_blank");
    }

    async exportExcel() {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        /*try {
            const userVar = JSON.parse(localStorage.getItem('user'));
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL+"/api/tcuureport", {
                responseType: 'blob', // Set the response type to 'blob' for downloading the file,
                headers: {
                    "Authorization" : `Bearer ${userVar.token2}`,
                }
            });
        
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'TCUUReport.xlsx');
            document.body.appendChild(link);
            link.click();
            } catch (error) {
            // Handle error
            }*/
            const userVar = JSON.parse(localStorage.getItem('user'));
            const response = await axios.get(process.env.REACT_APP_BACKEND_URL+"/api/tcuureport", {
                //responseType: 'blob', // Set the response type to 'blob' for downloading the file,
                headers: {
                    "Authorization" : `Bearer ${userVar.token2}`,
                }
            });
            if (response) {
                //console.log(response.data);
                const workbook = XLSX.utils.book_new();

                // Create a new worksheet
                const worksheet = XLSX.utils.json_to_sheet(response.data);

                // Add the worksheet to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheet, 'TCU2022');

                // Generate Excel file
                XLSX.writeFile(workbook, 'TCU2022Report.xlsx');

                /*const worksheet = XLSX.utils.json_to_sheet(response.data);
                const wb = { Sheets: { 'data': worksheet }, SheetNames: ['TCU2022'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                FileSaver.saveAs(data, 'TCU2022Report' + fileExtension);*/
            }
      };

      render() {
        return (
            <Fragment>
                <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                        <h3>TCUU Report</h3>
                        <p>Reports will be produced and downloaded in Excel.</p>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col>
                            <Button type="button" className="btn-orange" onClick={this.exportExcel}>Export Report</Button>
                        </Col>
                    </Row>
                </Container>
            </Fragment>

        );
      }
}

export default TCUUReportExport;