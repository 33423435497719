import React, { Component, Fragment } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
//import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { Table, Select, Icon, Input,  DatePicker} from 'antd';

import './Reports.css'
import { Container, Row, Col, Form } from 'react-bootstrap';
import { CalendarOutlined } from '@ant-design/icons';

const { Option } = Select;
const { MonthPicker } = DatePicker;

class MonthlyReportExport extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false,
          reportType: 'survey',
          columns: [],
          startDate: '',
          endDate: '',
          checkedStatus: false,
          column: {
            hear: false,
            motivation: false,
            purchasedBefore: false,
            refFname: false,
            refLname: false,
          },
          yearDropdown: [],
          monthDropdown: [],
          monthSelect: '',
          yearSelect: '',
          yearMonth: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.captureStartDate = this.captureStartDate.bind(this);
        this.captureEndDate = this.captureEndDate.bind(this);
        this.captureColumn = this.captureColumn.bind(this);
        this.reportExport = this.reportExport.bind(this);
        this.selectClickCheck = this.selectClickCheck.bind(this);
        this.selectClickUnCheck = this.selectClickUnCheck.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        let now = new Date();
        let past = now.getMonth();
        //console.log(past);
        const year = now.getFullYear();
        for (let i = 2019; i <= year; i++ ) {
            this.state.yearDropdown.push(i);
        }
        /*const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        for (let j = 0; j < past; j++) {
            this.state.monthDropdown.push(months[j]);
        }*/
      }
    
      toggle(e) {
          console.log(e.target.value);
        /*this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
          }));*/
        this.setState({reportType: e.target.value});
      }

      handleChange(date) {
        this.setState({
          startDate: date
        });
      }

    captureStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    captureEndDate = date => {
        //let enddate = moment(date).format("YYYY-MM-DD");
        this.setState({
            endDate: date
        });
    };

    captureColumn(e) {
        let column = this.state.column;
        if (e.target.checked) {
            let columnSelected = [];
            if (this.state !== undefined) {
                columnSelected= this.state.columns;
            }
            column[e.target.value] = true;
            console.log(columnSelected)
            columnSelected.push(e.target.value);
            this.setState({columns: columnSelected, column: column});
        } else {
            let lastCols= this.state.columns;
            let index = lastCols.indexOf(e.target.value);
            if (index > -1) {
                lastCols.splice(index, 1);
            }
            column[e.target.value] = false;
            this.setState({columns: lastCols, column: column});
        }
    }

    selectClickCheck()  {
        let columnSelected = [];
        columnSelected.push('hear');
        columnSelected.push('motivation');
        columnSelected.push('purchasedBefore');
        
        let column = {
            hear: true,
            motivation: true,
            purchasedBefore: true,
            
          };
        this.setState({checkedStatus : true, columns: columnSelected, column: column});
    }

    selectClickUnCheck() {
        const columnSelected = [];
        let column = {
            hear: false,
            motivation: false,
            purchasedBefore: false,
          }
        this.setState({checkedStatus : false, columns: columnSelected, column: column});
    }

    handleMonthChange(date, dateString) {
        console.log(date, dateString);
        //this.state.monthSelect = dateString;
        this.setState({yearMonth: dateString});
    }
    
    handleYearChange(e) {
        this.state.yearSelect = e;
        let now = new Date();
        const year = now.getFullYear();
        let past = now.getMonth();
        let monthDropdown = [];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if (e == year) {
            for (let j = 0; j < past; j++) {
                monthDropdown.push(months[j]);
            }
        } else {
            for (let j = 0; j < 12; j++) {
                monthDropdown.push(months[j]);
            }
        }
        this.setState({monthDropdown});
    }

    reportExport() {
        let month = (this.state.monthSelect < 10) ? '0'+this.state.monthSelect : this.state.monthSelect;

        let reportUrl = `https://sunpowerdocs.s3.us-west-2.amazonaws.com/test/monthlyrebateprocessedreport${this.state.yearMonth}.xlsx`;
        console.log(reportUrl);
        window.open(reportUrl, "_blank");
    }

      render() {
        return (
            <Fragment>
                <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                            <h3>Monthly Rebate Processed Report</h3>
                            <p>Reports will be produced and downloaded in Excel.</p>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col md={4}>
                            <Form.Label className="d-block pt-4">Date:</Form.Label>
                            <MonthPicker
                                id='date-picker'
                                placeholderText='Month Year'
                                onChange={this.handleMonthChange}
                            />
                            <CalendarOutlined className="date-icon" />
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col>
                            <Button type="button" className="btn-orange" onClick={this.reportExport}>Export Report</Button>
                        </Col>
                    </Row>
                </Container>

                {/* <Select style={{ width: 120 }} onChange={this.handleYearChange}>
                    {this.state.yearDropdown.map(function (yr, index) {
                        return <Option value={yr}>{yr}</Option>;
                    })}
                </Select>
                <Select style={{ width: 120 }} onChange={this.handleMonthChange}>
                    {this.state.monthDropdown.map(function (mon, index) {
                        return <Option value={index}>{mon}</Option>;
                    })}
                </Select> */}
            </Fragment>

        );
      }
}

export default MonthlyReportExport;