import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Modal, Table, Input, InputNumber, Select, Form, Button, Spin, Icon } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import './Reports.css';
import userStat from './FindStat';

const { TextArea } = Input;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props);
      const { visible, onCancel, onCreate, formString, trackingNo, systemId, form } = props;
      const formValues = JSON.parse(formString);
      this.state = { rebateStatus: '', promocode: '', referralEmailSend: false, amount: formValues['amount'], loading: true, noteData: [], ustat: userStat() };
      //console.log('construct amount: ',this.state.amount);
    }

    componentDidMount() {
      /*setTimeout(() => {
          this.setState({loading: false});
      }, 5000);*/
      const { visible, onCancel, onCreate, formString, trackingNo, systemId, form } = this.props;
      const userVar = JSON.parse(localStorage.getItem('user'));
      let url = '';
      url = `${process.env.REACT_APP_BACKEND_URL}/api/note/${systemId}`;
      axios.get(url, {
        headers: {
          "Authorization": `Bearer ${userVar.token2}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=UTF-8'
        }
      })
        .then(response => {
          //console.log(response.data);
          const tableData = [];
          response.data.map(entry => {
            const rowEntry = {
              name: entry.name,
              note: entry.note,
              createdDt: entry.createdDt,
              id: entry.id,
              action: entry.action,
              key: entry.id,
            }
            tableData.push(rowEntry);
          })
          this.setState({ noteData: tableData, loading: false });
        }).catch(error => {
          if (error.response) {
            if (error.response.data.error == "token_expired") {
              localStorage.clear();
              window.location.href = '/login';
            }
            /*console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);*/
          }
        });
    }

    handleChange = (value) => {
      //console.log(`selected ${value}`);
      this.setState({ rebateStatus: value })
    }

    handlePromoChange = (e) => {
      console.log(e.target.value);
      this.setState({ promocode: e.target.value.toLowerCase() })
    }

    handleEmailChange = (value) => {
      this.setState({ referralEmailSend: true });
    }

    handleAmountChange = (value) => {
      this.setState({ amount: value });
    }

    deleteNote = (id) => {
      this.setState({ loading: true });
      const userVar = JSON.parse(localStorage.getItem('user'));
      let url = '';
      url = `${process.env.REACT_APP_BACKEND_URL}/api/note/${id}`;
      axios.delete(url, {
        headers: {
          "Authorization": `Bearer ${userVar.token2}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=UTF-8'
        }
      })
        .then(response => {
          //console.log(response.data);
          const tableData = [];
          response.data.map(entry => {
            const rowEntry = {
              name: entry.name,
              note: entry.note,
              createdDt: entry.createdDt,
              id: entry.id,
              action: entry.action,
              key: entry.id,
            }
            tableData.push(rowEntry);
          })
          this.setState({ noteData: tableData, loading: false });
        }).catch(error => {
          if (error.response) {
            if (error.response.data.error == "token_expired") {
              localStorage.clear();
              window.location.href = '/login';
            }
            /*console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);*/
          }
        });
    }

    render() {
      console.log()
      const noteColumns = [
        {
          title: 'Note Creator',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Note',
          dataIndex: 'note',
          key: 'note',
        },
        {
          title: 'Creation Date',
          dataIndex: 'createdDt',
          key: 'createdDt',
        },
        {
          title: 'Action',
          key: 'action',
          dataIndex: 'action',
          hidden: (this.state.ustat == '' ? false : true),
          render: (text, record) => (
            <span>
              {record.action == 'delete' ? <a href='#' onClick={(e) => this.deleteNote(record.id)}>Delete</a> : null}

            </span>
          ),
        }
      ];
      const { visible, onCancel, onCreate, formString, trackingNo, systemId, form } = this.props;

      const { getFieldDecorator, getFieldValue } = form;
      //console.log(formString);
      const formValues = JSON.parse(formString);
      //console.log(formValues);
      const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
      const formTailLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14, offset: 4 },
      };
      const referralPromo = ['friends & family lease', 'friends & family purchase'];
      return (
        <Modal
          visible={visible}
          title="Add Note"
          okText="Save"
          onCancel={onCancel}
          onOk={onCreate}
          width={800}
          footer={[
            <Button key="back" className='btn-gray-secondary mb-2' onClick={onCancel}>
              Cancel
            </Button>,
            <Button key="submit" className='btn-orange' onClick={onCreate}>
              Save
            </Button>,
            <Spin spinning={this.state.loading} tip="Loading...">
              <Table
                columns={noteColumns}
                dataSource={this.state.noteData}
                scroll={{ y: 200 }}
              />
            </Spin>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item label="Note" name="note" {...formItemLayout}>
              {getFieldDecorator('note', {
                initialValue: formValues['note'],
                rules: [{ required: true }],
              })(<TextArea rows={4} />)}
            </Form.Item>
            <Form.Item name="trackingNo" style={{ display: 'none' }}>
              {getFieldDecorator('trackingNo', {
                initialValue: trackingNo,
              })(<Input />)}
            </Form.Item>
            <Form.Item name="systemId" style={{ display: 'none' }}>
              {getFieldDecorator('systemId', {
                initialValue: formValues['id'],
              })(<Input />)}
            </Form.Item>

          </Form>

        </Modal>
      );
    }
  },
);

const data = [];
const { Search } = Input;

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class Note extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: '', visible: false };
    this.columns = [
      {
        title: 'Dealer Name',
        dataIndex: 'dealer',
        width: 130,
        editable: false,
        key: 'dealer',
      },
      {
        title: 'Tracking No',
        dataIndex: 'trackingNo',
        width: 150,
        editable: false,
        key: 'trackingNo'
      },
      {
        title: 'First Name',
        dataIndex: 'fname',
        width: 130,
        editable: true,
        key: 'fname'

      },
      {
        title: 'Last Name',
        dataIndex: 'lname',
        width: 130,
        editable: true,
        key: 'lname'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        editable: true,
        width: 200
      },
      {
        title: 'Notes',
        dataIndex: 'operation',
        width: 90,
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <CollectionCreateForm
              wrappedComponentRef={this.saveFormRef}
              visible={this.state.visible}
              onCancel={this.handleCancel}
              onCreate={this.handleCreate}
              formString={record.formString}
              trackingNo={record.key}
              systemId={record.systemId}
            />
          ) : (
            <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
              Add Note
            </a>
          );
        },
      },
    ];
  }
  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ editingKey: '', visible: false });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log('Received values of form: ', values);
      const key = values['trackingNo'];
      values['emailsunempl'] = (values['emailsunempl']) ? values['emailsunempl'] : false;
      this.setState({ spinnerDisplay: true, spinnerText: 'Saving your note...' });
      const userVar = JSON.parse(localStorage.getItem('user'));

      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/note`, { values }, {
        headers: {
          "Authorization": `Bearer ${userVar.token2}`
        }
      })
        .then(response => {
          console.log('response: ', response);
          if (response) {
            /*const newData = [...this.state.data];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                ...item,
                ...values,
                });
                this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
            } else {
                newData.push(values);
                this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
            }*/
            //this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: ''  });
            this.setState({ editingKey: '', spinnerDisplay: false, spinnerText: '' });
          }
        })
        .catch(error => {
          alert(JSON.stringify(error));
        })
      /*const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
          ...item,
          ...row,
          });
          this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '', visible: false });
      } else {
          newData.push(row);
          this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '', visible: false });
      }*/

      this.setState({ editingKey: '', visible: false });
      //form.resetFields();
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };
  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: '' });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: '' });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key, visible: true });
  }

  searchEntity(value) {
    this.setState({ spinnerDisplay: true, spinnerText: 'Searching for your rebate...' });
    const userVar = JSON.parse(localStorage.getItem('user'));
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/noteSearch/${value}`, {
      headers: {
        "Authorization": `Bearer ${userVar.token2}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
      .then(response => {
        console.log(response);
        const tableData = [];
        response.data.map(entry => {
          let searchEntry = {};
          if (entry.promocode) {
            for (let key in entry) {
              searchEntry[key] = entry[key];
            }
            searchEntry['key'] = entry.trackingNo;
            const jsonString = JSON.stringify(searchEntry);
            console.log(jsonString);
            const rowEntry = {
              fname: entry.fname,
              lname: entry.lname,
              trackingNo: entry.trackingNo,
              dealer: entry.dealer,
              email: entry.email,
              key: entry.trackingNo,
              formString: jsonString,
              systemId: entry.id
            }
            tableData.push(rowEntry);
          }
          //console.log(entry);

          /*const searchEntry = {
              fname: entry.fname,
              lname: entry.lname,
              trackingNo: entry.trackingNo,
              dealer: entry.dealer,
              email: entry.email,
              key: entry.trackingNo,
              formString: entry.formString
          };*/


        })
        this.setState({ data: tableData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
      }).catch(error => {
        if (error.response) {
          if (error.response.data.error == "token_expired") {
            localStorage.clear();
            window.location.href = '/login';
          }
          /*console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);*/
        }
      });

  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });
    return (
      <Fragment>
        <Container className="py-5">
          <Row className="pt-md-5">
            <Col className="pt-5 pb-md-4">
              <h3>Add Notes</h3>
            </Col>
          </Row>
          <Row className='pt-4 '>
            <Col>
              <p>Search by First Name, Last Name, Email, or Tracking Number</p>
              <Form>
                <Form.Item>
                  <Input.Search id='search' autoComplete='off' onSearch={value => this.searchEntity(value)} placeholder = "Search" style={{ width: 200 }} />  
                  <Button className = "btn-orange-secondary d-inline ms-1" type="submit" onClick={() => this.searchEntity(document.getElementById('search').value)} >  
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <LoadingOverlay
                active={this.state.spinnerDisplay}
                spinner
                text={this.state.spinnerText}
              >

                <EditableContext.Provider value={this.props.form}>
                  <Table
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    scroll={{ y: 400 }}
                    pagination={{
                      onChange: this.cancel,
                    }}
                  />
                </EditableContext.Provider>
              </LoadingOverlay>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
    /*return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered
          dataSource={this.state.data}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            onChange: this.cancel,
          }}
        />
      </EditableContext.Provider>
    );*/
  }
}

const NoteFormTable = Form.create()(Note);
export default NoteFormTable;