import React, { Component, Fragment } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import './Reports.css'
import { Container, Row, Col, Form } from 'react-bootstrap';
import { CalendarOutlined } from '@ant-design/icons';

class DealerFullyFundReportExport extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false,
          reportType: 'rebate',
          columns: [],
          startDate: '',
          endDate: '',
          checkedStatus: false,
          column: {
            fname: false,
            lname: false,
            address1: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            email: false,
            phone: false,
            promocode: false,
            systemdate: false,
            moduleName: false,
            saletype: false,
            cardType: false,
            dname: false,
            daddress1: false,
            dcity: false,
            dzip: false,
            trackingNo: false,
            dealercode: false,
            amount: false,
            status: false,
            processDate: false,
            mailingaddress1: false,
            mailingaddress2: false,
            mailingcity: false,
            mailingstate: false,
            mailingzip: false,
            dealerType: false,
            dealerType2: false,
            referrer: false,
            watts: false,
            certification: false,
            contractDate: false,
            rebateNo: false
          }
        };
        this.handleChange = this.handleChange.bind(this);
        this.captureStartDate = this.captureStartDate.bind(this);
        this.captureEndDate = this.captureEndDate.bind(this);
        this.captureColumn = this.captureColumn.bind(this);
        this.reportExport = this.reportExport.bind(this);
        this.selectClickCheck = this.selectClickCheck.bind(this);
        this.selectClickUnCheck = this.selectClickUnCheck.bind(this);
      }
    
      toggle(e) {
          console.log(e.target.value);
        /*this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
          }));*/
        this.setState({reportType: e.target.value});
      }

      handleChange(date) {
        this.setState({
          startDate: date
        });
      }

    captureStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    captureEndDate = date => {
        //let enddate = moment(date).format("YYYY-MM-DD");
        this.setState({
            endDate: date
        });
    };

    captureColumn(e) {
        let column = this.state.column;
        if (e.target.checked) {
            let columnSelected = [];
            if (this.state !== undefined) {
                columnSelected= this.state.columns;
            }
            column[e.target.value] = true;
            console.log(columnSelected)
            columnSelected.push(e.target.value);
            this.setState({columns: columnSelected, column: column});
        } else {
            let lastCols= this.state.columns;
            let index = lastCols.indexOf(e.target.value);
            if (index > -1) {
                lastCols.splice(index, 1);
            }
            column[e.target.value] = false;
            this.setState({columns: lastCols, column: column});
        }
    }

    selectClickCheck()  {
        let columnSelected = [];
        columnSelected.push('fname');
        columnSelected.push('lname');
        columnSelected.push('address1');
        columnSelected.push('address2');
        columnSelected.push('city');
        columnSelected.push('state');
        columnSelected.push('zip');
        columnSelected.push('mailingaddress1');
        columnSelected.push('mailingaddress2');
        columnSelected.push('mailingcity');
        columnSelected.push('mailingstate');
        columnSelected.push('mailingzip');
        columnSelected.push('email');
        columnSelected.push('phone');
        columnSelected.push('promocode');
        columnSelected.push('systemdate');
        columnSelected.push('contractDate');
        columnSelected.push('moduleName');
        columnSelected.push('saletype');
        columnSelected.push('cardType');
        columnSelected.push('dname');
        columnSelected.push('daddress1');
        columnSelected.push('dcity');
        columnSelected.push('dzip');
        columnSelected.push('trackingNo');
        columnSelected.push('dealercode');
        columnSelected.push('amount');
        columnSelected.push('status');
        columnSelected.push('processDate');
        columnSelected.push('dealerType');
        columnSelected.push('dealerType2');
        columnSelected.push('referrer');
        columnSelected.push('watts');
        columnSelected.push('certification');
        columnSelected.push('rebateNo');
        let column = {
            fname: true,
            lname: true,
            address1: true,
            address2: true,
            city: true,
            state: true,
            zip: true,
            mailingaddress1: true,
            mailingaddress2: true,
            mailingcity: true,
            mailingstate: true,
            mailingzip: true,
            email: true,
            phone: true,
            promocode: true,
            systemdate: true,
            contractDate: true,
            moduleName: true,
            saletype: true,
            cardType: true,
            dname: true,
            daddress1: true,
            dcity: true,
            dzip: true,
            trackingNo: true,
            dealercode: true,
            amount: true,
            status: true,
            processDate: true,
            dealerType: true,
            dealerType2: true,
            referrer: true,
            watts: true,
            certification: true,
            rebateNo: true
          };
        this.setState({checkedStatus : true, columns: columnSelected, column: column});
    }

    selectClickUnCheck() {
        const columnSelected = [];
        let column = {
            fname: false,
            lname: false,
            address1: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            mailingaddress1: false,
            mailingaddress2: false,
            mailingcity: false,
            mailingstate: false,
            mailingzip: false,
            email: false,
            phone: false,
            promocode: false,
            systemdate: false,
            contractDate: false,
            moduleName: false,
            saletype: false,
            cardType: false,
            dname: false,
            daddress1: false,
            dcity: false,
            dzip: false,
            trackingNo: false,
            dealercode: false,
            amount: false,
            status: false,
            processDate: false,
            dealerType: false,
            dealerType2: false,
            referrer: false,
            watts: false,
            certification: false,
            rebateNo: false
          }
        this.setState({checkedStatus : false, columns: columnSelected, column: column});
    }

    reportExport() {
        //console.log(this.state.columns.toString());
        //console.log(this.state.endDate.toLocaleDateString());
        //console.log(this.state.startDate.toLocaleDateString());
        //console.log(this.state.reportType);
        const userVar = JSON.parse(localStorage.getItem('user'));
        let reportUrl = process.env.REACT_APP_BACKEND_URL+"/bpsadmin/dealerfullyfund.php?reportType="+this.state.reportType+
            "&startDate="+this.state.startDate.toLocaleDateString()+"&endDate="+this.state.endDate.toLocaleDateString()+"&userVar="+userVar.token;
        //console.log(reportUrl);
        window.open(reportUrl, "_blank");
    }

      render() {
        return (
            <Fragment>
                 <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                            <h3>Dealer Fully Funded Report</h3>
                            <p>Reports will be produced and downloaded in Excel.</p>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col md={4}>
                            <Form.Label className="d-block pt-4">Start Date:</Form.Label>
                            <DatePicker
                                id='date-picker'
                                placeholderText='mm/dd/yyyy'
                                selected={this.state.startDate}
                                onChange={this.captureStartDate}
                                dateFormat="MM-dd-yyyy"
                                className="form-control form-control-sm"
                            />
                            <CalendarOutlined className="date-icon" />
                        </Col>
                        <Col md={4}>
                            <Form.Label className="d-block pt-4">End Date:</Form.Label>
                            <DatePicker
                                id='date-picker'
                                placeholderText='mm/dd/yyyy'
                                selected={this.state.endDate}
                                onChange={this.captureEndDate}
                                dateFormat="MM-dd-yyyy"
                                className="form-control form-control-sm"
                            />
                            <CalendarOutlined className="date-icon" />

                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col>
                            <Button type="button" className="btn-orange" onClick={this.reportExport}>Export Report</Button>
                        </Col>
                    </Row>
                </Container>
            </Fragment>

        );
      }
}

export default DealerFullyFundReportExport;