import React, { Component, Fragment } from 'react';
import 'antd/dist/antd.css';
import { Table, Button, Popover, Icon, Input } from 'antd';
import axios from 'axios';
import './Dashboard.css';
import './Reports.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import './RebateTableNew.css';

const { TextArea } = Input;
class CorpDocTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            formData: new FormData(),
            ids: [],
            spinnerDisplay: false,
            spinnerText: '',
            successMessage: false,
            rebateCount: 0
        }

    }

    componentDidMount() {
        this.renderList();
    }

    getNotesToShow(notes, rows) {
        console.log(notes);
        console.log(rows);
        let table = (
            <table id="customers">
                <tbody>
                    <tr>
                        <td>
                            <TextArea defaultValue={notes} disabled="true" rows={rows} className="input-disabled" />
                        </td>
                    </tr>
                </tbody>
            </table>
        );

        return table;
    }

    /*getNotesToShow(notes, rows) {
        let table = (
            <div>{notes}</div>
        );

        return table;
    }*/

    renderList() {

        const userVar = JSON.parse(localStorage.getItem('user'));
        let tableData = [];
        this.setState({ spinnerDisplay: true, spinnerText: 'Searching for corporate uploaded documents...' });
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/corpDocUpload`, {
            headers: {
                "Authorization": `Bearer ${userVar.token2}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => {
                console.log(response);
                let rCount = this.state.rebateCount;
                response.data.map(entry => {
                    //console.log(entry);
                    let accessLog = []; let logs = ''; let logList = [];
                    if (entry.accessLog !== null) {
                        //console.log(entry.accessLog);
                        /*accessLog = entry.accessLog.map( logEntry => {
                            return '<p>Name: '+logEntry.name+' Download date: '+logEntry.downloadTime.split(' ')[0]+'</p>';
                        })*/
                        accessLog = entry.accessLog;
                        logList = entry.accessLog.map(logEntry => 'Name: ' + logEntry.name + '\nDownload date: ' + logEntry.downloadTime.split(' ')[0]);
                        logs = logList.join("\n");

                    }
                    console.log(logs);
                    console.log('logs length:' + logs.length);
                    const tableRow = {
                        'fileName': entry.fileName,
                        'uploadTime': entry.uploadTime,
                        'corpAdminName': entry.corpAdminName,
                        'downloadDate': entry.downloadDate,
                        's3FileName': entry.s3FileName,
                        'accessLog': accessLog,
                        'id': entry.id,
                        'note': (entry.note) ? entry.note : '',
                        'logs': (logs) ? this.getNotesToShow(logs, (logs.length > 1) ? 10 : 0) : ''
                    };
                    tableData.push(tableRow);
                    rCount++;

                })
                //this.setState({ data: tableData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
                //console.log(tableData);
                this.setState({ tableData, spinnerDisplay: false, spinnerText: '', rebateCount: rCount });
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        localStorage.clear();
                        window.location.href = '/login';
                    }
                    /*console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);*/
                }
            });

    }

    handleClick(event, s3FileName, id) {
        console.log(id);
        //event.stopPropagation();
        const userVar = JSON.parse(localStorage.getItem('user'));
        window.open(s3FileName);
        let row = {};
        row['id'] = id;
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/logCorpFileDownload`, { row }, {
            headers: {
                "Authorization": `Bearer ${userVar.token2}`
            }
        })
            .then(response => {
                console.log('response: ', response);
            })
            .catch(error => {
                //alert(JSON.stringify(error));
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        localStorage.clear();
                        window.location.href = '/login';
                    }
                    /*console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);*/
                }
            })
    }

    render() {
        const columns = [
            {
                title: 'Filename',
                dataIndex: 'fileName',
                key: 'fileName',
                width: 150,
                render: (select, record) => {
                    console.log(record);
                    console.log(record['accessLog'].length);
                    return (
                        <div>
                            {record['accessLog'].length > 0 ?
                                <Popover
                                    placement="topLeft"
                                    title=''
                                    content={record['logs']}
                                    arrowPointAtCenter
                                >
                                    <Icon type="file-text" />
                                </Popover>
                                : null}
                            <span> {record['fileName']}</span>
                        </div>
                    );
                }
            },
            {
                title: 'Upload Date',
                dataIndex: 'uploadTime',
                key: 'uploadTime',
                width: 100
            },
            {
                title: 'Corporate Admin Name',
                dataIndex: 'corpAdminName',
                key: 'corpAdminName',
                width: 100
            },
            {
                title: 'Download Date',
                dataIndex: 'downloadDate',
                key: 'downloadDate',
                width: 100
            },
            {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                width: 100,
                render: (text, record) => (
                    <TextArea defaultValue={record.note} disabled="true" rows={(record.note.length > 1) ? 10 : 1} className="input-disabled" />
                )
            },
            {
                title: 'Download',
                key: 's3FileName',
                dataIndex: 's3FileName',
                width: 100,
                render: (text, record) => (
                    <span>
                        <a className='blue-link' onClick={(e) => { this.handleClick(e, record.s3FileName, record.id) }}>Download</a>
                    </span>
                ),
            }
        ];

        return (
            <Fragment>

                <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                            <h3>Corporate Uploads</h3>
                        </Col>
                    </Row>
                    <Row className="py-4">
                        <Col>
                            <Table dataSource={this.state.tableData} columns={columns} scroll={{ x: 600, y: 500 }} />
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default CorpDocTable;
