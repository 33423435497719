import React, { Component, Fragment } from 'react';
import { MDBDataTable,  MDBInput } from 'mdbreact';
import axios from 'axios';
import { Button, ButtonGroup } from 'reactstrap';

import './Reports.css'
import { Container, Row, Col, Form } from 'react-bootstrap';
import './Post.css'

class Post extends Component {

  constructor(props) {
    super(props);


    this.state = {
      data : {},
      responseData: {},
      trackingNos: [],
      allcolor: "success",
      pendingcolor: "secondary",
      approvedcolor: "secondary",
      declinedcolor: "secondary",
      comments: {},
      showButtons: false,
      declineError: false,
      errorClassNames: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.commentEnter = this.commentEnter.bind(this);
  }

  handleChange = async (e) =>  {
    console.log(e.target.name);
    console.log(e.target.checked);

    if (e.target.checked) {
      let trackingNoStore= this.state.trackingNos;
      console.log(trackingNoStore)
      trackingNoStore.push(e.target.name);
      this.setState({trackingNos: trackingNoStore});
    }
    
  }

  commentEnter = async (e) =>  {
    //console.log(e.target.name);
    //console.log(e.target.value);
    let commentStore= this.state.comments;
    commentStore[e.target.name] = e.target.value
    this.setState({comments: commentStore});
  }

  toggleState = async (e) =>  {
    console.log(e.target.value);
    switch(e.target.value) {
      case "Pending":
        this.setState({pendingcolor: "success", approvedcolor: "secondary", declinedcolor: "secondary", allcolor: "secondary", showButtons: true});
        break;
      case "Approved":
        this.setState({pendingcolor: "secondary", approvedcolor: "success", declinedcolor: "secondary", allcolor: "secondary", showButtons: false});
        break;
      case "Declined":
        this.setState({pendingcolor: "secondary", approvedcolor: "secondary", declinedcolor: "success", allcolor: "secondary", showButtons: false});
        break;
      case "All":
        this.setState({pendingcolor: "secondary", approvedcolor: "secondary", declinedcolor: "secondary", allcolor: "success", showButtons: false});
        break;
    }
    this.returnRows(e.target.value, false);
  }

  componentDidMount() {
    /*axios.get('${process.env.REACT_APP_BACKEND_URL}/json.php')
      .then(response => this.setState({ post: response.data }));*/
    const userVar = JSON.parse(localStorage.getItem('user'));
    console.log(userVar);
    /*axios.get('${process.env.REACT_APP_BACKEND_URL}/rebatejson.php')
      .then(response => {
        console.log(response);
        this.setState({responseData: response});
        this.returnRows('Pending');
      });*/
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/bpsadmin/rebatejson.php`, { userVar })
        .then(response => {
          console.log(response);
          this.setState({responseData: response});
          //this.returnRows('Pending', false);
          this.returnRows('All', false);
        });
  }

  returnRows(status, resetFlag) {
    let newData = { columns: {}, rows: {}};
    newData.columns = this.state.responseData.data.payload.data.columns[status];
    if (resetFlag) {
      this.setState({data: newData});
    }
    
    let rowsTemp = [];
    if (this.state.responseData.data.payload.data.rows[status] !== undefined) {
      rowsTemp = this.state.responseData.data.payload.data.rows[status].map((item, key) => {
        //const href='https://sunpowerdocs.s3-us-west-2.amazonaws.com/'+item['sundoc'];
        const commentName = 'comment-'+item['tracking'];
        
        if (status == 'Pending') {
            return {
              check: <MDBInput key={item['tracking']} label="" type="checkbox" name={item['tracking']} id={item['tracking']} onChange={this.handleChange}/>,
              comment: <select name={commentName} id={commentName} onChange={this.commentEnter} defaultValue=''>
              <option value=''>Please select a comment to decline</option>
              <option value="System has not been installed">System has not been installed</option>
              <option value="Address doesn't match customer records">Address doesn't match customer records</option>
              <option value="Rebate was not agreed upon before contract signing">Rebate was not agreed upon before contract signing</option>
              <option value='Please contact dealer for more information'>Please contact dealer for more information</option>
              <option value='Wrong PromoCode was selected, your rebate will be resubmitted with correct promocode'>Wrong PromoCode was selected, your rebate will be resubmitted with correct promocode</option>
              </select>,
              promocode:  item['promocode'],
              rebateenterdate: item['rebateenterdate'],
              fname : item['fname'],
              lname : item['lname'],
              email : item['email'],
              phone : item['phone'],
              address : item['address'],
              city : item['city'],
              state : item['state'],
              zip : item['zip'],
              mailingaddress1 : item['mailingaddress1'],
              mailingcity : item['mailingcity'],
              mailingstate : item['mailingstate'],
              mailingzip : item['mailingzip'],
              installdate : item['installdate'],
              contractDate : item['contractDate'],
              module : item['module'],
              system : item['system'],
              selltype : item['selltype'],
              reward : item['reward'],
              amount : item['amount'],
              trackingNo : item['trackingNo'],
              referralAmount : item['referralAmount'],
              referraltrackingNo : item['referraltrackingNo'],
              dname : item['dname'],
              dcity : item['dcity'],
              dstate : item['dstate'],
              fundingStatus : item['fundingStatus'],
              shipDate : item['shipDate'],
              shipMethod : item['shipMethod'],
              contractDate : item['contractDate'],
              sundoc: item['sundoc'].map(document => {
                const href='https://sunpowerdocs.s3-us-west-2.amazonaws.com/'+document;
                return <p><a href={href} target='_blank'>{document}</a></p>;
              }),
              
            };
          } else if (status == 'Declined') {
              return {
                check: item['processDate'],
                promocode:  item['promocode'],
                rebateenterdate: item['rebateenterdate'],
                comment: item['comment'],
                fname : item['fname'],
                lname : item['lname'],
                email : item['email'],
                phone : item['phone'],
                address : item['address'],
                city : item['city'],
                state : item['state'],
                zip : item['zip'],
                mailingaddress1 : item['mailingaddress1'],
                mailingcity : item['mailingcity'],
                mailingstate : item['mailingstate'],
                mailingzip : item['mailingzip'],
                installdate : item['installdate'],
                contractDate : item['contractDate'],
                module : item['module'],
                system : item['system'],
                selltype : item['selltype'],
                reward : item['reward'],
                amount : item['amount'],
                trackingNo : item['trackingNo'],
                referralAmount : item['referralAmount'],
                referraltrackingNo : item['referraltrackingNo'],
                dname : item['dname'],
                dcity : item['dcity'],
                dstate : item['dstate'],
                fundingStatus : item['fundingStatus'],
                shipDate : item['shipDate'],
                shipMethod : item['shipMethod'],
                contractDate : item['contractDate'],
                sundoc: item['sundoc'].map(document => {
                  const href='https://sunpowerdocs.s3-us-west-2.amazonaws.com/'+document;
                  return <p><a href={href} target='_blank'>{document}</a></p>;
                }),
              }
          }  else if (status == 'Approved') {
            return {
              check: item['processDate'],
              promocode:  item['promocode'],
              rebateenterdate: item['rebateenterdate'],
              fname : item['fname'],
              lname : item['lname'],
              email : item['email'],
              phone : item['phone'],
              address : item['address'],
              city : item['city'],
              state : item['state'],
              zip : item['zip'],
              mailingaddress1 : item['mailingaddress1'],
              mailingcity : item['mailingcity'],
              mailingstate : item['mailingstate'],
              mailingzip : item['mailingzip'],
              installdate : item['installdate'],
              contractDate : item['contractDate'],
              module : item['module'],
              system : item['system'],
              selltype : item['selltype'],
              reward : item['reward'],
              amount : item['amount'],
              trackingNo : item['trackingNo'],
              referralAmount : item['referralAmount'],
              referraltrackingNo : item['referraltrackingNo'],
              dname : item['dname'],
              dcity : item['dcity'],
              dstate : item['dstate'],
              fundingStatus : item['fundingStatus'],
              shipDate : item['shipDate'],
              shipMethod : item['shipMethod'],
              contractDate : item['contractDate'],
              sundoc: item['sundoc'].map(document => {
                const href='https://sunpowerdocs.s3-us-west-2.amazonaws.com/'+document;
                return <p><a href={href} target='_blank'>{document}</a></p>;
              }),
            };
          } else {
            return {
              rebateStatus: item['rebateStatus'],
              check: item['processDate'],
              promocode:  item['promocode'],
              rebateenterdate: item['rebateenterdate'],
              comment: item['comment'],
              fname : item['fname'],
              lname : item['lname'],
              email : item['email'],
              phone : item['phone'],
              address : item['address'],
              city : item['city'],
              state : item['state'],
              zip : item['zip'],
              mailingaddress1 : item['mailingaddress1'],
              mailingcity : item['mailingcity'],
              mailingstate : item['mailingstate'],
              mailingzip : item['mailingzip'],
              installdate : item['installdate'],
              contractDate : item['contractDate'],
              module : item['module'],
              system : item['system'],
              selltype : item['selltype'],
              reward : item['reward'],
              amount : item['amount'],
              trackingNo : item['trackingNo'],
              referralAmount : item['referralAmount'],
              referraltrackingNo : item['referraltrackingNo'],
              dname : item['dname'],
              dcity : item['dcity'],
              dstate : item['dstate'],
              fundingStatus : item['fundingStatus'],
              shipDate : item['shipDate'],
              shipMethod : item['shipMethod'],
              sundoc: item['sundoc'].map(document => {
                const href='https://sunpowerdocs.s3-us-west-2.amazonaws.com/'+document;
                return <p><a href={href} target='_blank'>{document}</a></p>;
              }),
            }
          }
        });
    }
    
    //console.log(rowsTemp);
    newData.rows = rowsTemp;
    if (resetFlag) {
      setTimeout(this.setState({data: newData}), 3000);
    } else {
      this.setState({data: newData});
    }
    
  }

  processClaims = async (e) =>  {
    console.log(e.target.value);
    let processRebate = true;
    if (e.target.value == 'Declined') {
      const trackingNos = this.state.trackingNos;
      const comments = this.state.comments;
      console.log(comments);
      for (const key in trackingNos) {
        console.log(key);
        const commentKey = 'comment-'+trackingNos[key];
        console.log(commentKey);
        if (! (commentKey in comments)) {
          processRebate = false;
          this.setState({declineError: true, declineHighlightError: true});
        }
      }
      console.log(processRebate);
    }
    const userVar = JSON.parse(localStorage.getItem('user'));
    if (processRebate) {
      this.setState({declineError: false, declineHighlightError: false});
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/bpsadmin/ProcessRebate.php`, { posted_data: this.state.trackingNos, comment: this.state.comments ,action: e.target.value, userVar: userVar });
        console.log('Returned data:', response);
        this.setState({responseData: response});
        let newData= this.state.data;
        let rowsTemp = [];
        newData.rows = rowsTemp;
        this.setState({trackingNos: []});
        this.setState({data: newData});
        //this.returnRows('Pending', true);
        this.returnRows('Pending', false);
      } catch (e) {
        console.log(`Axios request failed: ${e}`);
      }
    }
    
  }

  render() {
    return (
      <Fragment>
        <Container className='py-5'>
          { this.state.showButtons ?
              <Row className='pt-5 pb-md-4'>
                <Col>
                By checking the box(s) below you can approve or decline multiple Rebates at once.
                </Col>
              </Row>
            : null
          }
          <Row>
            { this.state.showButtons ? 
                <Col>
                  { this.state.declineError ? <div className="alert alert-danger">Comments are needed for declined rebates.</div> : null}
                  <Button value="Approved" onClick={this.processClaims} className="button-spacing">Approve Rebates</Button>
                  <Button value="Declined" onClick={this.processClaims}>Decline Rebates</Button>
                </Col>
            : 
              <Col>
              </Col>
            }
            
            <Col>
              <ButtonGroup>
                <Button color={this.state.allcolor} value="All" onClick={this.toggleState}>All</Button>
                <Button color={this.state.pendingcolor} value="Pending" onClick={this.toggleState}>Pending</Button>
                <Button color={this.state.approvedcolor} value="Approved" onClick={this.toggleState}>Approved</Button>
                <Button color={this.state.declinedcolor} value="Declined" onClick={this.toggleState}>Declined</Button>
              </ButtonGroup>
            </Col>
            
          </Row>
          <Row>
            <Col>
              <MDBDataTable 
                hover 
                scrollX 
                scrollY
                maxHeight="300px"
                striped
                data={this.state.data}
              />
          </Col>
        </Row>
        </Container>
      </Fragment>
    );
  }

}

export default Post;
