import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../../_actions';
import Topper from "./Topper";
import SideCard from "./SideCard";
import '../HomePage.css';
import GeneralReport from './GeneralReport';

class Logout extends Component {
    constructor(props) {
        super(props);
        localStorage.clear();
        window.location.href = '/login';
    }

    componentDidMount() {
        localStorage.clear();
        window.location.href = '/login';
    }

    handleDeleteUser(id) {
        return (e) => this.props.deleteUser(id);
    }

    render() {
        const { user, users } = this.props;
        return (

            <div>
                <Topper />
                <GeneralReport />
            </div>
        );
    }
}

function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    getUsers: userActions.getAll,
    deleteUser: userActions.delete
}

const connectedHomePage = connect(mapState, actionCreators)(Logout);
export { connectedHomePage as Logout };