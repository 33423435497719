import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../../_actions';
import Topper from "./Topper";
import SideCard from "./SideCard";
import '../HomePage.css';
import MaxReferralReportExport from './MaxReferralReportExport';
import { Footer } from '../../footer/Footer';

class MaxReferralReport extends Component {
    componentDidMount() {
        this.props.getUsers();
    }

    handleDeleteUser(id) {
        return (e) => this.props.deleteUser(id);
    }

    render() {
        const { user, users } = this.props;
        return (

            <Fragment>
                    <Topper />
                    <MaxReferralReportExport/>
                <Footer />
            </Fragment>

        );
    }
}

function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    getUsers: userActions.getAll,
    deleteUser: userActions.delete
}

const connectedHomePage = connect(mapState, actionCreators)(MaxReferralReport);
export { connectedHomePage as MaxReferralReport };