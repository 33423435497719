import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import './Footer.css';

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <footer>
                    <p>&copy;{(new Date().getFullYear())} SunPower Corporation. All Rights Reserved.</p>
                <ul>
                    <li><a href="https://global.sunpower.com/privacy-policy" target="_blank"><font color="white">PRIVACY POLICY</font></a></li>
                    <li><a href="https://global.sunpower.com/sunpower-terms-use-agreement" target="_blank"><font color="white">TERMS</font></a></li>
                </ul>
            </footer>
        );
    }
}

//export default Footer;
function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(Footer);
export { connectedLoginPage as Footer };