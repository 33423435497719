import React, { useEffect, useState } from "react"
import { Alert, Button, Checkbox, Modal, Spin, Table, Tag } from "antd"
import axios from "axios"

const AIModal = ({ visible, onClose, systemId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [info, setInfo] = useState([]) // Variable for storing key value pairs for tags
  const [contracts, setContracts] = useState([]) // Variable for storing contracts
  const [selectedContracts, setSelectedContracts] = useState([])

  useEffect(() => {
    if (visible && systemId?.length) {
      const userVar = JSON.parse(localStorage.getItem("user"))
      let row = {}
      row["selectedIds"] = systemId
      setIsLoading(true)

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/showAIInfo`,
          { row },
          {
            headers: {
              Authorization: `Bearer ${userVar.token2}`,
            },
          }
        )
        .then((response) => {
          if (response) {
            const info = Object.entries(response?.data)
              .filter((x) => x[0] !== "contracts")
              .map((x) => ({
                label: x[0],
                isMatch: !/not/.test(x[1]),
              }))
            setInfo(info)
            setContracts(response?.data?.contracts || [])
          }
          setIsLoading(false)
        })
        .catch((error) => {
          alert(JSON.stringify(error))
        })
    }
  }, [visible])

  function onModalClose() {
    setInfo([])
    setContracts([])
    setSelectedContracts([])
    onClose()
    setError("")
  }

  function onSubmit() {
    setError("")
    const userVar = JSON.parse(localStorage.getItem("user"))
    if (!selectedContracts.length) {
      setError("Please select at least one contract")
      return
    }

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/addContractsForReLabelling`,
        { contracts: selectedContracts },
        {
          headers: {
            Authorization: `Bearer ${userVar.token2}`,
          },
        }
      )
      .then(() => {
        onModalClose()
        setIsLoading(false)
      })
      .catch((error) => {
        alert(JSON.stringify(error))
      })
  }

  return (
    <Modal
      title="AI Modal"
      visible={visible}
      onCancel={onModalClose}
      footer={null}
      width={800}
    >
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          {info.length ? (
            // Render your fetched data here
            <>
              <div className="mb-3">
                <Table
                  rowKey={({ label }) => label}
                  dataSource={info}
                  columns={[
                    {
                      title: "Label",
                      render: (_, { isMatch, label }) => {
                        return (
                          <Tag color={isMatch ? "green" : "red"}>{label}</Tag>
                        )
                      },
                    },
                    {
                      title: "Match",
                      render: (_, { isMatch }) => {
                        return isMatch ? "is a match" : "is not a match"
                      },
                    },
                  ]}
                />
              </div>

              <div>
                <h3>Select Contracts for ReLabelling</h3>
                <Checkbox.Group
                  onChange={(e) => setSelectedContracts(e)}
                  value={selectedContracts}
                  className="mb-3"
                >
                  {contracts.map((x, i) => (
                    <div key={i}>
                      <Checkbox value={x} key={i}>
                        {x}
                      </Checkbox>
                    </div>
                  ))}
                </Checkbox.Group>

                {error && (
                  <Alert type="error" message={error} className="mb-3" />
                )}
                <div>
                  <Button
                    onClick={onSubmit}
                    htmlType="button"
                    disabled={isLoading}
                    className="btn btn-orange-secondary"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <p>No data available.</p>
          )}
        </div>
      )}
    </Modal>
  )
}

export default AIModal
