import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Modal, Table, Input, InputNumber, Select, Form, Button, Spin, Icon, Popconfirm } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';

import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import userStat from './FindStat';

import './Reports.css'

const data = [];
const { Option } = Select;
/*for (let i = 0; i < 100; i++) {
  data.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}*/

const { Search } = Input;
const EditableContext = React.createContext();

class EditableCell extends React.Component {

  handleChange = (value) => {
    console.log(`selected ${value}`);
  }
  getInput = (data, index) => {
      //console.log(data);
      //console.log(index);
    if (index === 'userStatus') {
      //return <InputNumber />;
      return <Select initialValue="data" style={{ width: 120 }} onChange={this.handleChange}>
                <Option value="active">Active</Option>
                <Option value="inactive">InActive</Option>
            </Select>
    }
    return <Input />;
  };

  validateEmailUniqueness = (rule, value, callback) => {
    console.log(this.props.dataIndex);
    console.log(this.props.record.key);
    //console.log(form.getFieldValue('fname'));
    if (this.props.dataIndex == 'email' && value) {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/bpsadmin/checkifuniqueemail.php`,{email: value, id: this.props.record.key})
            .then(response => {
                console.log(response);
                if (response.data.emailError) {
                    callback('This email already exists in the system and you cannot add this email again.');
                } else {
                    callback();
                }
            })
            .catch(error => {
                alert(JSON.stringify(error));
            })
      
    } else {
      callback();
    }
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: title == 'Phone' ? false : true ,
                  message: `Please Input ${title}!`,
                },
                {
                  validator: this.validateEmailUniqueness,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput(record[dataIndex], dataIndex))}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: '', ustat: userStat() };
    /*const userVar = JSON.parse(localStorage.getItem('user'));
    axios.post("${process.env.REACT_APP_BACKEND_URL}/listdealerusers.php",{userVar: userVar})
        .then(response => {
            //console.log(response);
            response.data.map((item) => {
                data.push({
                    key: item['id'],
                    fname: item['fname'],
                    lname: item['lname'],
                    email: item['email'],
                    userStatus: item['userStatus']
                  });
              });
        })
        .catch(error => {
            alert(JSON.stringify(error));
        })*/
    this.columns = [
      {
        title: 'First Name',
        dataIndex: 'fname',
        width: '9%',
        editable: this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false : true,
      },
      {
        title: 'Last Name',
        dataIndex: 'lname',
        width: '9%',
        editable: this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false : true,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '15%',
        editable: this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false : true,
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        width: '9%',
        editable: this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false : true,
      },
      {
        title: 'Dealer Name',
        dataIndex: 'dealerName',
        width: '10%',
        editable: false,
      },
      {
        title: 'Dealer ID',
        dataIndex: 'dealerCode',
        width: '8%',
        editable: false,
      },
      {
        title: 'Register Date',
        dataIndex: 'registerDate',
        width: '10%',
        editable: false,
      },
      {
        title: 'User Status',
        dataIndex: 'userStatus',
        width: '9%',
        editable: this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false : true,
      },
      {
        title: 'User Type',
        dataIndex: 'userType',
        width: '9%',
        editable: this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false : true,
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <a
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Save & Send
                  </a>
                )}
              </EditableContext.Consumer>
              <Popconfirm title="Are you sure you want to cancel?" onConfirm={() => this.cancel(record.key)}>
                <a><br></br>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
              Edit
            </a>
          );
        },
      },
    ];
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
        //console.log(row);
        //console.log(error);
        if (error) {
            return;
        }
        
        const userVar = JSON.parse(localStorage.getItem('user'));
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/bpsadmin/resendemail.php`,{userVar: userVar, data: row, id: key})
        .then(response => {
            //console.log(response);
            if (response) {
                const newData = [...this.state.data];
                const index = newData.findIndex(item => key === item.key);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                    ...item,
                    ...row,
                    });
                    this.setState({ data: newData, editingKey: '' });
                } else {
                    newData.push(row);
                    this.setState({ data: newData, editingKey: '' });
                }
            }
        })
        .catch(error => {
            alert(JSON.stringify(error));
        })
      
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  searchEntity(value) {
    //const { value } = e.target;
    //console.log(e.target.value);
    const userVar = JSON.parse(localStorage.getItem('user'));
    const newData = [];
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/bpsadmin/listsearcheddealer.php`,{userVar: userVar, searchValue: value})
        .then(response => {
            //console.log(response);
            response.data.map((item) => {
              newData.push({
                    key: item['id'],
                    fname: item['fname'],
                    lname: item['lname'],
                    email: item['email'],
                    phone: item['phone'],
                    userStatus: item['userStatus'],
                    userType: item['userType'],
                    dealerName: item['dealerName'],
                    dealerCode: item['dealerCode'],
                    registerDate: item['registerDate']
                  });
              });
              this.setState({ data: newData, editingKey: '' });
        })
        .catch(error => {
            alert(JSON.stringify(error));
        })
    
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <Fragment>
         <Container className="py-5">
          <Row className="pt-md-5">
            <Col className="pt-5 pb-md-4">
              <h3>Resend Email</h3>
            </Col>
          </Row>
          <Row className='pt-4'>
            <Col>
              <p>Search by Dealership Name or Dealer ID</p>
              <Form>
                <Form.Item>
                  <Input.Search autoComplete='on' onSearch={value => this.searchEntity(value)} placeholder="Search" style={{ width: 200 }} />
                  <Button className="btn-orange-secondary d-inline ms-1" type="submit" onSubmit={value => this.searchEntity(value)} >
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <LoadingOverlay
                active={this.state.spinnerDisplay}
                spinner
                text={this.state.spinnerText}
              >
               
                <EditableContext.Provider value={this.props.form}>
                  <Table
                    components={components}
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    scroll={{ y: 400 }}
                    pagination={{
                      onChange: this.cancel,
                    }}
                  />
                </EditableContext.Provider>
              </LoadingOverlay>
            </Col>
          </Row>
        </Container>
            
        </Fragment>
    );
  }
}

const ResendEmailTable = Form.create()(EditableTable);
export default ResendEmailTable;