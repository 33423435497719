export const constantRoles = {
    ACCOUNTING: 'ACCOUNTING',
    BPS_ADMIN: 'BPS_ADMIN',
    MANAGEMENT_ACCESS: 'MANAGEMENT_ACCESS',
}

export const rolesName ={
    ACCOUNTING: 'ACCOUNTING',
    BPS_ADMIN: 'BPS ADMIN - SUPPORT',
    MANAGEMENT_ACCESS: 'BPS ADMIN - MANAGEMENT',
}