import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Table, Input, Select, Popconfirm, Form } from 'antd';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';

const data = [];
const { Option } = Select;
/*for (let i = 0; i < 100; i++) {
  data.push({
    key: i.toString(),
    name: `Edrward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}*/

const { Search } = Input;
const EditableContext = React.createContext();

class EditableCell extends React.Component {

  handleChange = (value) => {
    console.log(`selected ${value}`);
  }
  getInput = (data, index) => {
      //console.log(data);
      //console.log(index);
    if (index === 'saletype') {
      //return <InputNumber />;
      return <Select initialValue={{data}} style={{ width: 120 }} onChange={this.handleChange}>
                <Option value="purchased">Purchased</Option>
                <Option value="loaned">Loaned</Option>
                <Option value="leased">Leased</Option>
            </Select>
    }
    if (index === 'cardType') {
        //return <InputNumber />;
        return <Select initialValue={{data}} style={{ width: 120 }} onChange={this.handleChange}>
                  <Option value="visareward">Physical Visa</Option>
                  <Option value="virtualvisa">Virtual Visa</Option>
                  <Option value="check">Check</Option>
              </Select>
    }
    if (index === 'fundingStatus') {
        //return <InputNumber />;
        return <Select initialValue={{data}} style={{ width: 120 }} onChange={this.handleChange}>
                  <Option value="pending">Pending</Option>
                  <Option value="On Hold">Hold</Option>
              </Select>
      }
      if (index === 'status') {
        //return <InputNumber />;
        return <Select initialValue={{data}} style={{ width: 120 }} onChange={this.handleChange}>
                  <Option value="Approved">Approved</Option>
                  <Option value="Declined">Declined</Option>
                  <Option value="In Process">Pending</Option>
              </Select>
      }
      if (index === 'moduleName') {
        //return <InputNumber />;
        return <Select initialValue={{data}} style={{ width: 120 }} onChange={this.handleChange}>
                  <Option value="A-Series">A-Series</Option>
                  <Option value="X-Series">X-Series</Option>
                  <Option value="E-Series">E-Series</Option>
                  <Option value="P-Series">P-Series</Option>
              </Select>
      }
    return <Input />;
  };

  validateEmailUniqueness = (rule, value, callback) => {
    console.log(this.props.dataIndex);
    console.log(this.props.record.key);
    //console.log(form.getFieldValue('fname'));
    if (this.props.dataIndex == 'email' && value) {
        /*axios.post(`${process.env.REACT_APP_BACKEND_URL}/bpsadmin/checkifuniqueemail.php`,{email: value, id: this.props.record.key})
            .then(response => {
                console.log(response);
                if (response.data.emailError) {
                    callback('This email already exists in the system and you cannot add this email again.');
                } else {
                    callback();
                }
            })
            .catch(error => {
                alert(JSON.stringify(error));
            })*/
        callback();
    } else {
      callback();
    }
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: title == 'Phone' || title == 'Mailing Address2' ||  title == 'Address2'  ||  title == 'Comment' ? false : true ,
                  message: `Please Input ${title}!`,
                },
                {
                  validator: this.validateEmailUniqueness,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput(record[dataIndex], dataIndex))}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: '', spinnerDisplay: false , spinnerText: ''};
    /*const userVar = JSON.parse(localStorage.getItem('user'));
    axios.post("${process.env.REACT_APP_BACKEND_URL}/listdealerusers.php",{userVar: userVar})
        .then(response => {
            //console.log(response);
            response.data.map((item) => {
                data.push({
                    key: item['id'],
                    fname: item['fname'],
                    lname: item['lname'],
                    email: item['email'],
                    userStatus: item['userStatus']
                  });
              });
        })
        .catch(error => {
            alert(JSON.stringify(error));
        })*/
    this.columns = [
        {
            title: 'Dealer Name',
            dataIndex: 'dealer',
            width: 200,
            editable: false,
            key:'dealer',
            fixed: 'left'
          },
          {
            title: 'Tracking No',
            dataIndex: 'trackingNo',
            width: 200,
            editable: false,
            key:'trackingNo',
            fixed: 'left'
          },
        {
            title: 'First Name',
            dataIndex: 'fname',
            width: 200,
            editable: true,
            key:'fname',
            fixed: 'left'
            
        },
        {
            title: 'Last Name',
            dataIndex: 'lname',
            width: 200,
            editable: true,
            key:'lname',
            fixed: 'left'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key:'email',
            editable: true,
            width: 200,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            editable: true,
            key:'phone',
            width: 200,
        },
        {
            title: 'Address1',
            dataIndex: 'address1',
            editable: true,
            key:'address1',
            width: 200,
        },
        {
            title: 'Address2',
            dataIndex: 'address2',
            editable: true,
            key:'address2',
            width: 200,
        },
        {
            title: 'City',
            dataIndex: 'city',
            editable: true,
            key:'city',
            width: 200,
        },
        {
            title: 'State',
            dataIndex: 'state',
            key:'state',
            editable: true,
            width: 200,
        },
        {
            title: 'Zip',
            dataIndex: 'zip',
            key:'zip',
            editable: true,
            width: 200,
        },
        {
            title: 'Mailing Address1',
            dataIndex: 'mailingaddress1',
            key:'mailingaddress1',
            editable: true,
            width: 200,
        },
        {
            title: 'Mailing Address2',
            dataIndex: 'mailingaddress2',
            key:'mailingaddress2',
            editable: true,
            width: 200,
        },
        {
            title: 'Mailing City',
            dataIndex: 'mailingcity',
            key:'mailingcity',
            editable: true,
            width: 200,
        },
        {
            title: 'Mailing State',
            dataIndex: 'mailingstate',
            key:'mailingstate',
            editable: true,
            width: 200,
        },
        {
            title: 'Mailing Zip',
            dataIndex: 'mailingzip',
            key:'mailingzip',
            editable: true,
            width: 200,
        },
        {
            title: 'Promocode',
            dataIndex: 'promocode',
            key:'promocode',
            editable: true,
            width: 200,
        },
        {
            title: 'Module Type',
            dataIndex: 'moduleName',
            key:'moduleName',
            editable: true,
            width: 200,
        },
        {
            title: 'Install Date',
            dataIndex: 'systemdate',
            key:'systemdate',
            editable: true,
            width: 200,
        },
        {
            title: 'System Size',
            dataIndex: 'watts',
            key:'watts',
            editable: true,
            width: 200,
        },
        {
            title: 'Sale Type',
            dataIndex: 'saletype',
            key:'saletype',
            editable: true,
            width: 200,
        },
        {
            title: 'Card Type',
            dataIndex: 'cardType',
            key:'cardType',
            editable: true,
            width: 200,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key:'amount',
            editable: true,
            width: 200,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key:'status',
            editable: true,
            width: 200,
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key:'comment',
            editable: true,
            width: 200,
        },
        {
            title: 'Funding Status',
            dataIndex: 'fundingStatus',
            key:'fundingStatus',
            editable: true,
            width: 200,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            width: 200,
            render: (text, record) => {
            const { editingKey } = this.state;
            const editable = this.isEditing(record);
            return editable ? (
                <span>
                <EditableContext.Consumer>
                    {form => (
                    <a
                        onClick={() => this.save(form, record.key)}
                        style={{ marginRight: 8 }}
                    >
                        Save
                    </a>
                    )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                    <a><br></br>Cancel</a>
                </Popconfirm>
                </span>
            ) : (
                <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                Edit
                </a>
            );
            },
        },
    ];
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
        //console.log(row);
        //console.log(error);
        if (error) {
            return;
        }
        this.setState({ spinnerDisplay: true, spinnerText: 'Saving your rebate...' });
        const userVar = JSON.parse(localStorage.getItem('user'));
        row['trackingNo'] = key;
        
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/rebateedit`,{row}, {
            headers: {
                "Authorization" : `Bearer ${userVar.token2}`
            }})
        .then(response => {
            console.log('response: ',response);
            if (response) {
                const newData = [...this.state.data];
                const index = newData.findIndex(item => key === item.key);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                    ...item,
                    ...row,
                    });
                    this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
                } else {
                    newData.push(row);
                    this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
                }
                //this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: ''  });
                
            }
        })
        .catch(error => {
            alert(JSON.stringify(error));
        })
      
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  searchEntity(value) {
    //const { value } = e.target;
    console.log(value);
    this.setState({ spinnerDisplay: true, spinnerText: 'Searching for your rebate...' });
    const userVar = JSON.parse(localStorage.getItem('user'));
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rebatesearch/${value}`, {
            headers: {
                "Authorization" : `Bearer ${userVar.token2}`,
                'Accept' : 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
            }})
        .then(response => {
            console.log(response);
            const tableData = [];
            response.data.map(entry => {
                let searchEntry = {};
                if (entry.promocode) {
                    for (let key in entry) {
                        searchEntry[key] = entry[key];
                    }
                    searchEntry['key'] = entry.trackingNo;
                    console.log(searchEntry);
                    tableData.push(searchEntry);
                }
                //console.log(entry);
                
                /*const searchEntry = {
                    fname: entry.fname,
                    lname: entry.lname,
                    trackingNo: entry.trackingNo,
                    dealer: entry.dealer,
                    email: entry.email,
                    key: entry.trackingNo,
                    formString: entry.formString
                };*/
                
                
            })
            this.setState({ data: tableData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
        }).catch(error => {
            if (error.response) {
                if (error.response.data.error == "token_expired") {
                    localStorage.clear();
                    window.location.href = '/login';
                }
                /*console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);*/
              }
        });
    
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
        <Fragment>
            <LoadingOverlay
                active={this.state.spinnerDisplay}
                spinner
                text={this.state.spinnerText}
                >
                <div className="container shadow-sm bg-white rounded">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <h3>Search</h3>
                            <br></br>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 offset-sm-1 col-md-6 offset-md-1">
                        <span>Please search by First Name, Last Name or Email</span>
                        <Search placeholder="input search text" onSearch={value => this.searchEntity(value)} enterButton />
                            <br></br>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <EditableContext.Provider value={this.props.form}>
                                <Table
                                components={components}
                                bordered
                                dataSource={this.state.data}
                                columns={columns}
                                scroll={{ x: 5400 }}
                                rowClassName="editable-row"
                                pagination={{
                                    onChange: this.cancel,
                                }}
                                />
                            </EditableContext.Provider>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </Fragment>
    );
  }
}

const RebateEdit = Form.create()(EditableTable);
export default RebateEdit;