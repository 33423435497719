import React, { Component, Fragment } from 'react';
import './Reports.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { CalendarOutlined } from '@ant-design/icons';

class ReconcileReportExport extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          dropdownOpen: false,
          reportType: 'survey',
          columns: [],
          startDate: '',
          endDate: '',
          checkedStatus: false,
          
        };
        this.reportExport = this.reportExport.bind(this);
        
      }

    reportExport() {
        const userVar = JSON.parse(localStorage.getItem('user'));
        let reportUrl = process.env.REACT_APP_BACKEND_URL+"/bpsadmin/reconcile/Report.php?token="+userVar.token;
        console.log(reportUrl);
        window.open(reportUrl, "_blank");
    }

      render() {
        return (
            <Fragment>
                <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                        <h3>Reconciliation Report</h3>
                        <p>Reports will be produced and downloaded in Excel.</p>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col>
                            <Button type="button" className="btn-orange" onClick={this.reportExport}>Export Report</Button>
                        </Col>
                    </Row>
                </Container>
            </Fragment>

        );
      }
}

export default ReconcileReportExport;