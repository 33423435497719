const userStat = () => {
    const userVar = JSON.parse(localStorage.getItem('user'));
    const roles = [
        {'MANAGEMENT_ACCESS' : 120091874},
        {'BPS_ADMIN' : 120091268},
        {'ACCOUNTING' : 113870874},
        {'ROOT_ADMIN' : 213471267}
      ];
    const getByKey = (arr,key) => (arr.find(x => Object.keys(x)[0] === key) || {})[key]
    /*let findByKey = (arr,key) => {
        return (arr.find(ele=> key in ele ) || {})[key]
      }*/
    //console.log('role:'+userVar.userRole);
    //console.log('id:'+findByKey(roles,'MANAGEMENT_ACCESS'));
    //console.log('id:'+getByKey(roles,userVar.userRole));
    return getByKey(roles,userVar.userRole);
}

export default userStat;