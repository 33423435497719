import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Input, message, Button, Select, Popconfirm, Spin, Alert, Table } from 'antd';
//import DataTable from "../../Common/DataTable";
import { constantRoles, rolesName } from "./Constants";
import axios from 'axios';

const ListUsersContent = () => {

    //const [form] = Form.create();
    const { Option } = Select;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableData, setTableData] = useState();
    const [editable, setEditable] = useState();
    const [rolesUser, setRoles] = useState([]);
    const [roleId, setRoleId] = useState();
    const [totalPages, setTotalPages] = useState({});
    const [alertMessage, setAlertMessage] = useState()
    const [getSearchLoading, setGetSearchLoading] = useState(false);
    const [getUsersLoading, setGetUsersLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [resendEmail, setResendEmail] = useState(false);
    

    const resetState = () => {
        setTimeout(() => {
            setAlertMessage()
        }, 3000)
    }

    
  const getSearch = (e) => {
    setGetSearchLoading(true);
    const userVar = JSON.parse(localStorage.getItem('user'));
    const formData = new FormData();
    formData.append('search', e);
    
    try {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/listusers`, formData, {
            headers: {
              "Authorization": `Bearer ${userVar.token2}`
            }
          })
            .then(response => {
              if (response) {
                setTableData([])
                response.data.map((_user, index) => {
                  let user = {
                    key: index,
                    createdAt: _user.createdAt,
                    registered: _user.registered,
                    email: _user.email,
                    id: _user.id,
                    isActive: _user.status == true ? 'Active' : 'In-active',
                    name: _user.name,
                    roleName: _user.roleName,
                    roleValue: _user.roleName == constantRoles.MANAGEMENT_ACCESS ? rolesName.MANAGEMENT_ACCESS  : _user.roleName == constantRoles.BPS_ADMIN ? rolesName.BPS_ADMIN : _user.roleName == constantRoles.ACCOUNTING ? rolesName.ACCOUNTING : rolesName.ROOT_ADMIN,
                    roleId: _user.userRoleId,
                  }
                  setTableData(oldArr => [...oldArr, user]);
                  setGetSearchLoading(false);
                })
              }
            })
            .catch(error => {
              alert(JSON.stringify(error));
            })
        
    } catch (err) {
      console.log('onerror', err);

    }
  }

    const mapRoleName = (userRoles) => {
        userRoles.map((role) => {
            if (role.value == constantRoles.BPS_ADMIN) {
                return role.name = rolesName.BPS_ADMIN
            }
            if (role.value == constantRoles.ACCOUNTING) {
                return role.name = rolesName.ACCOUNTING
            }
            if (role.value == constantRoles.MANAGEMENT_ACCESS) {
                return role.name = rolesName.MANAGEMENT_ACCESS
            }
        })
    }

    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

            width: '9%'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '14%',
            render: ((text, record) => {
                if (record.key === editable) {
                    return (
                        <>
                            <Input defaultValue={text} onChange={(e) => record.email = e.target.value} />
                        </>
                    )
                } else {
                    return (
                        <>
                            {text}
                        </>
                    )
                }
            })
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',

            width: '6%'
        },
        {
            title: 'Registered Date',
            dataIndex: 'registered',
            key: 'registered',

            width: '6%'
        },
        {
            title: 'Role',
            dataIndex: 'roleValue',
            key: 'roleValue',
            width: '10%',
            render: ((text, record) => {
                if (record.key === editable && record.roleName !== constantRoles.MANAGEMENT_ACCESS) {
                    return (
                        <Select defaultValue={text} style={{ width: '100%' }} onChange={(value) => {
                            setRoleId(value)
                            record.roleName = value
                        }}>
                            {
                                rolesUser.slice(0).map((role, index) => (
                                    <Option key={index} value={role.value}>{role.name}</Option>
                                ))
                            }
                        </Select>
                    )
                } else {
                    return (
                        <>
                            {text}
                        </>
                    )
                }
            })
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            width: '4%',
            render: ((text, record) => {
                if (editable === record.key &&  record.roleName !== constantRoles.MANAGEMENT_ACCESS) {
                    return (
                        <>
                            <Select defaultValue={text} style={{ width: '100%' }} onChange={(value) => { record.isActive = value }}>
                                <Option value={true}>Active</Option>
                                <Option value={false}>In-active</Option>
                            </Select>
                        </>
                    )
                } else {
                    return (
                        <>
                            {text}
                        </>
                    )
                }
            })
        },
        {
            title: 'Action',
            width: '10%',
            render: ((_, record) => {
                return (
                    <>
                        <Button type='link' hidden={record.key !== editable ? false : true} onClick={() => {
                            setEditable(record.key);
                            getUserRoles();
                        }}>
                            edit
                        </Button>

                        {(record.registered == undefined || record.registered == null || record.registered == '') ?
                            (<Button type='link' hidden={record.key !== editable ? false : true} onClick={() => {
                                resendEmailHandler(record.email)
                            }}>
                                resend email
                            </Button>) : <></>
                        }

                        <Button type='link' hidden={record.key === editable ? false : true} onClick={() => {
                            updateHandler(record);
                            setEditable(null);
                        }}>
                            save
                        </Button>
                        <Button type='link' hidden={record.key === editable ? false : true}>
                            <Popconfirm title="Sure to cancel?" onConfirm={() => {
                                setEditable(null);
                                getUsesrs()
                            }}>
                                <a>Cancel</a>
                            </Popconfirm>
                        </Button>
                    </>
                )

            })
        },
    ];

    const getUsesrs = () => {
      const userVar = JSON.parse(localStorage.getItem('user'));
      setGetUsersLoading(true);
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/usermgmt`, {
        headers: {
            "Authorization": `Bearer ${userVar.token2}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
        }
    })
      .then(response => {
          //console.log(response);
          setTableData([])
          response.data.map((_user, index) => {
            if(_user.roleName!== constantRoles.superAdmin){
              
              let user = {
                  key: index,
                  username: _user.username,
                  name: _user.name,
                  email: _user.email,
                  roleName: _user.roleName,
                  roleValue: _user.roleName == constantRoles.MANAGEMENT_ACCESS ? rolesName.MANAGEMENT_ACCESS  : _user.roleName == constantRoles.BPS_ADMIN ? rolesName.BPS_ADMIN : _user.roleName == constantRoles.ACCOUNTING ? rolesName.ACCOUNTING : rolesName.ROOT_ADMIN,
                  roleId: _user.userRoleId,
                  id: _user.id,
                  isActive: _user.status == true ? 'Active' : 'In-active',
                  createdAt: _user.createdAt,
                  registered: _user.registered,
              }
              setTableData(oldArr => [...oldArr, user])
              setGetUsersLoading(false);
            }

          })
          
      }).catch(error => {
          if (error.response) {
              if (error.response.data.error == "token_expired") {
                  localStorage.clear();
                  window.location.href = '/login';
              }
              setAlertMessage(() => { return <Alert type="error" message="There was an error retrieving users"></Alert> })
              resetState()
              /*console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);*/
          }
      });
    }
    
    const getUserRoles = () => {
      let roles = [
        {roleName: 'MANAGEMENT_ACCESS', id: 120091874},
        {roleName: 'BPS_ADMIN', id: 120091268},
        {roleName: 'ACCOUNTING', id: 113870874}
      ];
      let userRoles = roles.map((role) => {
        return (
            {
                value: role.roleName,
                id: role.id
            }
        )
      });
      mapRoleName(userRoles)
      setRoles(userRoles)
    }

    useEffect(() => {
        getUsesrs()
    }, [])


    const updateHandler = async (record) => {
        setEditing(true);
        const userVar = JSON.parse(localStorage.getItem('user'));
        const formData = new FormData();
        formData.append('id', record.id);
        formData.append('username', record.username);
        formData.append('name', record.name);
        formData.append('email', record.email);
        formData.append('userRole', record.roleName);
        formData.append('isActive', record.isActive == "Active" || record.isActive == true ? true : false);
        formData.append('isDeleted', false);
        try {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/updateRole`, formData, {
                headers: {
                  "Authorization": `Bearer ${userVar.token2}`
                }
              })
                .then(response => {
                    setEditing(false);
                  if (response.data.status) {
                    getUsesrs();

                    setAlertMessage(() => { return <Alert type="success" message={`User Updated Successfully !!`}></Alert> })
                    resetState()
                  } else {
                    getUsesrs();

                    setAlertMessage(() => { return <Alert type="error" message="This email already exists in the system."></Alert> })
                    resetState();
                  }
                })
                .catch(error => {
                  getUsesrs();

                  setAlertMessage(() => { return <Alert type="error" message="There was a problem updating the user."></Alert> })
                  resetState();
                })
            
        } catch (err) {
          getUsesrs();

          setAlertMessage(() => { return <Alert type="error" message="There was a problem updating the user."></Alert> })
          resetState()

        }
           
    }

    const resendEmailHandler = async (userEmail) => {
        setResendEmail(true);
      const userVar = JSON.parse(localStorage.getItem('user'));
        const formData = new FormData();
        formData.append('email', userEmail);
        try {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/resendUserEmail`, formData, {
                headers: {
                  "Authorization": `Bearer ${userVar.token2}`
                }
              })
                .then(response => {
                    setResendEmail(false);
                  if (response) {
                    
                    setAlertMessage(() => { return <Alert type="success" message={`Email sent Successfully !!`}></Alert> })
                    resetState();
                    getUsesrs();
                  }
                })
                .catch(error => {
                    setResendEmail(false);
                  setAlertMessage(() => { return <Alert type="error" message="There was a problem resending the email."></Alert> })
                  resetState();
                  getUsesrs();
                })
            
        } catch (err) {
            setResendEmail(false);
          setAlertMessage(() => { return <Alert type="error" message="There was a problem resending the email."></Alert> })
          resetState();
          getUsesrs();

        }
    }

    return (
        <>
            <section className='vh-fill'>
                <Container className='py-5 vh-fill'>
                    <Row className="pt-md-5">
                        <Col  className="pt-5">
                            <h4>Users</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-2">
                            <Input.Search allowClear className='w-25' onSearch={(e) => {
                                getSearch(e)
                            }} />
                        </Col>
                    </Row>
                    <Row  className="py-4">
                        <Col className="alertmessage mb-1 ">

                            {alertMessage}
                        </Col>

                    </Row>
                    <Row>
                        {
                            getSearchLoading || getUsersLoading || editing || resendEmail ? <Spin className='mt-5 d-flex justify-content-center' size='large'></Spin> : <Table columns={columns} dataSource={tableData} pagination={{ pageSize: 30 }} scroll={{ y: 500 }} />
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}


export default ListUsersContent;