import React from 'react';
import {
  Collapse,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  NavLink, Row, Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
   
import { Navbar, Nav, NavDropdown, Container, Dropdown, Button } from 'react-bootstrap';
import './Topper.css'
import userStat from './FindStat';
;
export default class Topper extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      ustat: userStat()
    };
    //console.log('check:'+this.state.ustat);
    this.referralLink = this.referralLink.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  
  }

  referralLink() {
    //console.log('redirecting');
    const userVar = JSON.parse(localStorage.getItem('user'));
    //const referralLink = process.env.REACT_APP_REFERRAL_LINK+'?referralto='+this.utf8_to_b64(userVar.referralto);
    const referralLink = process.env.REACT_APP_REFERRAL_LINK+'?encEmail='+userVar.referralto
    window.open(referralLink, '_blank');
  }

  render() {
    return (
        <div>
              <Navbar expand="lg" className="navbar navbar-expand-lg fixed-top shadow-sm navbar-light bg-white px-2 pb-0">
                <NavbarBrand href="/"><img src="https://sunpowerrebate.com/images/SunPower.png" width="140px" alt="logo" /></NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ms-auto" navbar>
                    <NavItem>
                      <NavLink href="/">Home</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/submittedrebates">Submitted Rebates</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
                      <DropdownToggle nav caret>
                        Reports
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem href="/reports">
                          Rebate Report
                        </DropdownItem>
                        <DropdownItem href="/allrebatereports">
                          All Status Rebate Report
                        </DropdownItem>
                        <DropdownItem href="/dealerregreports">
                          Dealer Registration Report
                        </DropdownItem>
                        <DropdownItem href="/dealerexpreports">
                          Dealer Exception Report
                        </DropdownItem>
                        <DropdownItem href="/surveyreports">
                          Survey Report
                        </DropdownItem>
                        <DropdownItem href="/reportlogmenu">
                          Report Log Menu
                        </DropdownItem>
                        <DropdownItem href="/reportreconcile">
                          Reconcilication Report
                        </DropdownItem>
                        <DropdownItem href="/rebatereservation">
                          Rebate Reservation Report
                        </DropdownItem>
                        <DropdownItem href="/specialpromoreport">
                          Special Promotion Report
                        </DropdownItem>
                        <DropdownItem href="/maxreferralreport">
                          Max Referral Report
                        </DropdownItem>
                        <DropdownItem href="/monthlyreport">
                          Monthly Rebate Processed Report
                        </DropdownItem>
                        <DropdownItem href="/dealerfullyfund">
                          Dealer Fully Funded Report
                        </DropdownItem>
                        <DropdownItem href="/tcuureport">
                          TCUU Report
                        </DropdownItem>
                        <DropdownItem href="/snhpreport">
                          SNHP Report
                        </DropdownItem>
                      </DropdownMenu>
                  </UncontrolledDropdown>

                  <UncontrolledDropdown nav inNavbar style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'block' : 'none') }}>
                      <DropdownToggle nav caret>
                        Reports
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem href="/allrebatereports">
                            All Status Rebate Report
                        </DropdownItem>
                        <DropdownItem href="/dealerexpreports">
                          Dealer Exception Report
                        </DropdownItem>
                      </DropdownMenu>
                  </UncontrolledDropdown>
                    <NavItem style={{ display: ((this.state.ustat == 120091268 || this.state.ustat == 113870874 || this.state.ustat == 120091874) ? 'none' : 'block') }}>
                      <NavLink href="/upload">Upload</NavLink>
                    </NavItem>
                    <NavItem style={{ display: (this.state.ustat == 113870874 ? 'none' : 'block') }}>
                      <NavLink href="/resendemail">Resend Email</NavLink>
                    </NavItem>
                    <NavItem  style={{ display: ((this.state.ustat == 120091268 || this.state.ustat == 113870874 || this.state.ustat == 120091874) ? 'none' : 'block') }}>
                      <NavLink href="/seasonal">Document Approval</NavLink>
                    </NavItem>
                    <NavItem style={{ display: (this.state.ustat == 113870874 ? 'none' : 'block') }}>
                      <NavLink href="/editrebate">Edit Rebate</NavLink>
                    </NavItem>
                    <NavItem style={{ display: (this.state.ustat == 113870874 ? 'none' : 'block') }}>
                      <NavLink href="/addnote">Add Notes</NavLink>
                    </NavItem>
                    <NavItem style={{ display: ((this.state.ustat == 120091268 || this.state.ustat == 113870874 || this.state.ustat == 120091874 ) ? 'none' : 'block') }}>
                      <NavLink href="/corpdocs">Corporate Documents</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar  style={{ display: ((this.state.ustat == 120091268 || this.state.ustat == 113870874 || this.state.ustat == 120091874 ) ? 'none' : 'block') }}>
                      <DropdownToggle nav caret>
                        User Management
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem href="/addusers">
                          Add Users
                        </DropdownItem>
                        <DropdownItem href="/listusers">
                          List Users
                        </DropdownItem>
                        
                      </DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem>
                    <NavLink onClick={this.referralLink}>Referral Admin</NavLink>
                  </NavItem>
              <NavItem>
                      <Button href="/logout" className="btn btn-orange-secondary ms-auto ms-lg-4">Log Out</Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
        </div>
    );
  }
}