import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import './UploadFile.css'
import './Reports.css'
import { Container, Col, Row, Button, Form } from 'react-bootstrap';
import { Select } from 'antd';

let HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      url: "",
      reportType: '',
      formData: '',
      templateMessage: false,
      spinner: false
    }
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  toggle(e) {
    //console.log(e.target.value);
    /*this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen
      }));*/
    this.setState({ reportType: e });
  }

  handleChange = (ev) => {
    console.log(ev.target.files[0]);
    let fileParts = ev.target.files[0].name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];
    let formData = new FormData();
    formData.append("userfile", ev.target.files[0]);
    formData.append("fileType", fileType);
    formData.append("fileName", fileName);
    formData.append("reportType", this.state.reportType);
    const userVar = localStorage.getItem('user');
    formData.append('userVar', userVar);
    this.setState({ success: false, url: "", formData: formData });

  }
  // Perform the upload
  handleUpload = (ev) => {
    /*let file = this.uploadInput.files[0];
    // Split the filename to get the name and type
    let fileParts = this.uploadInput.files[0].name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];
    let formData = new FormData();
    console.log(ev.target);
    /*formData.append("userfile", ev.target.files[0]);
    formData.append("fileType", fileType);
    formData.append("fileName", fileName);
    formData.append("reportType", this.state.reportType);*/
    console.log("Preparing the upload");
    let url = '';
    if (this.state.reportType == 'dealer') {
      url = "/bpsadmin/" + 'uploaddealerfile.php';
    } else if (this.state.reportType == 'corp') {
      url = "/bpsadmin/" + 'uploadcorporate.php';
    } else if (this.state.reportType == 'rebate') {
      url = "/bpsadmin/" + 'uploaddealerreg.php';
    } else if (this.state.reportType == 'promopdf') {
      url = "/bpsadmin/" + 'uploaddealerpromopdf.php';
    } else if (this.state.reportType == 'rebatetemplate') {
      url = "/bpsadmin/" + 'uploadDealerRebateTemplate.php';
    } else if (this.state.reportType == 'specialpromo') {
      url = '/api/specialpromotionupload';
    } else if (this.state.reportType == 'sseventytwentyfivepromo') {
      url = "/bpsadmin/" + 'uploadSpecialPromoRebates.php';
    } else if (this.state.reportType == 'custompromorebates') {
      url = '/api/customPromoUpload';
    } else if (this.state.reportType == 'custompromoreqdfields') {
      url = '/api/uploadPromoReqdFields';
    } else if (this.state.reportType == 'hawaiianupload') {
      url = '/api/fileupload';
    } else if (this.state.reportType == 'uploadQ32023Incentive') {
      url = '/api/uploadQ32023Incentive';
    } else if (this.state.reportType == 'uploadNewHomeBuilder') {
      url = '/api/uploadNewHomeBuilder';
    } else {
      url = '/api/promotionupload';
    }
    this.setState({ spinner: true });
    const apiEndPoints = ['promo', 'specialpromo', 'custompromoreqdfields', 'custompromorebates', 'hawaiianupload', 'uploadQ32023Incentive'];
    //if (this.state.reportType == 'promo' || this.state.reportType == 'specialpromo') {
    if (apiEndPoints.includes(this.state.reportType)) {
      const userVar = JSON.parse(localStorage.getItem('user'));
      axios.post(process.env.REACT_APP_BACKEND_URL + url, this.state.formData, {
        headers: {
          "Authorization": `Bearer ${userVar.token2}`
        }
      })
        .then(response => {
          console.log('response: ', response);
          if (response || response.data) {
            this.setState({ success: response.data.status, templateMessage: response.data.error, spinner: false });
          }
        })
        .catch(error => {
          //alert(JSON.stringify(error));
          if (error.response) {
            if (error.response.data.error == "token_expired") {
              localStorage.clear();
              window.location.href = '/login';
            }
            /*console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);*/
          }
        })
    } else {
      axios.post(process.env.REACT_APP_BACKEND_URL + url, this.state.formData)
        .then(response => {
          console.log(response);
          this.setState({ success: response.data.status, templateMessage: response.data.error, spinner: false });
          //var returnData = response.data.data.returnData;
          //var signedRequest = returnData.signedRequest;
          //var url = returnData.url;
          //this.setState({url: url})
          //console.log("Recieved a signed request " + signedRequest);

          // Put the fileType in the headers for the upload
          /*var options = {
            headers: {
              'Content-Type': fileType
            }
          };
          /*axios.put(signedRequest,file,options)
          .then(result => {
            console.log("Response from s3")
            this.setState({success: true});
          })
          .catch(error => {
            alert("ERROR " + JSON.stringify(error));
          })*/
        })
        .catch(error => {
          alert(JSON.stringify(error));
        })
    }

  }


  render() {
    const Success_message = () => (
      <div>
        <div className="alert alert-success">SUCCESSFUL UPLOAD</div>
        <br />
      </div>
    )
    const Error_message = () => (
      <div>
        <div className="alert alert-danger">{htmlToReactParser.parse(this.state.templateMessage)}</div>
        <br />
      </div>
    )
    return (
      <Container className="py-5 vh-fill">
        <Row className="pt-md-5">
          <Col className="pt-5 pb-md-4">
            <h3>Upload File</h3>
          </Col>
        </Row>
        <Row>
          <Col className='border p-4'>
            <Form layout="inline">
              <p className='mb-1'>Select File Type to Upload</p>
              <Select placeholder="Select File Type" style={{ width: 350 }} onChange={this.toggle} className="pe-2 mb-4">
                <Option value="dealer">Dealer File</Option>
                <Option value="promo">Promotion File</Option>
                <Option value="corp">Corporate Admin File</Option>
                <Option value="rebate">Batch Rebate File</Option>
                <Option value="promopdf">Promocode PDF</Option>
                <Option value="rebatetemplate">Rebate Template Uploade</Option>
                <Option value="specialpromo">TCUU Special Promotion Rebate Upload</Option>
                <Option value="sseventytwentyfivepromo">$75 & $25 Special Promotion Rebate Upload</Option>
                <Option value="custompromoreqdfields">Special Promotion Required Fields Upload</Option>
                <Option value="custompromorebates">Special Promotions Upload</Option>
                <Option value="hawaiianupload">Hawaiian File Upload</Option>
                <Option value="uploadQ32023Incentive">Q3-2023 Incentive</Option>
                <Option value="uploadNewHomeBuilder">New Home Builder</Option>
              </Select>
            </Form>
            {this.state.spinner ? <FontAwesomeIcon icon={faSpinner} transform="grow-7 left-1.5 up-2.2" spin /> : null}
            {this.state.success ? <Success_message /> : null}
            {this.state.templateMessage ? <Error_message /> : null}
            <input className='form-control w-50' onChange={this.handleChange} ref={(ref) => { this.uploadInput = ref; }} type="file" />
            <Button type="submit" className="btn-orange mt-4" onClick={this.handleUpload}>Upload File</Button>
          </Col>
        </Row>
      </Container>

    );
  }
}
export default UploadFile;