import React, { Component, Fragment } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import './Reports.css'
import { Container, Row, Col, Form } from 'react-bootstrap';
import { CalendarOutlined } from '@ant-design/icons';

class MaxReferralReportExport extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false,
          reportType: 'survey',
          columns: [],
          startDate: '',
          endDate: '',
          checkedStatus: false,
          column: {
            hear: false,
            motivation: false,
            purchasedBefore: false,
            refFname: false,
            refLname: false,
          }
        };
        this.handleChange = this.handleChange.bind(this);
        this.captureStartDate = this.captureStartDate.bind(this);
        this.captureEndDate = this.captureEndDate.bind(this);
        this.captureColumn = this.captureColumn.bind(this);
        this.reportExport = this.reportExport.bind(this);
        this.selectClickCheck = this.selectClickCheck.bind(this);
        this.selectClickUnCheck = this.selectClickUnCheck.bind(this);
      }
    
      toggle(e) {
          console.log(e.target.value);
        /*this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
          }));*/
        this.setState({reportType: e.target.value});
      }

      handleChange(date) {
        this.setState({
          startDate: date
        });
      }

    captureStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    captureEndDate = date => {
        //let enddate = moment(date).format("YYYY-MM-DD");
        this.setState({
            endDate: date
        });
    };

    captureColumn(e) {
        let column = this.state.column;
        if (e.target.checked) {
            let columnSelected = [];
            if (this.state !== undefined) {
                columnSelected= this.state.columns;
            }
            column[e.target.value] = true;
            console.log(columnSelected)
            columnSelected.push(e.target.value);
            this.setState({columns: columnSelected, column: column});
        } else {
            let lastCols= this.state.columns;
            let index = lastCols.indexOf(e.target.value);
            if (index > -1) {
                lastCols.splice(index, 1);
            }
            column[e.target.value] = false;
            this.setState({columns: lastCols, column: column});
        }
    }

    selectClickCheck()  {
        let columnSelected = [];
        columnSelected.push('hear');
        columnSelected.push('motivation');
        columnSelected.push('purchasedBefore');
        
        let column = {
            hear: true,
            motivation: true,
            purchasedBefore: true,
            
          };
        this.setState({checkedStatus : true, columns: columnSelected, column: column});
    }

    selectClickUnCheck() {
        const columnSelected = [];
        let column = {
            hear: false,
            motivation: false,
            purchasedBefore: false,
          }
        this.setState({checkedStatus : false, columns: columnSelected, column: column});
    }

    reportExport() {
        const userVar = JSON.parse(localStorage.getItem('user'));
        let reportUrl = `${process.env.REACT_APP_BACKEND_URL}/bpsadmin/MaxReferralReport.php?token=`+userVar.token;
        console.log(reportUrl);
        window.open(reportUrl, "_blank");
    }

      render() {
        return (
            <Fragment>
                <Container className="py-5 vh-fill">
                    <Row className="pt-md-5">
                        <Col className="pt-5">
                        <h3>Max Referral Report</h3>
                        <p>Reports will be produced and downloaded in Excel.</p>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col>
                            <Button type="button" className="btn-orange" onClick={this.reportExport}>Export Report</Button>
                        </Col>
                    </Row>
                </Container>
            </Fragment>

        );
      }
}

export default MaxReferralReportExport;