import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Container, Card, Alert, Form } from 'react-bootstrap';
import 'antd/dist/antd.css';
import "react-datepicker/dist/react-datepicker.css";
import './Dashboard.css';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import { Select } from 'antd';
//import { Form, Select } from 'antd';
import { history } from '../../_helpers';

const { Option } = Select;


const DashboardContent = () => {
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const [spinnerText, setSpinnerText] = useState('Loading your content...');
    const [chartDataCount, setChartDataCount] = useState([{ name: '', rcount: 0, ramount: 0 }]);
    const [chartDataAmount, setChartDataAmount] = useState([{ name: '', rcount: 0, ramount: 0 }]);
    const [yrOptionsCount, setYrOptionsCount] = useState([]);
    const [yrOptionsAmt, setYrOptionsAmt] = useState([]);
    const [yrOptionsStatus, setYrOptionsStatus] = useState([]);
    const [rcount, setRCount] = useState([]);
    const [ramount, setRAmt] = useState([]);
    const [statusCount, setSCount] = useState([]);
    const [countYr, setCountYr] = useState(0);
    const [amtYr, setAmtYr] = useState(0);
    const [statusYr, setStatusYr] = useState(0);
    const [countMon, setCountMon] = useState('year');
    const [amtMon, setAmtMon] = useState('year');
    const [statusMon, setStatusMon] = useState('year');
    const [rebateCount, setRebateCount] = useState(0);
    const [rebateAmt, setRebateAmt] = useState(0);
    const [defaultCountYr, setDefaultCountYr] = useState('');
    const [defaultAmtYr, setDefaultAmtYr] = useState('');
    const [defaultStatsYr, setDefaultStatsYr] = useState('');

    const [totalRebates, setTotalRebates] = useState(0);
    const [totalProcessed, setTotalProcessed] = useState(0);
    const [totalRegistered, setTotalRegistered] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [totalApproved, setTotalApproved] = useState(0);
    const [totalDeclined, setTotalDeclined] = useState(0);
    const [statusCountYr, setStatusCountYr] = useState(0);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const amountFormat = (amount)  => {
        if (amount !== null && amount !== undefined) {
    
            let result = String(amount).includes('.') ? parseFloat(amount).toFixed(2) : amount + '.00';
            result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `$${result}`;
        
        } else {
            return "";

        }
    
    }

    const numberFormat = (amount)  => {
        if (amount !== null && amount !== undefined) {
    
            let result = String(amount).includes('.') ? parseInt(amount) : parseInt(amount);
            result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `${result}`;
        
        } else {
            return "";

        }
    
    }

    const getYTDData = (data, assocKey) => {
        let currentYr = new Date().getFullYear();
        const months = {
            1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul',
            8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
        };
        currentYr = (data[currentYr]) ? currentYr : currentYr - 1;
        if (assocKey == 'rcount') {
            setDefaultCountYr(currentYr);
        } else {
            setDefaultAmtYr(currentYr);
        }
        let mthCtr = 0; let chartData = []; let statusCount = 0; let rebateAmount = 0;
        while (true) {
            let properties = Object.keys(data[currentYr]).reverse();
            properties.forEach(prop => {
                //console.log(`PropertyName: ${prop}, its Value: ${foo[prop]}`)
                if (mthCtr < 9) {
                    if (assocKey == 'rcount') {
                        chartData.push({ 'name': months[prop], 'rebatecount': data[currentYr][prop] });
                        //console.log(data[currentYr][prop]);
                        statusCount += parseInt(data[currentYr][prop]);

                    } else {
                        chartData.push({ 'name': months[prop], 'rebateamount': parseFloat(data[currentYr][prop]) });
                        rebateAmount += parseFloat(data[currentYr][prop]);
                    }
                }
                mthCtr++;
            });
            if (mthCtr > 8) {
                break;
            } else {
                currentYr--;
            }
        }
        if (rebateCount == 0 && statusCount != 0) {
            setRebateCount(numberFormat(statusCount));
        }
        setRebateAmt(formatter.format(rebateAmount));
        let chartDataTmp = [];
        let keyProps = Object.keys(chartData).reverse();
        keyProps.forEach(prop => {
            chartDataTmp.push(chartData[prop]);
        });
        //console.log(chartDataTmp)
        //console.log(mthCtr);
        if (assocKey == 'rcount') {
            setChartDataCount(chartDataTmp);
        } else {
            setChartDataAmount(chartDataTmp);
        }
    }
    const userVar = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (userVar.atype == 'support') {
            history.push('/submittedrebates');
        }
        setSpinnerDisplay(true);

        axios.get(process.env.REACT_APP_BACKEND_URL + '/api/getDashboardData', {
            headers: {
                "Authorization": `Bearer ${userVar.token2}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => {
                //console.log(response);
                getYTDData(response.data.rcount, 'rcount');
                //console.log('here1');
                getYTDData(response.data.ramount, 'ramount');
                //console.log('here2');
                //console.log(response.data.yrOptionsCount);
                let selectCount = []
                Object.keys(response.data.yrOptionsCount).map(index => {
                    selectCount.push(response.data.yrOptionsCount[index])
                })
                setYrOptionsCount(selectCount);
                //console.log('here3');
                let selectAmt = []
                Object.keys(response.data.yrOptionsAmt).map(index => {
                    selectAmt.push(response.data.yrOptionsAmt[index])
                })
                setYrOptionsAmt(selectAmt);
                let selectStatus = []
                Object.keys(response.data.yrStatusCount).map(index => {
                    selectStatus.push(response.data.yrStatusCount[index])
                })
                setYrOptionsStatus(selectStatus);
                //console.log('here4');
                setRCount(response.data.rcount);
                //console.log('here5');
                setRAmt(response.data.ramount);
                //console.log('here6');
                setSCount(response.data.statusCount);
                //console.log('here7');
                if (response.data.statusCount['Rebate Registered']) {
                    getYTDStatusData(response.data.statusCount, 'Rebate Registered');
                }
                if (response.data.statusCount['approved']) {
                    getYTDStatusData(response.data.statusCount, 'approved');
                }
                if (response.data.statusCount['declined']) {
                    getYTDStatusData(response.data.statusCount, 'declined');
                }
                if (response.data.statusCount['in process']) {
                    getYTDStatusData(response.data.statusCount, 'in process');
                }
                if (response.data.statusCount['processing']) {
                    getYTDStatusData(response.data.statusCount, 'processing');
                }
                if (response.data.statusCount['totalRebates']) {
                    getYTDStatusData(response.data.statusCount, 'totalRebates');
                }
                /*getYTDStatusData(response.data.statusCount, 'approved');
                getYTDStatusData(response.data.statusCount, 'declined');
                getYTDStatusData(response.data.statusCount, 'in process');
                getYTDStatusData(response.data.statusCount, 'processing');
                getYTDStatusData(response.data.statusCount, 'totalRebates');*/
                setSpinnerDisplay(false);

            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        localStorage.clear();
                        window.location.href = '/login';
                    }
                    /*console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);*/
                }
            });

    }, []);

    const calculateRebateCount = (value) => {
        //console.log('value:',value);
        if (defaultCountYr) {
            if (countMon == 'year') {
                let statusCount = 0;
                let properties = Object.keys(rcount[countYr || defaultCountYr]).reverse();
                properties.forEach(prop => {
                    statusCount += parseInt(rcount[countYr || defaultCountYr][prop]);
                });
                setRebateCount(numberFormat(statusCount));
            } else {
                if (value) {
                    setRebateCount(numberFormat(rcount[countYr || defaultCountYr][value]) || 0);
                } else {
                    setRebateCount(numberFormat(rcount[countYr || defaultCountYr][countMon] || 0));
                }


            }
        }
    }

    const calculateRebateAmount = (value) => {
        //console.log('value:',value);
        if (defaultAmtYr) {
            if (amtMon == 'year') {
                let statusCount = 0;
                let properties = Object.keys(ramount[amtYr || defaultAmtYr]).reverse();
                properties.forEach(prop => {
                    statusCount += parseFloat(ramount[amtYr || defaultAmtYr][prop]);
                });
                setRebateAmt(formatter.format(statusCount));
            } else {
                if (value) {
                    setRebateAmt(formatter.format(ramount[amtYr || defaultAmtYr][value] || 0));
                } else {
                    setRebateAmt(formatter.format(ramount[amtYr || defaultAmtYr][amtMon] || 0));
                }


            }
        }
    }

    const handleCountYr = (value) => {
        setCountYr(value);
        const months = {
            1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul',
            8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
        };
        const yrVals = rcount[value];
        let keyProps = Object.keys(yrVals);
        let chartDataTmp = [];
        keyProps.forEach(prop => {
            chartDataTmp.push({ 'name': months[prop], 'rebatecount': yrVals[prop] });
        });
        console.log(chartDataTmp);
        setChartDataCount(chartDataTmp);
    }

    const handleCountMon = (value) => {
        setCountMon(value);
    }

    useEffect(() => {
        calculateRebateCount(countMon);
    }, [countMon]);
    useEffect(() => {
        calculateRebateCount(undefined);
    }, [countYr]);

    const handleAmtYr = (value) => {
        setAmtYr(value);
        const months = {
            1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul',
            8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
        };
        const yrVals = ramount[value];
        let keyProps = Object.keys(yrVals);
        let chartDataTmp = [];
        keyProps.forEach(prop => {
            chartDataTmp.push({ 'name': months[prop], 'rebateamount': parseFloat(yrVals[prop]) });
        });
        console.log(chartDataTmp);
        setChartDataAmount(chartDataTmp);
    }

    const handleAmountMon = (value) => {
        setAmtMon(value);
    }

    useEffect(() => {
        calculateRebateAmount(undefined);
    },[amtYr])
    useEffect(() => {
        calculateRebateAmount(amtMon);
    },[amtMon])

    const handleStatusYr = (value) => {
        setStatusYr(value);
        calculateStatusCountValues(value, 'year', 'Rebate Registered');
        calculateStatusCountValues(value, 'year', 'approved');
        calculateStatusCountValues(value, 'year', 'declined');
        calculateStatusCountValues(value, 'year', 'in process');
        calculateStatusCountValues(value, 'year', 'processing');
        calculateStatusCountValues(value, 'year', 'totalRebates');
    }

    const handleStatusMon = (value) => {
        setStatusMon(value);
        calculateStatusCountValues(value, 'month', 'Rebate Registered');
        calculateStatusCountValues(value, 'month', 'approved');
        calculateStatusCountValues(value, 'month', 'declined');
        calculateStatusCountValues(value, 'month', 'in process');
        calculateStatusCountValues(value, 'month', 'processing');
        calculateStatusCountValues(value, 'month', 'totalRebates');
    }

    const getYTDStatusData = (data, status) => {
        let currentYr = new Date().getFullYear();
        const months = {
            1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul',
            8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
        };
        //console.log(data);
        currentYr = (data[status][currentYr]) ? currentYr : currentYr - 1;
        if (statusCountYr == 0) {
            setStatusCountYr(currentYr)
        }
        currentYr = (statusCountYr != 0) ? statusCountYr : currentYr;
        let properties = Object.keys(data[status][currentYr]).reverse();
        
        let totalCtr = 0;
        properties.forEach(prop => {
            totalCtr += parseInt(data[status][currentYr][prop]);
            console.log(parseInt(data[status][currentYr][prop])+'|'+totalCtr);
        });

        if (status == 'Rebate Registered') {
            setTotalRegistered(numberFormat(totalCtr));
        } else if (status == 'approved') {
            setTotalApproved(numberFormat(totalCtr));
        } else if (status == 'declined') {
            setTotalDeclined(numberFormat(totalCtr));
        } else if (status == 'in process') {
            setTotalPending(numberFormat(totalCtr));
        } else if (status == 'totalRebates') {
            setTotalRebates(numberFormat(totalCtr));
        } else {
            setTotalProcessed(numberFormat(totalCtr));
        }
    }

    const calculateStatusCountValues = (value, type, status) => {
        let yrSelect = statusYr; let monSelect = statusMon;
        if (type == 'year') {
            yrSelect = value
        } else {
            monSelect = value;
        }
        if (monSelect == 'year') {
            let statsCount = 0;
            //console.log(statusCount);
            if (statusCount[status][yrSelect]) {
                let properties = Object.keys(statusCount[status][yrSelect]).reverse();
                properties.forEach(prop => {
                    statsCount += parseInt(statusCount[status][yrSelect][prop]);
                });
            }

            if (status == 'Rebate Registered') {
                setTotalRegistered(numberFormat(statsCount));
            } else if (status == 'approved') {
                setTotalApproved(numberFormat(statsCount));
            } else if (status == 'declined') {
                setTotalDeclined(numberFormat(statsCount));
            } else if (status == 'in process') {
                setTotalPending(numberFormat(statsCount));
            } else if (status == 'totalRebates') {
                setTotalRebates(numberFormat(statsCount));
            } else {
                setTotalProcessed(numberFormat(statsCount));
            }
        } else {
            const statusDisplayCOunt = (statusCount[status][yrSelect] && statusCount[status][yrSelect][monSelect]) ? statusCount[status][yrSelect][monSelect] : 0;
            if (status == 'Rebate Registered') {
                setTotalRegistered(numberFormat(statusDisplayCOunt));
            } else if (status == 'approved') {
                setTotalApproved(numberFormat(statusDisplayCOunt));
            } else if (status == 'declined') {
                setTotalDeclined(numberFormat(statusDisplayCOunt));
            } else if (status == 'in process') {
                setTotalPending(numberFormat(statusDisplayCOunt));
            } else if (status == 'totalRebates') {
                setTotalRebates(numberFormat(statusDisplayCOunt));
            } else {
                setTotalProcessed(numberFormat(statusDisplayCOunt));
            }

        }
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const cLabel = (payload[0].name == 'rebatecount') ? 'Rebate Count' : 'Rebate Amount';
            const cValue = (payload[0].name == 'rebatecount') ? payload[0].value : amountFormat(payload[0].value);
          return (
            <div className="custom-tooltip">
              <p className="label">{`${cLabel} : ${cValue}`}</p>
            </div>
          );
        }
      
        return null;
    };
    const CustomYAxisTick = (props) => {
        const { x, y, payload } = props;
        let formattedValue;

        if (payload.value >= 1000000) {
            formattedValue = `${payload.value / 1000000}M`;
        } else if (payload.value >= 1000) {
            formattedValue = `${payload.value / 1000}K`;
        } else {
            formattedValue = payload.value;
        }
        
        return (
          <text x={x} y={y} dy={16} textAnchor="end" fill="#808080">
            {formattedValue}
          </text>
        );
      };
    return (
        <LoadingOverlay
            active={spinnerDisplay}
            spinner
            text={spinnerText}
        >
            <Container fluid className="pb-4 px-5 vh-fill">
                <Row className="pt-md-5">
                    <Col className="pt-5">
                        <h4>Hello, {userVar.details.fname}</h4>
                        <h6 className="pb-2">Welcome to the SunPower Rebate Portal</h6>
                        <Alert className="alert alert-light bg-sp-blue text-white shadow-sm pt-3 px-4">
                            <h6 className="text-white d-inline">Update your closed sales:</h6>
                            <p className="mb-0 d-inline fw-light"> Please visit the <a href="https://sunpower1.force.com/signin" target="_blank" className="blue-link text-white">SunPower Portal</a> to report your closed sales.</p>
                        </Alert>
                    </Col>
                </Row>
                <Row className="g-2">
                    <Col xxl={5} xl={6} lg={6} md={12}>
                        <Card className="p-4 h-100">
                            <Row className="border-bottom pb-2">
                                <Col>
                                    <h2 className="sp-green fs-5 fw-bold">{rebateCount}</h2>
                                    <h6>Total Rebates Approved/Processed</h6>
                                </Col>
                            </Row>
                            <Row className="g-2">
                                <Col xs={6}>
                                    <Form.Label className="mb-0 date-label">Year:</Form.Label>
                                    <Select
                                        className="w-100"
                                        onChange={handleCountYr}
                                        value={countYr ? countYr :defaultCountYr}
                                        style={{ width: 160 }}
                                    >
                                        {yrOptionsCount.map(yrVal =>
                                            (<Option value={yrVal}>{yrVal}</Option>))}
                                    </Select>

                                </Col>
                                <Col xs={6}>
                                    <Form.Label className="mb-0 date-label">Month:</Form.Label>
                                    <Select defaultValue="year" className="w-100" onChange={handleCountMon}>
                                        <Option value="year">Entire Year</Option>
                                        <Option value="1">January</Option>
                                        <Option value="2">February</Option>
                                        <Option value="3">March</Option>
                                        <Option value="4">April</Option>
                                        <Option value="5">May</Option>
                                        <Option value="6">June</Option>
                                        <Option value="7">July</Option>
                                        <Option value="8">August</Option>
                                        <Option value="9">September</Option>
                                        <Option value="10">October</Option>
                                        <Option value="11">November</Option>
                                        <Option value="12">December</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                <Col>
                                    <div style={{ width: '100%', height: 240 }}>
                                        <ResponsiveContainer className='px-2'>
                                            <LineChart data={chartDataCount} margin={{ top: 5, right: 5, bottom: 5, left: -5 }} width={200} height={240}>
                                                <Line type="monotone" dataKey="rebatecount" stroke="#F79320" />
                                                <CartesianGrid stroke="#f5f5f5" />
                                                <XAxis dataKey="name" stroke="#808080" />
                                                <YAxis padding={{ top: 20, bottom: 20 }} stroke="#808080" />
                                                <Tooltip content={<CustomTooltip />} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xxl={5} xl={6} lg={6} md={12}>
                        <Card className="p-4 h-100">
                            <Row className="border-bottom pb-2">
                                <Col>
                                    <h2 className="sp-green fs-5 fw-bold">{rebateAmt}</h2>
                                    <h6>Total $ Amount of Rebates</h6>
                                </Col>
                            </Row>
                            <Row className="g-2">
                                <Col md={6} xs={6}>
                                    <Form.Label className="mb-0 date-label">Year:</Form.Label>
                                    <Select
                                        className="w-100"
                                        onChange={handleAmtYr}
                                        value={amtYr ? amtYr: defaultAmtYr}
                                        style={{ width: 160 }}
                                    >
                                        {yrOptionsAmt.map(yrVal =>
                                            (<Option value={yrVal}>{yrVal}</Option>))}
                                    </Select>
                                </Col>
                                <Col md={6} xs={6}>
                                    <Form.Label className="mb-0 date-label">Month:</Form.Label>
                                    <Select defaultValue="year" className="w-100" onChange={handleAmountMon}>
                                        <Option value="year">Entire Year</Option>
                                        <Option value="1">January</Option>
                                        <Option value="2">February</Option>
                                        <Option value="3">March</Option>
                                        <Option value="4">April</Option>
                                        <Option value="5">May</Option>
                                        <Option value="6">June</Option>
                                        <Option value="7">July</Option>
                                        <Option value="8">August</Option>
                                        <Option value="9">September</Option>
                                        <Option value="10">October</Option>
                                        <Option value="11">November</Option>
                                        <Option value="12">December</Option>
                                    </Select>
                                </Col>

                            </Row>
                            <Row className="pt-4">
                                <Col>
                                    <div style={{ width: '100%', height: 250 }}>
                                        <ResponsiveContainer className='px-2'>
                                            <LineChart data={chartDataAmount} margin={{ top: 5, right: 2, bottom: 5, left: 10 }}>
                                                <Line type="monotone" dataKey="rebateamount" stroke="#F79320" />
                                                <CartesianGrid stroke="#f5f5f5" />
                                                <XAxis dataKey="name" stroke="#808080"   />
                                                <YAxis   padding={{ top: 20, bottom: 20 }} stroke="#808080" tick={<CustomYAxisTick />}/>
                                                <Tooltip content={<CustomTooltip />} />
                                            </LineChart>


                                        </ResponsiveContainer>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xxl={2} xl={12} lg={12} md={12}>
                        <Card className="p-4 h-100">
                            <Row className="border-bottom pb-2">
                                <Col>
                                    <h2 className="sp-green fs-5 fw-bold">{totalRebates}</h2>
                                    <h6>Total Rebates</h6>
                                </Col>
                            </Row>
                            <Row className="g-2">
                                <Col md={6} xs={6}>
                                    <Form.Label className="mb-0 date-label">Year:</Form.Label>
                                    <Select
                                        className="w-100"
                                        onChange={handleStatusYr}
                                        value={statusYr? statusYr: statusCountYr}
                                        style={{ width: 160 }}
                                    >
                                        {yrOptionsStatus.map(yrVal =>
                                            (<Option value={yrVal}>{yrVal}</Option>))}
                                    </Select>
                                </Col>
                                <Col md={6} xs={6}>
                                    <Form.Label className="mb-0 date-label">Month:</Form.Label>
                                    <Select defaultValue="year" className="w-100" onChange={handleStatusMon}>
                                        <Option value="year">Entire Year</Option>
                                        <Option value="1">January</Option>
                                        <Option value="2">February</Option>
                                        <Option value="3">March</Option>
                                        <Option value="4">April</Option>
                                        <Option value="5">May</Option>
                                        <Option value="6">June</Option>
                                        <Option value="7">July</Option>
                                        <Option value="8">August</Option>
                                        <Option value="9">September</Option>
                                        <Option value="10">October</Option>
                                        <Option value="11">November</Option>
                                        <Option value="12">December</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row className="pt-4 text-center px-2 g-2">
                                <Col xs={12} className="bg-light rounded pt-2">
                                    <p className="total-rebates-label mb-2">Processed</p>
                                    <h2 className="sp-green fs-4 fw-bold">{totalProcessed}</h2>
                                </Col>
                                <Col lg={6} md={3} xs={6} className="bg-light rounded pt-2">
                                    <p className="total-rebates-label mb-2">Registered</p>
                                    <h2 className="sp-green fs-4 fw-bold">{totalRegistered}</h2>
                                </Col>
                                <Col lg={6} md={3} xs={6} className="bg-light rounded pt-2">
                                    <p className="total-rebates-label mb-2">Pending</p>
                                    <h2 className="sp-green fs-4 fw-bold">{totalPending}</h2>
                                </Col>
                                <Col lg={6} md={3} xs={6} className="bg-light rounded pt-2">
                                    <p className="total-rebates-label mb-2">Approved</p>
                                    <h2 className="sp-green fs-4 fw-bold">{totalApproved}</h2>
                                </Col>
                                <Col lg={6} md={3} xs={6} className="bg-light rounded pt-2">
                                    <p className="total-rebates-label mb-2">Declined</p>
                                    <h2 className="sp-green fs-4 fw-bold">{totalDeclined}</h2>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </LoadingOverlay>
    );
}
export default DashboardContent;