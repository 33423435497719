import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import { Modal, Table, Input, InputNumber, Select, Form, Checkbox, Button, Upload, Icon, DatePicker } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import './Reports.css'
import userStat from './FindStat';
//import moment from 'moment';
const promoCodesToBeRequired = ['marchmania23','springsolar23']
const { Option } = Select;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    constructor(props) {
      super(props);
      const { visible, onCancel, onCreate, formString, trackingNo, form } = props;
      const formValues = JSON.parse(formString);
      this.state = { rebateStatus: '', promocode: '', referralEmailSend: false, amount: formValues['amount'], fileList: [],
      uploading: false, systemId: formValues['id'], ustat: userStat(), moduleRequired: promoCodesToBeRequired.includes(formValues['promocode'].trim())};
      this.handleContractUpload = this.handleContractUpload.bind(this);
      //console.log('construct amount: ',this.state.amount);
    }
    handleChange = (value) => {
      //console.log(`selected ${value}`);
      this.setState({ rebateStatus: value })
    }

    handlePromoChange = (e) => {
      // console.log(e.target.value.toString().toLowerCase(), promoCodesToBeRequired.includes(e.target.value.toString().toLowerCase()));
      this.setState({ promocode: e.target.value.toLowerCase() })
      this.setState({ moduleRequired: promoCodesToBeRequired.includes(e.target.value.toString().toLowerCase().trim()) })
    }

    handleEmailChange = (value) => {
      this.setState({ referralEmailSend: true });
    }

    handleAmountChange = (value) => {
      this.setState({ amount: value });
    }

    handleUpload = () => {
      const { fileList } = this.state;
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('files[]', file);
      });
  
      this.setState({
        uploading: true,
      });
      this.props.form.setFieldsValue({
        uploadFileList: formData,
      });
      this.setState({
        uploading: false,
      });
    };

   formatDate = () => {
      let d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      const hr = d.getHours();
      const min = d.getMinutes();
      const sec = d.getSeconds();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day, hr, min, sec].join('');
  }

    handleContractUpload = (response) => {
      //console.log(response);
      //const formData = new FormData();
      //console.log(formData);
      /*let fileList = [];
      response.fileList.forEach(file => {
        console.log(file);
        //formData.append('files[]', file);
        fileList.push(file);
      });
      this.props.form.setFieldsValue({
        uploadFileList: fileList,
      });
      console.log(fileList);*/
      console.log(response);
      const userVar = JSON.parse(localStorage.getItem('user'));
      const formData = new FormData();
          formData.append('systemId', this.state.systemId);
          let cleanedFileName =  response.file.name.replace(/[^a-zA-Z0-9\.]/g, '');
          let finalFileName = this.formatDate()+'_'+cleanedFileName;
          formData.append('finalFileName', finalFileName);
          formData.append('contract', response.file);
          axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/contractUpload`, formData, {
            headers: {
              "Authorization": `Bearer ${userVar.token2}`,
              'Content-Type': "multipart/form-data; charset=utf-8",
              'X-Requested-With': 'XMLHttpRequest'
              //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
            }
          })
          .then(result => {
            console.log('response: ', result);
            if (result) {
              response.onSuccess(result.status, response.file);
            }
          })
          .catch(error => {
            alert(JSON.stringify(error));
          })
      /*response.fileList.forEach(file => {
        console.log(file);
        
      });*/
          
    }
    
    dateInBetween = (d1, d2, d3) => {
      console.log(d1);
      console.log(d2);
      console.log(d3);
      let date1 = new Date(d1).getTime();
      let date2 = new Date(d2).getTime();
      let date3 = new Date(d3).getTime();
      console.log(date1);
      console.log(date2);
      console.log(date3);
      if (date1 < date2 && date2 < date3) {
         return true;
      } 
      return false;
    };

    render() {
      const { visible, onCancel, onCreate, formString, trackingNo, form } = this.props;

      const { getFieldDecorator, getFieldValue } = form;
      //console.log(formString);
      const formValues = JSON.parse(formString);
      const { uploading, fileList } = this.state;
      const uploadProps = {
        multiple: true,
        fileList,
        onRemove: file => {
          this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
              fileList: newFileList,
            };
          });
        },
        beforeUpload: file => {
          this.setState(state => ({
            fileList: [...state.fileList, file],
          }));
          return false;
        },
        /*customRequest({
          action,
          data,
          file,
          filename,
          headers,
          onError,
          onProgress,
          onSuccess,
          withCredentials
        }) {
          console.log('upload');
          const formData = new FormData();
          formData.append('systemId', formValues['id']);
          let cleanedFileName =  file.name.replace(/[^a-zA-Z0-9\.]/g, '');
          let finalFileName = formatDate()+'_'+cleanedFileName;
          formData.append('finalFileName', finalFileName);
          formData.append('contract', file);
          axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/contractUpload`, formData, {
            headers: {
              "Authorization": `Bearer ${userVar.token2}`,
              'content-type': 'multipart/form-data',
            }
          })
          .then(response => {
            console.log('response: ', response);
            if (response) {
              
  
            }
          })
          .catch(error => {
            alert(JSON.stringify(error));
          })
        }*/
        /*
          <Form.Item label="Referral First Name" name="refFname" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 ? 'none' : 'block') }}>
              {getFieldDecorator('refFname', {
                initialValue: formValues['refFname'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && !this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Last Name" name="refLname" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 ? 'none' : 'block') }}>
              {getFieldDecorator('refLname', {
                initialValue: formValues['refLname'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && !this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Email" name="refEmail" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 ? 'none' : 'block') }}>
              {getFieldDecorator('refEmail', {
                initialValue: formValues['refEmail'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && !this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Phone" name="refPhone" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 ? 'none' : 'block') }}>
              {getFieldDecorator('refPhone', {
                initialValue: formValues['refPhone'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && !this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Address1" name="refAddress1" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 ? 'none' : 'block') }}>
              {getFieldDecorator('refAddress1', {
                initialValue: formValues['refAddress1'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && !this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Address2" name="refAddress2" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 ? 'none' : 'block') }}>
              {getFieldDecorator('refAddress2', {
                initialValue: formValues['refAddress2'],

              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral City" name="refCity" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 ? 'none' : 'block') }}>
              {getFieldDecorator('refCity', {
                initialValue: formValues['refCity'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && !this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral State" name="refState" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 ? 'none' : 'block') }}>
              {getFieldDecorator('refState', {
                initialValue: formValues['refState'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && !this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Zip" name="refZip" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 ? 'none' : 'block') }}>
              {getFieldDecorator('refZip', {
                initialValue: formValues['refZip'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && !this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
        */
      };
      //console.log(formValues);
      //console.log(this.state);
      const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
      const formTailLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14, offset: 4 },
      };
      const dateFormat = 'YYYY-MM-DD';
      const referralPromo = ['friends & family lease', 'friends & family purchase', 'spwr friends & family', 'sunpower friends & family'];
      return (
        <Modal
          visible={visible}
          title="Edit Rebate"
          okText="Save"
          onCancel={onCancel}
          onOk={onCreate}
          width={800}
        >
          <Form layout="vertical">
            <Form.Item label="First Name" name="fname" {...formItemLayout} style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('fname', {
                initialValue: formValues['fname'],
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Last Name" name="lname" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('lname', {
                initialValue: formValues['lname'],
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Email" name="Email" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('email', {
                initialValue: formValues['email'],
                rules: [{
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Phone" name="phone" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('phone', {
                initialValue: formValues['phone'],
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Address 1" name="address1" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('address1', {
                initialValue: formValues['address1'],
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Address 2" name="address2" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('address2', {
                initialValue: formValues['address2']
              })(<Input />)}
            </Form.Item>
            <Form.Item label="City" name="city" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('city', {
                initialValue: formValues['city'],
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="State" name="state" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('state', {
                initialValue: formValues['state'],
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Zip" name="zip" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('zip', {
                initialValue: formValues['zip'],
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Mailing Address1" name="mailingaddress1" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('mailingaddress1', {
                initialValue: formValues['mailingaddress1'],
                rules: [{ required: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false: true ) }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Mailing Address2" name="mailingaddress2" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('mailingaddress2', {
                initialValue: formValues['mailingaddress2'],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Mailing City" name="mailingcity" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('mailingcity', {
                initialValue: formValues['mailingcity'],
                rules: [{ required: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false: true ) }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Mailing State" name="mailingstate" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('mailingstate', {
                initialValue: formValues['mailingstate'],
                rules: [{ required: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false: true ) }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Mailing Zip" name="mailingzip" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('mailingzip', {
                initialValue: formValues['mailingzip'],
                rules: [{ required: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? false: true ) }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Dealer" name="dealerName" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('dealerName', {
                initialValue: formValues['dealerName'],
                rules: [{ required: true }],
              })(<Select showSearch placeholder="Select Dealer" optionFilterProp="children" style={{ width: 250 }} >
                {Object.keys(formValues['dealerOptions']).map((key) => (
                    <Select.Option key={key} value={key}>
                      {formValues['dealerOptions'][key]}
                    </Select.Option>
                  ))}
              </Select>)}
            </Form.Item>
            <Form.Item label="Promocode" name="promocode" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('promocode', {
                initialValue: formValues['promocode'],
                rules: [{ required: true }],
              })(<Input onChange={(e) => this.handlePromoChange(e)} />)}
            </Form.Item>
            <Form.Item label="Module Name" name="moduleName" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('moduleName', {
                initialValue: formValues['moduleName'],
                rules: [{ required: this.state.moduleRequired }],
              })(<Select style={{ width: 120 }}>
                <Option value="A-Series">A-Series</Option>
                <Option value="X-Series">X-Series</Option>
                <Option value="E-Series">E-Series</Option>
                <Option value="P-Series">P-Series</Option>
                <Option value="M-Series">M-Series</Option>
              </Select>)}
            </Form.Item>
            <Form.Item label="Install Date" name="systemdate" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('systemdate', {
                initialValue: formValues['systemdate'],
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="System Size" name="watts" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('watts', {
                initialValue: formValues['watts'],
                rules: [{ required: true  }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Purchase Type" name="saletype" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('saletype', {
                initialValue: formValues['saletype'],
                rules: [{ required: ((this.state.ustat == 120091268 || this.state.ustat == 120091874) && formValues['fundingStatus'] == 'processing') ? false : true  }],
              })(<Select style={{ width: 120 }} >
                <Option value="purchased">Purchased</Option>
                <Option value="loaned">Loaned</Option>
                <Option value="leased">Leased</Option>
              </Select>)}
            </Form.Item>
            <Form.Item label="Reward Type" name="cardType" {...formItemLayout} style={{ display: (((this.state.ustat == 120091268 || this.state.ustat == 120091874) && formValues['fundingStatus'] == 'processing') ? 'none' : 'block') }}>
              {getFieldDecorator('cardType', {
                initialValue: formValues['cardType'],
                rules: [{
                  validator(rule, value, callback) {
                    //console.log('validator value: ',value);
                    //console.log('amount: ',getFieldValue('amount'));
                    if (value == "virtualvisa" && getFieldValue('amount') > 1000) {
                      callback("You cannot select Virtual card since amount > 1000");

                    } else if (value == "visareward" && getFieldValue('amount') > 2500) {
                      callback("You cannot select Physical card since amount > 2500");
                    }

                    callback();
                  }
                }],
              })(<Select style={{ width: 120 }}>
                <Option value="visareward">Physical Visa</Option>
                <Option value="virtualvisa">Virtual Visa</Option>
                <Option value="check">Check</Option>
              </Select>)}
            </Form.Item>
            <Form.Item label="Amount" name="amount" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('amount', {
                initialValue: formValues['amount'],
                rules: [{ required: true }],
              })(<Input onChange={this.handleAmountChange} />)}
            </Form.Item>
            <Form.Item label="Rebate Status" name="status" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('status', {
                initialValue: formValues['status'],
                rules: [{ required: true }],
              })(<Select style={{ width: 220 }} onChange={this.handleChange}>
                <Option value="Approved">Approved</Option>
                <Option value="Declined">Declined</Option>
                <Option value="In Process">Pending</Option>
                <Option value="Pending Document Upload">Pending Document Upload</Option>
                <Option value="Pending SPWR Approval">Pending SPWR Approval</Option>
              </Select>)}
            </Form.Item>
            <Form.Item label="Comment" name="comment" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('comment', {
                initialValue: formValues['comment'],
                rules: [{ required: this.state.rebateStatus == 'Declined' }],
              })(<Select style={{ width: 500 }}>
                <Option value="">Please select a comment to decline</Option>
                <Option value="System has not been installed">System has not been installed</Option>
                <Option value="Address doesn't match customer records">Address doesn't match customer records</Option>
                <Option value="Rebate was not agreed upon before contract signing">Rebate was not agreed upon before contract signing</Option>
                <Option value="Please contact dealer for more information">Please contact dealer for more information</Option>
                <Option value="Wrong PromoCode was selected, your rebate will be resubmitted with correct promocode">Wrong PromoCode was selected, your rebate will be resubmitted with correct promocode</Option>
                <Option value="Duplicate Rebate Submission">Duplicate Rebate Submission</Option>
                <Option value="Incorrect Dealer Name">Incorrect Dealer Name</Option>
                <Option value="Offer not available in customer's State">Offer not available in customer's State</Option>
                <Option value="Did not meet Loan Term requirement">Did not meet Loan Term requirement</Option>
                <Option value="Did not meet APR requirement">Did not meet APR requirement</Option>
              </Select>)}
            </Form.Item>
            <Form.Item label="Contract Date" name="contractDate" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('contractDate', {
                initialValue: formValues['contractDate'],
                rules: [/*{ required: true,
                  type: "regexp",
                  pattern: new RegExp(/^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$/g),
                  message: "Contract date should be in mm/dd/YYYY format!" },*/ {
                  validator(rule, value, callback) {
                    //console.log('validator value: ',value);
                    //let pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
                    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
                    //console.log('amount: ',getFieldValue('amount'));
                    
                    let date1 = new Date(formValues['promoStartDate']).getTime();
                    let date2 = new Date(value).getTime();
                    let date3 = new Date(formValues['promoEndDate']).getTime();
                    
                    /*if ((this.state.ustat == 120091268 || this.state.ustat == 120091874) && formValues['fundingStatus'] == 'processing') {
                      callback();
                    } else {*/
                      if (!value) {
                        callback("The contract date is required.");
                      } else if (!dateRegex.test(value)) {
                        callback("Contract date should be in YYYY-mm-dd format!");
                      } else if (date1 > date2 || date2 > date3) {
                        callback("The contract date is not in between the promotion start and end date.");
                      } 
                    //}
                    
                    
                    /*if (!this.dateInBetween(formValues['startDate'], value, formValues['endDate'])) {
                      callback("The contract date is not in between the promotion start and end date.");
                    }*/
                    callback();
                  }
                }
                ], 
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Funding Status" name="fundingStatus" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('fundingStatus', {
                initialValue: formValues['fundingStatus'],
                rules: [{ required: true }],
              })(<Select style={{ width: 120 }}>
                <Option value="pending">Pending</Option>
                <Option value="On Hold">Hold</Option>
                <Option value="processing">Processing</Option>
              </Select>)}
            </Form.Item>
            <Form.Item label="Referral Reward Type" name="referralCard" {...formItemLayout} style={{ display: (referralPromo.includes(formValues.promocode.toLowerCase()) ? 'block' : 'none') }}>
              {getFieldDecorator('referralCard', {
                initialValue: formValues['referralCard'] && formValues['referralCard']['cardType'] ? formValues['referralCard']['cardType'] : '',
                rules: [ {
                  validator(rule, value, callback) {
                      if (!formValues['employeeIdPresent'] && value) {
                        callback("Employee ID field needs to be filled out for this reward type. The email will need to be resent to the referrer to fill out this field and select their reward type.");
                      /*} else if (!value && this.state.referralEmailSend) { 
                        callback();*/
                      } /*else if (!value && formValues['employeeIdPresent'] && referralPromo.includes(formValues.promocode.toLowerCase())) {
                        callback("The Referral Reward Type is required.");
                      } */
                   
                    
                    callback();
                  }
                }
                ], 
              })(<Select style={{ width: 120 }}>
                <Option value="">Referral Reward Type</Option>
                <Option value="visareward">Physical Visa</Option>
                <Option value="virtualvisa">Virtual Visa</Option>
                <Option value="check">Check</Option>
              </Select>)}
            </Form.Item>
            <Form.Item label="Referral First Name" name="refFname" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('refFname', {
                initialValue: formValues['refFname'],
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Last Name" name="refLname" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('refLname', {
                initialValue: formValues['refLname'],
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Email" name="refEmail" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('refEmail', {
                initialValue: formValues['refEmail'],
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Phone" name="refPhone" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('refPhone', {
                initialValue: formValues['refPhone'],
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Address1" name="refAddress1" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('refAddress1', {
                initialValue: formValues['refAddress1'],
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Address2" name="refAddress2" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('refAddress2', {
                initialValue: formValues['refAddress2'],

              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral City" name="refCity" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('refCity', {
                initialValue: formValues['refCity'],
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral State" name="refState" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('refState', {
                initialValue: formValues['refState'],
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Referral Zip" name="refZip" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('refZip', {
                initialValue: formValues['refZip'],
                rules: [{ required: false }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Employee First Name" name="empfname" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('empfname', {
                initialValue: formValues['empfname'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Employee Last Name" name="emplname" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('emplname', {
                initialValue: formValues['emplname'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Employee Email" name="empemail" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('empemail', {
                initialValue: formValues['empemail'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && this.state.referralEmailSend, type: 'email' }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Employee Phone" name="empphone" {...formItemLayout}  style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              {getFieldDecorator('empphone', {
                initialValue: formValues['empphone'],
                rules: [{ required: referralPromo.includes(formValues.promocode.toLowerCase()) && this.state.referralEmailSend }],
              })(<Input />)}
            </Form.Item>
            <Form.Item name="trackingNo" style={{ display: 'none' }}>
              {getFieldDecorator('trackingNo', {
                initialValue: trackingNo,
              })(<Input />)}
            </Form.Item>
            <Form.Item name="systemId" style={{ display: 'none' }}>
              {getFieldDecorator('systemId', {
                initialValue: formValues['id'],
              })(<Input />)}
            </Form.Item>
            <Form.Item name="dealer" style={{ display: 'none' }}>
              {getFieldDecorator('dealer', {
                initialValue: formValues['dealer'],
              })(<Input />)}
            </Form.Item>
            <Form.Item name="emailsunempl" {...formTailLayout} style={{ display: (referralPromo.includes(formValues.promocode.toLowerCase()) ? 'block' : 'none') }}>
              {getFieldDecorator('emailsunempl', {})(
                <Checkbox onChange={this.handleEmailChange}>
                  Send Referral Email to Sunpower Employee
                </Checkbox>)}

            </Form.Item>
            
            <div style={{ display: (this.state.ustat == 120091268 || this.state.ustat == 120091874 ? 'none' : 'block') }}>
              <Upload multiple={true} customRequest={this.handleContractUpload}>
                <Button>
                  <Icon type="upload" /> Select Contract
                </Button>
              </Upload>
            </div>
          </Form>
        </Modal>
      );
    }
  },
);

const data = [];
const { Search } = Input;

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data, editingKey: '', visible: false, searchTerm: '' };
    this.columns = [
      {
        title: 'Dealer Name',
        dataIndex: 'dealer',
        width: 200,
        editable: false,
        key: 'dealer',
      },
      {
        title: 'Tracking No',
        dataIndex: 'trackingNo',
        width: 200,
        editable: false,
        key: 'trackingNo'
      },
      {
        title: 'First Name',
        dataIndex: 'fname',
        width: 190,
        editable: true,
        key: 'fname'

      },
      {
        title: 'Last Name',
        dataIndex: 'lname',
        width: 190,
        editable: true,
        key: 'lname'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        editable: true,
        width: 300
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <CollectionCreateForm
              wrappedComponentRef={this.saveFormRef}
              visible={this.state.visible}
              onCancel={this.handleCancel}
              onCreate={this.handleCreate}
              formString={record.formString}
              trackingNo={record.key}
            />
          ) : (
            <a className='blue-link' disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
              Edit
            </a>
          );
        },
      },
    ];
    this.searchEntity = this.searchEntity.bind(this);
  }
  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ editingKey: '', visible: false });
  };

  handleCreate = () => {
    
    const { form } = this.formRef.props;
    console.log(form)
    form.validateFields(async (err, values) => {
      console.log('in validate');
      if (err) {
        console.log(err);
        return;
      }

      console.log('Received values of form: ', values);
      const key = values['trackingNo'];
      values['emailsunempl'] = (values['emailsunempl']) ? values['emailsunempl'] : false;
      this.setState({ spinnerDisplay: true, spinnerText: 'Saving your rebate...' });
      const userVar = JSON.parse(localStorage.getItem('user'));
      /*let formData = new FormData();
      
      for (const key in values) {
        console.log(key+':'+values[key]);
        formData.append(key, values[key]);
      }
      console.log(formData);*/
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/rebateedit`, {values}, {
        headers: {
          "Authorization": `Bearer ${userVar.token2}`
        }
      })
        .then(response => {
          console.log('response: ', response);
          if (response) {
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
              const item = newData[index];
              newData.splice(index, 1, {
                ...item,
                ...values,
              });
              this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
            } else {
              newData.push(values);
              this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
            }
            this.handleSearch(this.state.searchTerm);
            //this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: ''  });

          }
        })
        .catch(error => {
          alert(JSON.stringify(error));
        })
        /*try {
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_URL}/api/rebateedit`,
            data: formData,
            headers: { "Authorization": `Bearer ${userVar.token2}`,'content-type': 'multipart/form-data', },
          });
          if (response) {
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
              const item = newData[index];
              newData.splice(index, 1, {
                ...item,
                ...values,
              });
              this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
            } else {
              newData.push(values);
              this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
            }
            //this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: ''  });

          }
        } catch(error) {
          console.log(error)
        }*/
      /*const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
          ...item,
          ...row,
          });
          this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '', visible: false });
      } else {
          newData.push(row);
          this.setState({ data: newData, editingKey: '', spinnerDisplay: false, spinnerText: '', visible: false });
      }*/

      this.setState({ editingKey: '', visible: false });
      //form.resetFields();
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };
  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: '' });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: '' });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key, visible: true });
  }

  handleSearch(searchTerm) {
    const userVar = JSON.parse(localStorage.getItem('user'));
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rebatesearch/${searchTerm}`, {
          headers: {
            "Authorization": `Bearer ${userVar.token2}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
          }
        })
        .then(response => {
          //console.log(response);
          const tableData = [];
          response.data.map(entry => {
            //console.log(entry);
            let searchEntry = {};
            if (entry.promocode) {
              for (let key in entry) {
                searchEntry[key] = entry[key];
              }
              searchEntry['key'] = entry.trackingNo;
              const jsonString = JSON.stringify(searchEntry);
              //console.log(jsonString);
              const rowEntry = {
                fname: entry.fname,
                lname: entry.lname,
                trackingNo: entry.trackingNo,
                dealer: entry.dealer,
                email: entry.email,
                key: entry.trackingNo,
                formString: jsonString
              }
              tableData.push(rowEntry);
            }
            //console.log(entry);

            /*const searchEntry = {
                fname: entry.fname,
                lname: entry.lname,
                trackingNo: entry.trackingNo,
                dealer: entry.dealer,
                email: entry.email,
                key: entry.trackingNo,
                formString: entry.formString
            };*/


          })
          console.log(tableData);
          this.setState({ data: tableData, editingKey: '', spinnerDisplay: false, spinnerText: '' });
        }).catch(error => {
          if (error.response) {
            if (error.response.data.error == "token_expired") {
              localStorage.clear();
              window.location.href = '/login';
            }
            /*console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);*/
          }
        });
  }

  searchEntity(e) {
    //const { value } = e.target;
    console.log(e);
    e.preventDefault();
    console.log(this.props);
    this.props.form.validateFields((err, values) => {
      console.log(err);
      
      if (!err) {
        //console.log('Received values of form: ', values);
        console.log(values);
        this.setState({ spinnerDisplay: true, searchTerm: values['Search'], spinnerText: 'Searching for your rebate...' });
        this.handleSearch(values['Search']);
      }
    });

  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });
    const { getFieldDecorator } = this.props.form;
    return (
      <Fragment>
        <Container className="py-5">
          <Row className="pt-md-5">
            <Col className="pt-5 pb-md-4">
              <h3>Edit Rebate</h3>
            </Col>
          </Row>
          <Row className='pt-4'>
            <Col>
              <p>Search by First Name, Last Name, Email or Tracking Number</p>
              <Form onSubmit={this.searchEntity} >
                <Form.Item>
                {getFieldDecorator('Search', {
                  rules: [{ required: true }],
                })(<Input.Search autoComplete='on' onSearch={value => this.handleSearch(value)} placeholder="Search" style={{ width: 200 }} />)}
                  
                  <Button type="primary" htmlType="submit" className="btn-orange-secondary d-inline ms-1">
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <LoadingOverlay
                active={this.state.spinnerDisplay}
                spinner
                text={this.state.spinnerText}
              >

                <EditableContext.Provider value={this.props.form}>
                  <Table
                    bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    scroll={{ y: 400 }}
                    pagination={{
                      onChange: this.cancel,
                    }}
                  />
                </EditableContext.Provider>
              </LoadingOverlay>
            </Col>
          </Row>
        </Container>

      </Fragment>
    );
    /*return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered
          dataSource={this.state.data}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            onChange: this.cancel,
          }}
        />
      </EditableContext.Provider>
    );*/
  }
}

const EditableFormTable = Form.create()(EditableTable);
export default EditableFormTable;