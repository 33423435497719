import React, { Component, Fragment } from 'react';
import 'antd/dist/antd.css';
import { Table, Button, Popover, Icon, Form, Input, Select, Alert, Spin } from 'antd';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { constantRoles, rolesName } from "./Constants";

const { Option } = Select;
const _userRole = [
    {
        value: constantRoles.BPS_ADMIN,
        name: rolesName.BPS_ADMIN
    },
    {
        value: constantRoles.MANAGEMENT_ACCESS,
        name: rolesName.MANAGEMENT_ACCESS,
    },
    {
        value: constantRoles.ACCOUNTING,
        name: rolesName.ACCOUNTING
    },
]

class AddUsersContent extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {
          name: '',
          email: '',
          userRole: '',
          alertMessage: '',
          showAlert: false,
          messageType: '',
          loading: false
        };
        this.resetState = this.resetState.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        /*this.roleChange = this.roleChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.nameChange = this.nameChange.bind(this);*/
        
      }

      resetState = () => {
        window.scrollTo({top:0,left:0,behaviour:"smooth"});
         setTimeout(() => {
          this.setState({showAlert: false});
         }, 3000)
    }

    submitHandler =  (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
        if (!err) {
            //console.log('Received values of form: ', values);
            this.setState({loading: true});
            const userVar = JSON.parse(localStorage.getItem('user'));
            const formData = new FormData();
            formData.append('name', values['name']);
            formData.append('userRole', values['userRole']);
            formData.append('email', values['email']);
            try {
                axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/usermgmt`, formData, {
                    headers: {
                    "Authorization": `Bearer ${userVar.token2}`
                    }
                })
                    .then(response => {
                    //console.log(response.data.status);
                    if (response.data.status) {
                        this.props.form.resetFields();
                        this.setState({loading: false});
                        this.setState({showAlert: true, messageType: 'success', alertMessage: 'The user was successfully created.'});
                        this.resetState()
                    } else {
                        this.setState({loading: false});
                        this.setState({showAlert: true, messageType: 'error', alertMessage: 'This email already exists in the system and the user was not created.'});
                        this.resetState()
                    }   
                    })
                    .catch(error => {
                    //alert(JSON.stringify(error));
                    })
                
            } catch (err) {
                this.setState({showAlert: true, messageType: 'error', alertMessage: 'There was a problem creating the user.'});
                this.resetState()

            }
        }
        });
        
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return(
            <Fragment>
                <Container className='my-5 py-4'>
                    <Row>
                        <Col>
                            <div className="alertmessage w-50" style={{ display: (this.state.showAlert ? 'block' : 'none') }}>                       
                                <Alert type={this.state.messageType} message={this.state.alertMessage}></Alert>
                            </div> 
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Add User</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={this.submitHandler} className="user-form">
                                <Row className="mb-2 pb-2">
                                    
                                    <Col>
                                        <Form.Item
                                            label="User Role"
                                        >
                                            {getFieldDecorator('userRole', {
                                                rules: [{
                                                required: true, message: 'Please select User Role',
                                                }],
                                            })(
                                                <Select>
                                                {
                                                    _userRole.map((role) => (
                                                        <Option value={role.value}>{role.name}</Option>
                                                    ))
                                                }
                                                </Select>
                                            )}
                                            
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item label="Email">
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        required: true, message: 'Email is required'
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: 'Please enter valid email address',
                                                    },
                                                    /*{
                                                        validator(rule, value, callback) {
                                                            const formData = new FormData();
                                                            formData.append('email', value);
                                                            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/validateAdminEmail`, formData, {
                                                                headers: {
                                                                    "Authorization": `Bearer ${userVar.token2}`
                                                                }
                                                                })
                                                                .then(response => {
                                                                    if (response) {
                                                                        callback();
                                                                    } else {
                                                                        callback('This email has already been used.');
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    //alert(JSON.stringify(error));
                                                                })
                                                                
                                                          }
                                                    }*/
                                                    /*{
                                                        message: 'This email has already been used.',
                                                        validator: (_, value) => {
                                                            try {
                                                                const formData = new FormData();
                                                                formData.append('email', value);
                                                                axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/validateAdminEmail`, formData, {
                                                                    headers: {
                                                                      "Authorization": `Bearer ${userVar.token2}`
                                                                    }
                                                                  })
                                                                    .then(response => {
                                                                        if (response) {
                                                                            return Promise.resolve();
                                                                        } else {
                                                                            this.setState({showAlert: true, messageType: 'error', alertMessage: 'This email has already been used.'});
                                                                            this.resetState()
                                                                            return Promise.reject('This email has already been used.');
                                                                        }
                                                                    })
                                                                    .catch(error => {
                                                                      //alert(JSON.stringify(error));
                                                                    })
                                                                
                                                            } catch (err) {
                                                                this.setState({showAlert: true, messageType: 'error', alertMessage: 'There was a problem creating the user.'});
                                                                this.resetState()
                                                    
                                                            }
                                                          
                                                         }
                                                    }*/
                                                ]
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="mb-3 border-bottom pb-2">
                                    <Col>
                                        <Form.Item
                                            label="Name"
                                        >
                                            {getFieldDecorator('name', {
                                                rules: [{ required: true, message: 'Please input Name!' }],
                                            })(
                                                <Input />
                                            )}
                                            
                                        </Form.Item>
                                    </Col>
                                </Row>
                                
                                <Row className="justify-content-center text-center pt-4">
                                    <Col lg={12}>
                                        <Spin size="large" spinning={this.state.loading} />
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="btn-orange-secondary d-inline ms-1">Submit</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
        </Fragment>
        );
    }
        
}
export default Form.create({ name: 'add_user' })(AddUsersContent);