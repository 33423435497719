import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Container, Col, Row, Form, Card, Button } from 'react-bootstrap';
import { userActions } from '../_actions';
import './LoginPage.css';
import { Footer } from '../footer/Footer';
import queryString from 'query-string'

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loginError: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        if (values.error !== undefined && values.error == 1) {
            console.log('alert');
            this.setState({ loginError: true });
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true, loginError: false });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { username, password, submitted } = this.state;
        return (
            <Fragment>
                <Container fluid className="bg-light py-4">
                    <Row className="vh-fill align-items-center justify-content-center">
                        <Col lg={4} md={6}>
                            <Card className="rounded shadow-lg border-0 p-4">
                                {this.state.loginError ? <div className="alert alert-danger">Username  or Password is invalid</div> : null}
                                <Card.Header className="bg-white border-0">
                                    <img src="images/SunPower.png" width={200} className="mx-auto d-block pb-2" />
                                    <p className='mod-title'>BPS Admin</p>
                                    <h1 className="text-center font-weight-bold">LOG IN</h1>
                                </Card.Header>
                                <Card.Body>
                                    <Form name="form" onSubmit={this.handleSubmit}>
                                        <Form.Group className={'py-2' + (submitted && !username ? ' has-error' : '')}>
                                            <Form.Label htmlFor="email">Username:</Form.Label>
                                            <Form.Control className="bg-white" input type="text" name="username" value={username} onChange={this.handleChange} />
                                            {submitted && !username &&
                                                <div className="help-block">Email is required</div>
                                            }
                                        </Form.Group>
                                        <Form.Group className={'py-2' + (submitted && !password ? ' has-error' : '')}>
                                            <Form.Label htmlFor="pwd">Password:</Form.Label>
                                            <Form.Control input type="password" name="password" value={password} onChange={this.handleChange} />
                                            {submitted && !password &&
                                                <div className="help-block">Password is required</div>
                                            }
                                        </Form.Group>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group controlId="remember">
                                                    <Form.Check type="checkbox" label="Remember me" name="remember" />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="text-end">
                                                <a href="/forgot" className="text-dark">Forgot Password</a>
                                            </Col>
                                        </Row>
                                        <div className="text-center pt-5">
                                            <Button type='submit' className="btn-sunpower-orange">Log In</Button>
                                            {loggingIn &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Container>
                <Footer />
            </Fragment>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };