import React, {Fragment} from 'react';
import Topper from './Topper';
import AddUsersContent from './AddUsersContent';
import { Footer } from '../../footer/Footer';


const AddUsers = () => {
    return (
        <Fragment>
            <Topper />
            <AddUsersContent />
            <Footer />
        </Fragment>
    );
}
export default AddUsers;