import { authHeader } from '../_helpers';
import axios from 'axios';

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/bpsadmin/logindealer.php`, requestOptions)
        .then((resp) => resp.json()) // Transform the data into json
        .then(function(data) {
            //console.log(data);
            if (data.status === false) {
                window.location.href = '/login?error=1';
            }
            let responseJson = {
                username: username,
                token: data.token,
                token2: data.token2,
                userRole: data.userRole,
                details: data.details,
                pwdReset: data.pwdReset,
                tempPassword: data.tempPassword,
                atype: data.atype,
                referralto: JSON.parse(data.referralto)['encodedMsg']
            };
            const userData = JSON.stringify(responseJson)
            localStorage.setItem('user', userData);
            return userData;
        })
        .catch(function(error) {
            return error;
        });
}

/*function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}*/
/*function login(username, password) {
    const postData = {username: username, password: password};
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/logindealer.php`, { postData })
        .then(res => {
            console.log(res);
            if (res.status == 200) {
                let responseJson = {
                    username: username,
                    token: res
                };
                const userData = JSON.stringify(responseJson)
                localStorage.setItem('user', userData);
                return userData;
            } else {
                return false;
            }

        });
}*/

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}