import React, {Fragment} from 'react';
import Topper from './Topper';
import ListUsersContent from './ListUsersContent';
import { Footer } from '../../footer/Footer';


const ListUsers = () => {
    return (
        <Fragment>
            <Topper />
            <ListUsersContent />
            <Footer />
        </Fragment>
    );
}
export default ListUsers;