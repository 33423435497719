import React, { Fragment } from 'react';
import 'antd/dist/antd.css';
import { Table, Form, Input, Button, Icon, Select, Radio, Popover, AutoComplete, Tooltip, Tag } from 'antd';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import './RebateTableNew.css';
import LoadingOverlay from 'react-loading-overlay';
import AIModal from './AIModal';
import userStat from './FindStat';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}


const { Option } = Select;
const { Option2 } = AutoComplete;
const { TextArea } = Input;
const gridStyle = {
    width: '25%',
    textAlign: 'center',
};

class RebateTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            pagination: {},
            pageSize: 15,
            searchString: '',
            declineReason: [
                { name: 'System has not been installed', label: 'System has not been installed' },
                { name: 'Address doesn\'t match customer records', label: 'Address doesn\'t match customer records' },
                { name: 'Rebate was not agreed upon before contract signing', label: 'Rebate was not agreed upon before contract signing' },
                { name: 'Please contact dealer for more information', label: 'Please contact dealer for more information' },
                { name: 'Wrong PromoCode was selected, your rebate will be resubmitted with correct promocode', label: 'Wrong PromoCode was selected, your rebate will be resubmitted with correct promocode' },
                { name: 'Incorrect Dealer Name', label: 'Incorrect Dealer Name' },
                { name: 'Duplicate rebate submission', label: 'Duplicate rebate submission' },
                { name: 'Offer not available in customer\'s State', label: 'Offer not available in customer\'s State' },
                { name: 'Did not meet Loan Term requirement', label: 'Did not meet Loan Term requirement' },
                { name: 'Did not meet APR requirement', label: 'Did not meet APR requirement' },
            ],
            selectedRowKeys: [],
            declineReasonSelected: [],
            errorKey: {},
            showButtons: false,
            showComment: false,
            justCheckBox: false,
            rebateState: 'all',
            xAxis: 3800,
            autoOptions: [],
            selectData: [],
            value: undefined,
            inputType: 'fname',
            showReset: false,
            autoCompleteValue: [],
            defaultValue: 'all',
            modalVisible: false,
            modalSystemId: [],
            ustat: userStat(),
            columns: [
                /*{
                    title: 'Rebate Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: (this.state.rebateState == 'all')? 100 : 1,
                    className: (this.state.rebateState == 'all')? 'show' : 'hide',
                },*/
                /*{
                    title: 'Rebate Code',
                    dataIndex: 'promocode',
                    key: 'promocode',
                    width: 150,
                },
                {
                    title: 'Rebate Date',
                    dataIndex: 'rebatedate',
                    key: 'rebatedate',
                    width: 150,
                },
                {
                    title: 'Funding Status',
                    dataIndex: 'fundingStatus',
                    key: 'fundingStatus',
                    width: 150,
                },
                {
                    title: 'Rebate Status Tracking No',
                    dataIndex: 'trackingNo',
                    key: 'trackingNo',
                    width: 150,
                },
                {
                    title: 'Ship Date',
                    dataIndex: 'shipDate',
                    key: 'shipDate',
                    width: 150,
                },
                {
                    title: 'First Name',
                    dataIndex: 'fname',
                    key: 'fname',
                    width: 100,
                },
                {
                    title: 'Last Name',
                    dataIndex: 'lname',
                    key: 'lname',
                    width: 100,
                    className: 'show'
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    width: 150,
                },
                {
                    title: 'Address',
                    dataIndex: 'address1',
                    key: 'address1',
                    width: 150,
                },
                {
                    title: 'Address2',
                    dataIndex: 'address2',
                    key: 'address2',
                    width: 50,
                },
                {
                    title: 'City',
                    dataIndex: 'city',
                    key: 'city',
                    width: 100,
                },
                {
                    title: 'State',
                    dataIndex: 'state',
                    key: 'state',
                    width: 100,
                },
                {
                    title: 'Zip',
                    dataIndex: 'zip',
                    key: 'zip',
                    width: 50,
                },
                {
                    title: 'Uploaded Documents',
                    dataIndex: 'fileNames',
                    key: 'fileNames',
                    width: 150,
                    render: (select, record) => {
                        return (
                            <div>
                                {record.fileNames.map(document => (
                                    <p><a href={'https://sunpowerdocs.s3-us-west-2.amazonaws.com/'+document} target='_blank'>{document}</a></p>
                                ))};
                            </div>
                        );
                    }
                },*/
            ]

        };
        this.state.columns = this.getColumns();
        this.state.columns.unshift(
            {
                title: 'Approval Date/Decline Date',
                dataIndex: 'processDate',
                key: 'processDate',
                width: 220
            }
        );
        this.state.columns.unshift(
            {
                title: 'Rebate Status',
                dataIndex: 'status',
                key: 'status',
                width: 130,
            }
        );
        this.state.columns.unshift(
            {
                title: 'AI Status',
                dataIndex: 'aiStatus',
                key: 'aiStatus',
                width: 180,
                render: (select, record) => {
                    //console.log(record);
                    return (
                        <div>
                            {record['aiStatus'] ? <>
                                <Tag color={record['aiStatus'] == 'AI_PENDING' ? "red" : "green"} >{record['aiStatus']}</Tag>
                                <Button type='link' className='p-0' onClick={() => this.showAIModal([record.key])}><Icon type="info-circle" /></Button>
                                </> : null}
                        </div>

                    );
                },
            },
        );
        this.onAction = this.onAction.bind(this);
        this.reasonOnChange = this.reasonOnChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.approveRebates = this.approveRebates.bind(this);
        this.declineRebates = this.declineRebates.bind(this);
        this.statusChange = this.statusChange.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleonSelect = this.handleonSelect.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.declineReservationRebates = this.declineReservationRebates.bind(this);
        this.showAIModal=this.showAIModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }


    getColumns() {
        return [
            {
                title: 'Rebate Date',
                dataIndex: 'rebatedate',
                key: 'rebatedate',
                width: (this.state.rebateState == 'Approved' || this.state.rebateState == 'Declined') ? 200 : 150,
            },
            {
                title: 'Rebate Code',
                dataIndex: 'promocode',
                key: 'promocode',
                width: (this.state.rebateState == 'Approved' || this.state.rebateState == 'Declined') ? 150 : 250,
                render: (select, record) => {
                    return (
                        <div>
                            <Popover
                                style={{ width: 900 }}
                                placement="bottomRight"
                                title=''
                                content={record['popUpInfo']}
                                arrowPointAtCenter
                            >
                                <Icon type="info-circle" />
                            </Popover>
                            <span> {record['promocode']}</span>
                        </div>
                    );
                }
            },
            {
                title: 'Rebate Status Tracking No',
                dataIndex: 'trackingNo',
                key: 'trackingNo',
                width: (this.state.rebateState != 'Approved') ? 230 : (this.state.rebateState != 'Pending Document Upload') ? 240 : 225,
            },
            {
                title: 'Reward Type',
                dataIndex: 'reward',
                key: 'reward',
                width: 150,
            },
            {
                title: 'Reward Amount',
                dataIndex: 'amount',
                key: 'amount',
                width: 150,
            },
            {
                title: 'Rebate No',
                dataIndex: 'rebateNo',
                key: 'rebateNo',
                width: 120,
            },
            {
                title: 'Ship Date',
                dataIndex: 'shipDate',
                key: 'shipDate',
                width: 150,
            },
            {
                title: 'Funding Status',
                dataIndex: 'fundingStatus',
                key: 'fundingStatus',
                width: 150,
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                width: 150,
            },
            /*{
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                width: (this.state.rebateState == 'Approved') ? 200 : 150,
            },*/
            {
                title: 'Address',
                dataIndex: 'address1',
                key: 'address1',
                width: (this.state.rebateState == 'Approved') ? 200 : 170,
            },
            {
                title: 'Address2',
                dataIndex: 'address2',
                key: 'address2',
                width: 120,
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                width: 150,
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                width: (this.state.rebateState == 'Approved') ? 120 : 100,
            },
            {
                title: 'Zip',
                dataIndex: 'zip',
                key: 'zip',
                width: (this.state.rebateState == 'Approved') ? 100 : 80,
            },
            {
                title: 'Mailing Address',
                dataIndex: 'mailingaddress1',
                key: 'mailingaddress1',
                width: 200,
            },
            {
                title: 'Mailing Address2',
                dataIndex: 'mailingaddress2',
                key: 'mailingaddress2',
                width: 170,
            },
            {
                title: 'Mailing City',
                dataIndex: 'mailingcity',
                key: 'mailingcity',
                width: 160,
            },
            {
                title: 'Mailing State',
                dataIndex: 'mailingstate',
                key: 'mailingstate',
                width: 170,
            },
            {
                title: 'Mailing Zip',
                dataIndex: 'mailingzip',
                key: 'mailingzip',
                width: 120,
            },
            {
                title: 'Uploaded Documents',
                dataIndex: 'fileNames',
                key: 'fileNames',
                width: (this.state.rebateState == 'Approved') ? 600 : 200,
                render: (select, record) => {
                    return (
                        <div>
                            {record.fileNames.map(document => (
                                <p><a href={process.env.REACT_APP_S3_BUCKET + '/' + document} target='_blank'>{document}</a></p>
                            ))}
                        </div>
                    );
                }
            },
            {
                title: 'First Name',
                dataIndex: 'fname',
                key: 'fname',
                fixed: 'right',
                width: 140,
                render: (select, record) => {
                    return (
                        <div>
                            <Popover
                                style={{ width: 900 }}
                                placement="bottomRight"
                                title=''
                                content={record['popUpInfo']}
                                arrowPointAtCenter
                            >
                                <Icon type="info-circle" />
                            </Popover>
                            {record['notePresent'] ?
                                <Popover
                                    placement="topLeft"
                                    title=''
                                    content={record['notes']}
                                    arrowPointAtCenter
                                >
                                    <Icon type="file-text" />
                                </Popover>
                                : null}
                            <span> {record['fname']}</span>
                        </div>
                    );
                }

            },
            {
                title: 'Last Name',
                dataIndex: 'lname',
                key: 'lname',
                fixed: 'right',
                width: 120,

            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                fixed: 'right',
                width: 250,

            }
        ];
    }

    formatDate(date) {
        let month = ''; let day = ''; let year = '';
        if (date && date.length > 0) {
            const datetimeParts = date.split(' ');
            //console.log(console.log(datetimeParts);
            const dateParts = datetimeParts[0].split('-');
            //console.log(dateParts);
            let d = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
            //console.log(d);
            //const monthVal = (d.getMonth() == 0) ? 12 : d.getMonth();
            const monthVal = dateParts[1];
            month = '' + (monthVal);
            day = '' + d.getDate();
            year = d.getFullYear();
            //console.log(year);
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
        }
        //console.log([year, month, day].join('-'));

        return [year, month, day].join('-');
    }

    getPopUpInfo(entry) {
        let table = (
            <div id="scrollable">
                <table id="customers">
                    <thead>
                        <tr>
                            <th>Referral Amount</th>
                            <th>Referral Code</th>
                            <th>Referral First Name</th>
                            <th>Referral Last Name</th>
                            <th>Referral Email</th>
                            <th>Referral Phone</th>
                            <th>Employee ID</th>
                            <th>Referral Payment Type</th>
                            <th>Date Referral Email Sent</th>
                            <th>Referral Submitted Date</th>
                            <th>Referral Auto Redeemed</th>
                            <th>Install Date</th>
                            <th>Contract Date</th>
                            <th>Module Type</th>
                            <th>System Size</th>
                            <th>Purchased/Leased</th>
                            <th>Product Type</th>
                            <th>Dealer Name</th>
                            <th>Dealer City</th>
                            <th>Dealer State</th>
                            <th>Who Certified</th>
                            <th>Certifying First Name</th>
                            <th>Certifying Last Name</th>
                            <th>MilesId</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{entry['referralAmount']}</td>
                            <td>{entry['reftrackingNo']}</td>
                            <td>{entry['refFname']}</td>
                            <td>{entry['refLname']}</td>
                            <td>{entry['refEmail']}</td>
                            <td>{entry['refPhone']}</td>
                            <td>{entry['employeeId']}</td>
                            <td>{entry['refCardType']}</td>
                            <td>{entry['refEmailSentDate']}</td>
                            <td>{entry['referralDate']}</td>
                            <td>{entry['autoRedeemed']}</td>
                            <td>{entry['installdate']}</td>
                            <td>{entry['contractDate']}</td>
                            <td>{entry['module']}</td>
                            <td>{entry['watts']}</td>
                            <td>{entry['saletype']}</td>
                            <td>{entry['systemSelection']}</td>
                            <td>{entry['dname']}</td>
                            <td>{entry['dcity']}</td>
                            <td>{entry['dstate']}</td>
                            <td>{entry['certification']}</td>
                            <td>{entry['certFname']}</td>
                            <td>{entry['certLname']}</td>
                            <td>{entry['milesId']}</td>
                        </tr>
                    </tbody>

                </table>
            </div>
        );

        return table;
    }
    /*getPopUpInfo(entry) {
        let table = (
            <Descriptions
          bordered
          title="Rebate Information"
          size="small"
        >
          <Descriptions.Item label="Referral Amount">{entry['referralAmount']}</Descriptions.Item>
          <Descriptions.Item label="Referral Code">{entry['reftrackingNo']}</Descriptions.Item>
          <Descriptions.Item label="Referral First Name">{entry['refFname']}</Descriptions.Item>
          <Descriptions.Item label="Referral Last Name">{entry['refLname']}</Descriptions.Item>
          <Descriptions.Item label="Referral Email">{entry['refEmail']}</Descriptions.Item>
          <Descriptions.Item label="Referral Payment Type">{entry['refCardType']}</Descriptions.Item>
          <Descriptions.Item label="Referral Date">{entry['referralDate']}</Descriptions.Item>
          <Descriptions.Item label="Install Date">{entry['installdate']}</Descriptions.Item>
          <Descriptions.Item label="Contract Date">{entry['contractDate']}</Descriptions.Item>
          <Descriptions.Item label="Module Type">{entry['module']}</Descriptions.Item>
          <Descriptions.Item label="System Size">{entry['watts']}</Descriptions.Item>
          <Descriptions.Item label="Purchased/Leased">{entry['saletype']}</Descriptions.Item>
          <Descriptions.Item label="Dealer Name">{entry['dname']}</Descriptions.Item>
          <Descriptions.Item label="Dealer City">{entry['dcity']}</Descriptions.Item>
          <Descriptions.Item label="Dealer State">{entry['dstate']}</Descriptions.Item>
          <Descriptions.Item label="Who Certified">{entry['certification']}</Descriptions.Item>
          <Descriptions.Item label="Certifying First Name">{entry['certFname']}</Descriptions.Item>
          <Descriptions.Item label="Certifying Last Name">{entry['certLname']}</Descriptions.Item>
          </Descriptions>
        );
        
        return table;
    }*/

    /*getPopUpInfo(entry) {
        let table = (
            <Card title="Rebate Information" style={{ width: 700 }}>
                <Card.Grid hoverable={false} style={gridStyle}>Referral Amount: {entry['referralAmount']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Referral Code: {entry['reftrackingNo']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Referral First Name: {entry['refFname']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Referral Last Name: {entry['refLname']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Referral Email: {entry['refEmail']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Referral Payment Type: {entry['refCardType']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Referral Date: {entry['referralDate']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Install Date: {entry['installdate']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Contract Date: {entry['contractDate']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Module Type: {entry['module']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>System Size: {entry['watts']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Purchased/Leased: {entry['saletype']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Dealer Name: {entry['dname']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Dealer City: {entry['dcity']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Dealer State: {entry['dstate']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Who Certified: {entry['certification']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Certifying First Name: {entry['certFname']}</Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>Certifying Last Name: {entry['certLname']}</Card.Grid>
            </Card>
        );
        
        return table;
    }*/

    getNotesToShow(notes, rows) {
        let table = (
            <table id="customers">
                <tbody>
                    <tr>
                        <td>
                            <TextArea defaultValue={notes} disabled="true" rows={rows} className="input-disabled" />
                        </td>
                    </tr>
                </tbody>
            </table>
        );

        return table;
    }

    fillUpTableData(response) {
        const pagination = {
            pageSize: this.state.pageSize,
            total: response.data.total,
            showSizeChanger: false,
        };
        const tableData = [];
        //console.log(response);
        /*response.data.data.map(entry => {
            let temp = {
                key: entry['id'],
                fname: entry['system_information']['user_registration']['fname'],
                lname: entry['system_information']['user_registration']['lname'],
                email: entry['system_information']['user_registration']['email'],
                trackingNo: entry['trackingNo'],
                status: entry['status'],
                promocode: entry['system_information']['promocode'],
                installdate: entry['system_information']['systemdate'],
                contractDate: entry['system_information']['contractDate'],
                module: entry['system_information']['moduleName'],
                processDate: this.formatDate(entry['processDate']),
                comment: entry['comment'],
                rebatedate: this.formatDate(entry['created']),
                fundingStatus: entry['fundingStatus'],
                shipDate: entry['shipDate'],
                address1: entry['system_information']['user_registration']['address1'],
                address2: entry['system_information']['user_registration']['address2'],
                city: entry['system_information']['user_registration']['city'],
                state: entry['system_information']['user_registration']['state'],
                zip: entry['system_information']['user_registration']['zip'],
                reward: entry['cardType'],
                amount: entry['amount'],
                system: entry['system_information']['watts'],
                saletype: entry['system_information']['saletype'],
                dname: entry['system_information']['dealer']['name'],
                dcity: entry['system_information']['dealer']['city'],
                dstate: entry['system_information']['dealer']['state'],
                fileNames: [],
                popUpInfo: ''
            };
            temp['popUpInfo'] = this.getPopUpInfo(temp);
            entry['system_information']['sunpower_documents'].map(docs => {
                temp['fileNames'].push(docs['fileName']);
            });
            tableData.push(temp);
        });*/
        response.data.data.map(entry => {
            //console.log(entry['emailSent']);
            let refName = ''; let refLname = ''; let refEmail = ''; let refPhone = '';
            if (entry['refFname']) {
                refName = entry['refFname'];
            } else if (entry['empfname']) {
                refName = entry['empfname'];
            }
            if (entry['refLname']) {
                refLname = entry['refLname'];
            } else if (entry['emplname']) {
                refLname = entry['emplname'];
            }
            if (entry['refEmail']) {
                refEmail = entry['refEmail'];
            } else if (entry['empemail']) {
                refEmail = entry['empemail'];
            }
            if (entry['refPhone']) {
                refPhone = entry['refPhone'];
            } else if (entry['empphone']) {
                refPhone = entry['empphone'];
            }
            let refCardType = (entry['refCardType']) ? entry['refCardType'] : '';
            if (refName.toLowerCase() == 'joanne' && refLname.toLowerCase() == 'endow') {
                refName = entry['empfname'];
                refLname = entry['emplname'];
                refCardType = 'Foundation Check';
            }
            let temp = {
                key: entry['id'],
                fname: entry['fname'],
                lname: entry['lname'],
                email: entry['email'],
                trackingNo: entry['trackingNo'],
                status: (entry['status'] == 'In Process') ? 'Pending' : entry['status'],
                promocode: entry['promocode'],
                installdate: (entry['systemdate']) ? entry['systemdate'] : '',
                contractDate: (entry['contractDate']) ? entry['contractDate'] : '',
                module: (entry['moduleName']) ? entry['moduleName'] : '',
                processDate: (entry['processDate']) ? this.formatDate(entry['processDate']) : '',
                comment: (entry['comment']) ? entry['comment'] : '',
                rebatedate: this.formatDate(entry['created']),
                fundingStatus: entry['fundingStatus'],
                shipDate: (entry['shipDate']) ? entry['shipDate'] : '',
                address1: entry['address1'],
                address2: (entry['address2']) ? entry['address2'] : '',
                city: entry['city'],
                state: entry['state'],
                zip: entry['zip'],
                phone: (entry['phone']) ? entry['phone'] : '',
                referralAmount: (entry['referralAmount']) ? entry['referralAmount'] : 0,
                reftrackingNo: (entry['reftrackingNo']) ? entry['reftrackingNo'] : '',
                mailingaddress1: entry['mailingaddress1'],
                mailingaddress2: (entry['mailingaddress2']) ? entry['mailingaddress2'] : '',
                mailingcity: entry['mailingcity'],
                mailingstate: entry['mailingstate'],
                mailingzip: entry['mailingzip'],
                reward: entry['cardType'],
                amount: entry['amount'],
                rebateNo: entry['rebateNo'],
                watts: (entry['watts']) ? entry['watts'] : '',
                saletype: (entry['saletype']) ? entry['saletype'] : '',
                systemSelection: (entry['systemSelection']) ? entry['systemSelection'] : '',
                dname: (entry['dname']) ? entry['dname'] : '',
                dcity: (entry['dcity']) ? entry['dcity'] : '',
                dstate: (entry['dstate']) ? entry['dstate'] : '',
                refFname: refName,
                refLname: refLname,
                refEmail: refEmail,
                refPhone,
                refCardType,
                certification: (entry['certification']) ? entry['certification'] : '',
                certFname: (entry['certFname']) ? entry['certFname'] : '',
                certLname: (entry['certLname']) ? entry['certLname'] : '',
                referralDate: (entry['referralDate']) ? this.formatDate(entry['referralDate']) : '',
                refEmailSentDate: ((entry['emailSent'] && entry['emailSent'] == 'Yes' && entry['refEmailSentDate']) || ((!entry['emailSent'] || entry['emailSent'] === null) && entry['refEmailSentDate'])) ? this.formatDate(entry['refEmailSentDate']) : 'No Email Sent',
                autoRedeemed: (entry['autoRedeemed']) ? 'Yes' : 'No',
                fileNames: [],
                popUpInfo: '',
                aiStatus: entry['aiStatus'],
                systemId:entry['systemId'],
                milesId: entry['milesId'],
                employeeId: entry['employeeId']
            };
            temp['popUpInfo'] = this.getPopUpInfo(temp);
            temp['notes'] = this.getNotesToShow(entry['notes'], (entry['notes'].length > 1) ? 10 : 1);
            temp['notePresent'] = (entry['notes'].length > 0) ? true : false;
            entry['docs'].map(doc => {
                temp['fileNames'].push(doc);
            });
            tableData.push(temp);
        });

        this.setState({ dataSource: tableData, spinnerDisplay: false, spinnerText: '', pagination });
    }

    getRebates(pageNo, searchString, status, inputType) {
        this.setState({ spinnerDisplay: true, spinnerText: 'Retrieving the rebates...' });
        const userVar = JSON.parse(localStorage.getItem('user'));
        //console.log(userVar);
        const rebateState = (status.length > 0) ? status : this.state.rebateState;
        inputType = (inputType && inputType != '') ? inputType : this.state.inputType;
        let url = '';
        const urlSearchString = (searchString.length > 0 && searchString != 'reset') ? searchString : (searchString == 'reset') ? '' : this.state.searchString;
        if (pageNo) {
            url = `${process.env.REACT_APP_BACKEND_URL}/api/getrebates?page=${pageNo}&searchString=${urlSearchString}&status=${rebateState}&inputType=${inputType}`;
        } else if (searchString.length > 0) {
            url = `${process.env.REACT_APP_BACKEND_URL}/api/getrebates?page=${pageNo}&searchString=${urlSearchString}&status=${rebateState}&inputType=${inputType}`;
        } else {
            url = `${process.env.REACT_APP_BACKEND_URL}/api/getrebates?searchString=${urlSearchString}&status=${rebateState}&inputType=${inputType}`;
        }

        axios.get(url, {
            headers: {
                "Authorization": `Bearer ${userVar.token2}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => {
                this.fillUpTableData(response);
            }).catch(error => {
                if (error.response) {
                    if (error.response.data.error == "token_expired") {
                        localStorage.clear();
                        window.location.href = '/login';
                    }
                    /*console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);*/
                }
            });
    }

    componentDidMount() {

        this.getRebates(null, '', '');

    }

    onAction(pagination, filters, sorter, extra) {
        //console.log(pagination);
        const userVar = JSON.parse(localStorage.getItem('user'));
        //console.log(userVar);
        this.setState({ dataSource: [] });
        this.getRebates(pagination.current, '', '', this.state.inputType);

    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //console.log('Received values of form: ', values);
                //this.setState({ dataSource: [], searchString: values.searchString });
                this.setState({ dataSource: [] });
                this.getRebates(null, this.state.searchString, '', this.state.inputType);

            }
        });
    };

    /*rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let selectedIds = selectedRowKeys.split(',');
            this.setState({selectedIds});
            console.log(this.state.selectedIds);
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };*/
    onSelectChange = selectedRowKeys => {
        //console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    reasonOnChange(value, ev, key) {
        //console.log(value);
        //console.log(key);
        let declineReasonSelected = this.state.declineReasonSelected;
        declineReasonSelected.push({ 'id': key, 'reason': value });
        this.setState({ declineReasonSelected });
    }

    processRebates($action) {
        const spinnerText = ($action == 'Approved') ? 'Approving the selected Rebates...' : 'Declining the selected Rebates...';
        this.setState({ spinnerDisplay: true, spinnerText });
        const userVar = JSON.parse(localStorage.getItem('user'));
        let row = {};
        row['selectedIds'] = this.state.selectedRowKeys;
        row['declineIds'] = this.state.declineReasonSelected;
        row['action'] = $action;
        row['approvedDomain'] = 'bpsadmin';

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/rebate`, { row }, {
            headers: {
                "Authorization": `Bearer ${userVar.token2}`
            }
        })
            .then(response => {
                this.fillUpTableData(response);
            })
            .catch(error => {
                alert(JSON.stringify(error));
            })
    }

    approveRebates() {
        //console.log(this.state.selectedRowKeys);
        this.processRebates('Approved');
        this.setState({ selectedRowKeys: [] });
    }

    declineRebates() {
        //console.log(this.state.declineReasonSelected);
        let error = false;
        let errorKey = [];
        this.state.selectedRowKeys.map(rowKey => {
            //console.log(rowKey);
            const existsFlag = this.state.declineReasonSelected.some(function (arrVal) {
                return rowKey === arrVal.id;
            });
            //console.log('existsFlag: ',existsFlag);
            if (!existsFlag) {
                errorKey[rowKey] = true;
                error = true;
            }
        })
        //console.log('errorKey:',errorKey);
        if (!error) {
            this.processRebates('Declined');
            this.setState({ errorKey: {}, selectedRowKeys: [], declineReasonSelected: [] });
        } else {
            this.setState({ errorKey });
        }
    }

    declineReservationRebates() {
        let declineReasonSelected = [];
        this.state.selectedRowKeys.map(rowKey => {
            declineReasonSelected.push({ 'id': rowKey, 'reason': '' });
        });
        this.setState({ declineReasonSelected });
        setTimeout(() => {
            this.processRebates('Declined2');
            this.setState({ errorKey: {}, selectedRowKeys: [], declineReasonSelected: [], defaultValue: 'all', });
        }, 1000);

    }

    statusChange(e) {
        //console.log(e.target.value);
        this.getRebates(null, '', e.target.value, this.state.inputType);
        let columns = this.getColumns();
        if (e.target.value == 'In Process') {
            columns.unshift(
                {
                    title: 'AI Status',
                    dataIndex: 'aiStatus',
                    key: 'aiStatus',
                    width: 180,
                    render: (select, record) => {
                        //console.log(record);
                        return (
                            <div>
                                {record['aiStatus'] ? <>
                                    <Tag color={record['aiStatus'] == 'AI_PENDING' ? "red" : "green"} >{record['aiStatus']}</Tag>
                                    <Button type='link' className='p-0' onClick={() => this.showAIModal([record.key])}><Icon type="info-circle" /></Button>
                                    </> : null}
                            </div>

                        );
                    },
                },
            );
            columns.unshift(
                {
                    title: 'Decline Reason',
                    dataIndex: 'reason',
                    key: 'reason',
                    width: 770,
                    render: (select, record) => {
                        //console.log(record);
                        return (
                            <div>
                                <Select name={'reason_' + record['key']} onSelect={(value, event) => this.reasonOnChange(value, event, record['key'])} placeholder='Select Decline Reason' style={{ width: 610 }}>
                                    {this.state.declineReason.map(dReason => (
                                        <Option value={dReason.name} label={dReason.name} key={dReason.name}>
                                            {dReason.label}
                                        </Option>
                                    ))}
                                </Select>
                                <span className={(this.state.errorKey[record['key']]) ? 'showMsg' : 'hide'}>This field is required</span>
                            </div>

                        );
                    },

                },
            );
            
            this.setState({ showButtons: true, showComment: true, justCheckBox: false, rebateState: e.target.value, columns, xAxis: 4300 });


        } else if (e.target.value == 'Declined') {
            columns.unshift(
                {
                    title: 'Declined Comment',
                    dataIndex: 'comment',
                    key: 'comment',
                    width: 180,
                },
            );
        }
        if (e.target.value != 'In Process' && e.target.value != 'Pending Document Upload') {
            columns.unshift(
                {
                    title: 'Approval Date/Decline Date',
                    dataIndex: 'processDate',
                    key: 'processDate',
                    width: (e.target.value == 'Declined') ? 190 : 190,
                },
            );
            const xAxis = (e.target.value == 'Approved') ? 3600 : (e.target.value == 'Declined') ? 3900 : (e.target.value == 'Pending Document Upload') ? 3700 : 3800;
            this.setState({ showButtons: false, showComment: false, justCheckBox: false, rebateState: e.target.value, columns, xAxis });


        }
        if (e.target.value == 'Pending Document Upload') {
            this.setState({ showButtons: false, showComment: false, justCheckBox: false, rebateState: e.target.value, columns, xAxis: 3400 });
        }
        
        if (e.target.value == 'all') {
            columns.unshift(
                {
                    title: 'AI Status',
                    dataIndex: 'aiStatus',
                    key: 'aiStatus',
                    width: 180,
                    render: (select, record) => {
                        //console.log(record);
                        return (
                            <div>
                                {record['aiStatus'] ? <>
                                    <Tag color={record['aiStatus'] == 'AI_PENDING' ? "red" : "green"} >{record['aiStatus']}</Tag>
                                    <Button type='link' className='p-0' onClick={() => this.showAIModal([record.key])}><Icon type="info-circle" /></Button>
                                    </> : null}
                            </div>

                        );
                    },
                },
            );
            columns.unshift(
                {
                    title: 'Rebate Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 130,
                },
            );
            this.setState({ showButtons: false, showComment: false, justCheckBox: false, rebateState: e.target.value, columns, xAxis: 3800 });

        }
        if (e.target.value === 'Rebate Registered') {
            columns.shift();
            const xAxis = (e.target.value == 'Approved') ? 3600 : (e.target.value == 'Declined') ? 3900 : (e.target.value == 'Pending Document Upload') ? 3700 : 3800;
            this.setState({ showButtons: false, justCheckBox: true, rebateState: e.target.value, columns, xAxis });
        }
    }

    expandedRowRender(record, index, indent, expanded) {
        const columns = [
            { title: 'Reward', dataIndex: 'reward', key: 'reward', width: 40 },
            { title: 'Rebate Amount', dataIndex: 'amount', key: 'amount', width: 40 },
            { title: 'Phone', dataIndex: 'phone', key: 'phone', width: 40 },
            { title: 'Install Date', dataIndex: 'installdate', key: 'installdate', width: 40 },
            { title: 'Contract Date', dataIndex: 'contractDate', key: 'contractDate', width: 40 },
            { title: 'Module Type', dataIndex: 'module', key: 'module', width: 40 },
            { title: 'System Size', dataIndex: 'watts', key: 'watts', width: 40 },
            { title: 'Purchased/Leased', dataIndex: 'saletype', key: 'saletype', width: 50 },
            { title: 'Dealer Name', dataIndex: 'dname', key: 'dname', width: 50 },
            { title: 'Dealer City', dataIndex: 'dcity', key: 'dcity', width: 50 },
            { title: 'Dealer State', dataIndex: 'dstate', key: 'dstate', width: 200 },
        ];
        const data = [];
        data.push({
            key: record['key'],
            installdate: record['installdate'],
            contractDate: record['contractDate'],
            module: record['module'],
            watts: record['watts'],
            saletype: record['saletype'],
            dname: record['dname'],
            dcity: record['dcity'],
            dstate: record['dstate'],
            reward: record['reward'],
            amount: record['amount'],
        });

        return <Table
            columns={columns} dataSource={data} pagination={false} scroll={{ x: 700 }}
        />;
    };

    handleReset() {
        //console.log('reset');
        this.props.form.setFieldsValue({
            'searchString': ''
        });
        //this.formRef.current.resetFields();
        this.setState({ searchString: '', showReset: false, value: '', autoCompleteValue: [] });
        this.getRebates(null, 'reset', '');
    }

    renderOption(item) {
        console.log(item);

        return (
            <Option key={item.value} value={item.value.toString()}>
                <div className="global-search-item">

                    {item.title}

                </div>
            </Option>
        );
    }

    handleSearch(value) {
        //console.log(value);
        if (value && value.length > 1) {
            const userVar = JSON.parse(localStorage.getItem('user'));
            let url = '';
            url = `${process.env.REACT_APP_BACKEND_URL}/api/autocomplete/${value}/${this.state.inputType}`;

            axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${userVar.token2}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            })
                .then(response => {
                    console.log(response.data);
                    /*let options = response.data.data.map(entry => {
                        return (
                            <Option2 key={entry.value} value={entry.value}>
                                {entry.title}
                            </Option2>
                        );
                    });
                    console.log(options);*/
                    let autoOptions = [];
                    response.data.map(item => {
                        autoOptions.push(String(item));
                    })
                    this.setState({ autoOptions });
                }).catch(error => {
                    if (error.response) {
                        if (error.response.data.error == "token_expired") {
                            localStorage.clear();
                            window.location.href = '/login';
                        }
                        /*console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);*/
                    }
                });
        } else if (value.length == 0) {
            this.handleReset();
        }

    }

    onSelect(value, option) {
        //console.log(value);
        //console.log(option);
    }

    handleonSelect(value, option) {
        //console.log(value);
        //console.log(option);
        this.setState({ searchString: value, showReset: true, dataSource: [] });
        this.getRebates(null, value, '', this.state.inputType);
    }

    handleChange(value) {
        this.setState({ value });
    };

    handleInputChange(value) {
        this.setState({ inputType: value });
    }

    onInputChange(value) {
        console.log(value);
        if (value && value.length == 0) {
            this.handleReset();
        }
    }

    handleOnBlur(value) {
        //console.log('value in onblur:',value);
        this.setState({ searchString: value, showReset: true });
    }

    showAIModal(systemId) {
        //console.log(this.state.selectedRowKeys);
        this.setState({  modalVisible: true, modalSystemId: systemId });
    }


    handleCloseModal() {
        this.setState({  modalVisible: false, modalSystemId: [] });
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const searchStringError = isFieldTouched('searchString') && getFieldError('searchString');
        //const options = this.state.autoOptions.map(d => <Option key={d.value}>{d.title}</Option>);
        return (
            <Fragment>
                <Container fluid className="py-5">
                    <Row className="pt-md-5">
                        <Col className="pt-5 pb-md-4">
                            <h3>Submitted Rebates</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6} className="order-2 order-xl-1 pt-4">
                            <Form layout="inline" onSubmit={this.handleSubmit}>
                                <Select placeholder="Select" style={{ width: 240 }} onChange={this.handleInputChange} className="pe-2">
                                    <Option value="userregistration.fname">First Name</Option>
                                    <Option value="userregistration.lname">Last Name</Option>
                                    <Option value="userregistration.email">Email</Option>
                                    <Option value="userregistration.phone">Phone Number</Option>
                                    <Option value="name">Dealer Name</Option>
                                    <Option value="cardTracking.trackingNo">Rebate Status Tracking Number</Option>
                                    <Option value="userregistration.address1">Install Address 1</Option>
                                    <Option value="referralInfo.refFname">Referral First Name</Option>
                                    <Option value="referralInfo.refLname">Referral Last Name</Option>
                                    <Option value="referralCardTracking.trackingNo">Referral Status Tracking Number</Option>
                                </Select>
                                <AutoComplete
                                    dataSource={this.state.autoOptions}
                                    style={{ width: 200 }}
                                    onSearch={this.handleSearch}
                                    onSelect={this.handleonSelect}
                                    value={this.state.autoCompleteValue}
                                    onChange={e => this.setState({ autoCompleteValue: [e] })}
                                    onBlur={this.handleOnBlur}
                                >
                                    <Input.Search autoComplete='off' />
                                </AutoComplete>

                                <Form.Item>
                                    <Icon type="close-circle" style={{ display: (isFieldTouched('searchString') !== undefined && isFieldTouched('searchString')) ? '' : 'none' }} onClick={this.handleReset} />
                                    <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())} style={{ margin: 0 }} className="btn-orange-secondary me-1">
                                        Search
                                    </Button>
                                    <Button type="primary" onClick={this.handleReset} style={{ display: this.state.showReset ? '' : 'none' }} className="btn-gray-secondary ms-1">
                                        Reset
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col xl={6} className="text-xl-end order-1 order-xl-2 pt-4">
                            <Radio.Group defaultValue={this.state.defaultValue} buttonStyle="solid" onChange={this.statusChange}>
                                <Radio.Button value="all">All</Radio.Button>
                                <Radio.Button value="In Process">Pending</Radio.Button>
                                <Radio.Button value="Rebate Registered">Rebate Registered</Radio.Button>
                                <Radio.Button value="Pending Document Upload">Pending Document Upload</Radio.Button>
                                <Radio.Button value="Approved">Approved</Radio.Button>
                                <Radio.Button value="Declined">Declined</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row className='py-4'>
                        <Col>
                            <div className={this.state.showButtons ? 'show' : 'hide'}>
                                <Button onClick={this.approveRebates} className="btn-approve me-2">Approve</Button>
                                <Button onClick={this.declineRebates} className="btn-decline ms-2">Decline</Button>
                            </div>
                            <div className={this.state.justCheckBox ? 'show' : 'hide'}>
                                <Button onClick={this.declineReservationRebates} className="btn-decline">Decline</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <LoadingOverlay
                                active={this.state.spinnerDisplay}
                                spinner
                                text={this.state.spinnerText}
                            >
                                <Table
                                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                                    rowSelection={(this.state.showComment || this.state.justCheckBox) ? rowSelection : null} dataSource={this.state.dataSource} columns={this.state.columns} onChange={this.onAction} scroll={{ x: this.state.xAxis, y: 440 }} pagination={this.state.pagination}
                                />
                            </LoadingOverlay >
                        </Col>
                    </Row>
                    <AIModal visible={this.state.modalVisible} onClose={this.handleCloseModal} systemId={this.state.modalSystemId} />
                </Container >
            </Fragment >
        );
    }
}

const RebateTableForm = Form.create({ name: 'rebate_form' })(RebateTable);
export default RebateTableForm;